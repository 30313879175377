import {Button, Modal, notification} from 'antd'
import {useEffect} from 'react'
import ProductsAPI from '../../api/ProductsAPI'
import {actions} from './slice'
import {useAppDispatch, useAppSelector} from '../../hooks'
import {HtmlExport, HtmlExportConfig, HtmlExportConfigVariable } from '../../types/html_exports'
import { Input } from 'antd'
import AsyncProcessController from '../../lib/AsyncProcessController'
import HtmlExportsAPI from '../../api/HtmlExportsAPI'
import HtmlExportConfigsAPI from '../../api/HtmlExportConfigsAPI'

export default function HtmlExportParamsModal(){
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.htmlExportParamsModal)
  const htmlExportState = useAppSelector(state => state.htmlExportModal)

  useEffect(() => {
    if(state.isOpen){
      HtmlExportConfigsAPI.fetchDetails(state.configId, (cfg: HtmlExportConfig) => {
        if(cfg.variables && cfg.variables.length > 0){
          dispatch(actions.setVariables(cfg.variables))

          const keys = cfg.variables.map((v: HtmlExportConfigVariable) => v.key)
          ProductsAPI.fetchExportSeedParams(state.productUuid, keys, (resp: {[key: string]: string }) => {
            dispatch(actions.setSeedValues(resp))
          })
        }
      })
    }
  }, [dispatch, state.isOpen, state.productUuid, state.componentUuid])

  function onOk(){
    let isValid = true

    state.variables.forEach((variable: HtmlExportConfigVariable) => {
      if(!state.variableValues[variable.key] || state.variableValues[variable.key].trim().length === 0){
        isValid = false
      }
    })

    if(isValid){
      dispatch(actions.close())

      let cbc = new AsyncProcessController(dispatch)

      cbc.createHtmlExport(state.productUuid, state.componentUuid, htmlExportState.configId,
        state.variableValues, htmlExportState.selectedWidgets, htmlExportState.selectedPreset, state.comment,
        (exportId: number, success: boolean) => {
          if (success) {
            HtmlExportsAPI.getDetails(exportId, (details: HtmlExport) => {
              let content = <div>
                <Button size={'large'} type={'link'} target={'_blank'} rel={'noreferrer'} href={ details.download_url }>Download</Button>
                <br/><br/>OR COPY LINK<br/><br/>
                <Input.TextArea value={ details.download_url }/>
              </div>

              Modal.success({
                width: 700,
                title: 'Export has been created',
                content,
              })
            })
          } else {
            notification.error({
              message: 'Error occured during export process.'
            })
          }
        }
      )
    }else{
      notification.error({ message: 'Please fill in all the fields'})
    }
  }

  return <Modal
    visible={ state.isOpen }
    title={ 'Fill in export data' }
    onCancel={() => dispatch(actions.close())}
    onOk={ onOk }
  >
    {
      state.variables.map((variable: HtmlExportConfigVariable) => {
        return <div>
          <p>{variable.name}</p>
          <Input
            value={state.variableValues[variable.key]}
            onChange={e => dispatch(actions.setVariableValue({key: variable.key, value: e.target.value}))}
          />
          <br /><br />
        </div>
      })
    }
    <div>
      <p>Comment</p>
      <Input.TextArea value={ state.comment } onChange={e => dispatch(actions.setComment(e.target.value))}/>
    </div>
  </Modal>
}