import React from 'react'
import { connect } from 'react-redux'
import { Button, PageHeader, Table, Space, Input } from "antd"
import { withRouter, RouteComponentProps } from 'react-router'
import { RootState } from "../../store"
import { VendorSelectorState, actions } from "./slice"
import { Vendor } from '../../types/core'
import VendorsAPI from '../../api/VendorsAPI'
import {debounce} from '../../utils'
import CurrentUser from '../App/CurrentUser'


type PropsType = VendorSelectorState & RouteComponentProps & typeof actions;


class VendorSelector extends React.Component<PropsType> {
  fetchVendorsDebounced: Function
  state = { nameQuery: '' }

  constructor(props: PropsType) {
    super(props)
    this.fetchVendorsDebounced = debounce(this.fetchVendors, 300)
  }

  componentDidMount() {
    this.fetchVendors()
  }

  fetchVendors(){
    VendorsAPI.fetchList((vendors: Vendor[], total: Number) => {
      this.props.setVendors({ vendors, total })
    }, { query: this.state.nameQuery })
  }

  selectVendor = (vendor: Vendor) => {
    CurrentUser.setSelectedVendor(vendor)

    this.setState({ nameQuery: '' })
    this.props.history.push('/products')
  }

  onNameQueryChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ nameQuery: evt.target.value }, () => { this.fetchVendorsDebounced() })
  }

  render() {
    return <div>
      <PageHeader title={'Vendor selector'}/>
      <Input.Search
        placeholder={"Search by name..."}
        onChange={ this.onNameQueryChange }
        style={{ marginBottom: 20 }}
        enterButton={ false }
        value={ this.state.nameQuery }
      />
      <Table
        pagination={ false }
        bordered={ true }
        rowKey={'id'}
        columns={[
          {title: 'ID', dataIndex: 'id', key: 'id'},
          {title: 'Name', dataIndex: 'name', key: 'name'},
          {
            title: '',
            key: 'action',
            render: (text: string, record: Vendor) => {
              return <Space size={'middle'}>
                <Button onClick={ this.selectVendor.bind(this, record) }>Select</Button>
              </Space>
            }
          }
          ]}
        dataSource={ this.props.vendors }
      />
    </div>
  }
}

const mapStateToProps = (state: RootState): VendorSelectorState => (state.vendorSelector)

export default withRouter(connect(mapStateToProps, actions)(VendorSelector))