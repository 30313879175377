import React, {useEffect, useState} from 'react'
import {PageHeader, Button, Modal} from 'antd'
import AvailableContentBrowser from './AvailableContentBrowser'
import OzonWidgetsBuilder from './OzonWidgetsBuilder'
import {useAppDispatch, useAppSelector} from '../../hooks'
import {useParams} from 'react-router'
import {actions} from './slice'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { appHost } from '../../api/apiAccess'
import AppMenu from '../App/Menu'
import ContentPublicationsAPI from '../../api/ContentPublicationsAPI'
import {iOzonJsonForPublication, iOzonWidget} from '../../types/ozon'
import {ContentPublication, iContentPublicationUploadedAsset} from '../../types/content_publications'
import {debounce} from '../../utils'
import {ContentPublicationStatusTag} from '../minis'
import AsyncProcessController from '../../lib/AsyncProcessController'
import { ContentPublicationStatuses } from '../../types/content_publications'
import {useHistory} from 'react-router-dom'
import {cxhComponentPreviewUrl} from '../../lib/urls'
import ProductsAPI from '../../api/ProductsAPI'
import {AssetFetchedFromEcComponent, TextFetchedFromEcComponent} from '../../types/core'

// return a promise
function copy2clipboard(text: string) {
    // if (window.clipboardData! && window.clipboardData!.setData) {
    //     // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    //     return window.clipboardData!.setData("Text", text);
    //
    // }

    if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
}

type OzonPublicationParams = {
  publication_id: string
}

const updateWidgets = debounce(function(publicationId: number, ozon_widgets: Array<iOzonWidget>){
 ContentPublicationsAPI.updateOzonWidgets(publicationId, ozon_widgets, () => {})
}, 2000)

function OzonPublication(){
  const dispatch = useAppDispatch()
  const params = useParams() as OzonPublicationParams
  const publicationId = parseInt(params.publication_id)
  const state = useAppSelector(state => state.ozonPublication)
  const [isCopied, setIsCopied]  = useState(false)
  const [wasLoaded, setWasLoaded] = useState(false)
  const history = useHistory()

  useEffect(() => {
    ContentPublicationsAPI.fetchDetails(publicationId, (publication: ContentPublication) => {
      dispatch(actions.setDetails(publication))
      setWasLoaded(true)

      ProductsAPI.fetchProductEcComponentAssetsAndTexts(
        publication.cxh_product_uuid, publication.cxh_ec_component_uuid,
        (assets: AssetFetchedFromEcComponent[], texts: TextFetchedFromEcComponent[]) => {
          dispatch(actions.setAssetsFromEc(assets))
          dispatch(actions.setTextsFromEc(texts))
        }
      )

      ContentPublicationsAPI.fetchUploadedAssets(publicationId, (assets: Array<iContentPublicationUploadedAsset>) => {
        dispatch(actions.setUploadedAssets(assets))
      })
    })
  }, [dispatch, publicationId])

  useEffect(() => {
    if(wasLoaded){
      updateWidgets(publicationId, state.widgets)
    }
  }, [publicationId, state.widgets, wasLoaded])


  function triggerPublishing(){
    if(state.widgets.length === 0){
      Modal.warn({
        width: 500,
        title: 'No widgets',
        content: <div>
          <p>Please add some widgets before publishing.</p>
        </div>
      })
      return
    }

    function runPublishingProcess(){
      let ajc = new AsyncProcessController(dispatch)
      ajc.publishContentPublication(publicationId, (success: boolean) => {
        if(success){
          Modal.success({
            width: 500,
            title: 'Success',
            okText: 'Close editor',
            onOk: () => {
              history.push('/content-publications')
            },
            content: <div>
              <p>Product content was published successfully.</p>
              <Button
                onClick={e => window.location.href = `/api/1/content-publications/${publicationId}/download-json` }
              >Download Ozon JSON file</Button>
            </div>
          })
        }else{
          Modal.error({
            width: 500,
            title: 'Error',
            content: <div>
              <p>We encountered an issue during publication process.</p>
              <p>Please contact support.</p>
              <p></p>
              <p>Sincerely, The Management</p>
            </div>
          })
        }
      })
    }

    if(state.status === ContentPublicationStatuses.PUBLISHED){
      Modal.confirm({
        width: 600,
        title: 'Are you sure?',
        cancelText: 'Cancel',
        onCancel: () => {},
        onOk: () => {
          runPublishingProcess()
        },
        okText: 'OK',
        content: <div>
          <p>This content has been already published.</p>
          <p>Publishing it again will override previously exported images.</p>
          <p></p>
        </div>
      })
    }else{
      runPublishingProcess()
    }
  }

  function copyToClipboard(){
    ContentPublicationsAPI.prepareOzonTemporaryJson(publicationId, state.widgets, (resp: iOzonJsonForPublication) => {
      let toCopy = JSON.stringify(resp, null, 4)

      copy2clipboard(toCopy)

      setIsCopied(true)

      setTimeout(() => {
        setIsCopied(false)
      }, 1000)
    })
  }

  return <>
    <AppMenu selectedKey={''}/>
    <form method={'post'} action={appHost + '/api/1/utils/trigger-ozon-widgets-download'} id={'trigger-form'}>
      <input type={'hidden'} name={'widgets'} value={ JSON.stringify(state.widgets) }/>
    </form>
    <div style={{ backgroundColor: '#fff', width: '100%', height: 'calc(100% - 46px)', padding: '0px 20px' }}>
      <div style={{ width: '100%', backgroundColor: 'blue', height: 60 }}>
        <PageHeader
          style={{ padding: '10px 0' }}
          ghost={ false }
          // backIcon={ <ArrowLeftOutlined/> }
          title={<div>{'Ozon editor'}</div>}
              subTitle={
                state.status && <ContentPublicationStatusTag status={ state.status }/>
              }
          onBack={e => window.location.href = '/products' }
          extra={[
            <Button
              key={'cxh'}
              target={'_blank'}
              type={'link'}
              href={ cxhComponentPreviewUrl(state.cxh_product_uuid, state.cxh_ec_component_uuid) }
            >Open CXH Preview</Button>,
            <Button
              key={'sand'}
              target={'_blank'}
              type={'link'}
              href={'https://rich-content.ozon.ru/sandbox'}
            >Open Sandbox</Button>,
            <Button
              type={isCopied ? 'dashed' : 'default'}
              key={'show'}
              onClick={copyToClipboard}
            >{ isCopied ? 'Copied!' : 'Copy to clipboard' }</Button>,
            <Button
              key={'download'}
              type={'primary'}
              onClick={triggerPublishing}
            >{
              state.status === ContentPublicationStatuses.PUBLISHED ? 'RE-Publish' : 'Publish'
            }</Button>,
          ]}
        />
      </div>
      <div style={{ height: 'calc(100% - 70px)', width: '100%', display: 'inline-block', overflow: 'hidden' }}>
        <DndProvider backend={HTML5Backend}>
          <div style={{ width: 310, borderRight: '1px solid #ddd', display: 'inline-block', height: '100%', verticalAlign: 'top' }}>
            <AvailableContentBrowser publicationId={ publicationId }/>
          </div>
          <div style={{ width: 'calc(100% - 310px)', display: 'inline-block', paddingLeft: 30, height: '100%', verticalAlign: 'top', }}>
            <OzonWidgetsBuilder publicationId={ params.publication_id }/>
          </div>
        </DndProvider>
      </div>
    </div>
  </>
}

export default OzonPublication