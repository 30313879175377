import React from 'react'
import {actions} from './slice'
import{iOzonWidget} from '../../types/ozon'
import OzonWidgetEditor from './OzonWidgetEditor'
import OzonWidgetTextObjectEditor from './OzonWidgetTextObjectEditor'
import {Radio} from 'antd'
import {useAppDispatch} from '../../hooks'

export default function OzonTextBlockEditor({ widget, idx, isLast }: { widget: iOzonWidget, idx: number, isLast: boolean }){
  const dispatch = useAppDispatch()

  return <OzonWidgetEditor widget={ widget } idx={ idx } title={'Text block'} isLast={ isLast }>
    <div>
      Title
      <OzonWidgetTextObjectEditor widgetIdx={ idx } prop={'title'} object={ widget.title! } placeholder={'Enter title'}/>
    </div>
    <div>
      Content
      <OzonWidgetTextObjectEditor
        widgetIdx={ idx }
        prop={'text'}
        object={ widget.text! }
        placeholder={'Enter content'}
        big={ true }
      />
    </div>
    <hr/>
    <strong>Gap size&nbsp;</strong>
    <Radio.Group size={'small'} value={ widget.gapSize }
       onChange={e => dispatch(actions.updateWidgetAttribute({ widgetIdx: idx, attribute: 'gapSize', value: e.target.value }))}
    >
      <Radio.Button value={'s'}>s</Radio.Button>
      <Radio.Button value={'m'}>m</Radio.Button>
    </Radio.Group>
    <strong>&nbsp;Padding&nbsp;</strong>
    <Radio.Group size={'small'} value={ widget.padding }
       onChange={e => dispatch(actions.updateWidgetAttribute({ widgetIdx: idx, attribute: 'padding', value: e.target.value }))}
    >
      <Radio.Button value={'type1'}>1</Radio.Button>
      <Radio.Button value={'type2'}>2</Radio.Button>
    </Radio.Group>
    <strong>&nbsp;Theme&nbsp;</strong>
    <Radio.Group
      size={'small'}
      value={ widget.theme }
      onChange={e => dispatch(actions.updateWidgetAttribute({ widgetIdx: idx, attribute: 'theme', value: e.target.value }))}
    >
      <Radio.Button value={'default'}>Default</Radio.Button>
      <Radio.Button value={'primary'}>Primary</Radio.Button>
      <Radio.Button value={'secondary'}>Secondary</Radio.Button>
      <Radio.Button value={'tertiary'}>Tertiary</Radio.Button>
    </Radio.Group>
  </OzonWidgetEditor>
}