

export interface HtmlExportConfigVariable {
  idx: number
  key: string
  name: string
}


export interface HtmlExportConfigPayload {
  name: string
  outputter_id: number
  main_file_name: string
  main_file_dir_name: string
  assets_mode: number
  js_enabled: boolean
  video_enabled: boolean
  src_sets_enabled: boolean
  variables: HtmlExportConfigVariable[]
  scripts_format: number
  minify_scripts: boolean
  scripts_filename: string
  minified_scripts_filename: string
  scripts_dir_name: string
  include_both_scripts_files: boolean
  scripts_wrapper: string
  styles_format: number
  minify_styles: boolean
  styles_filename: string
  minified_styles_filename: string
  styles_dir_name: string
  include_both_styles_files: boolean
  style_addons: string
  root_indicator: string
  relate_contents_to_root_indicator: boolean
  content_dir_name?: string
  images_dir_name: string
  videos_dir_name: string
  documents_dir_name: string
  asset_suffix?: string
  inject_base_html_structure: boolean
  is_default: boolean
}


export interface HtmlExportConfig extends HtmlExportConfigPayload{
  id: number;
}

export class HtmlExportConfigAssetsMode{
  static IN_FILE = 1
  static SERVED_FROM_CXH_PREVIEW = 2
  static SERVED_FROM_PCC = 3

  static NAMES = {
    [HtmlExportConfigAssetsMode.IN_FILE]: 'In file',
    [HtmlExportConfigAssetsMode.SERVED_FROM_CXH_PREVIEW]: 'Served from CXH preview',
    [HtmlExportConfigAssetsMode.SERVED_FROM_PCC]: 'Served from PCC',
  }
}

export class HtmlExportConfigOutputter{
  static ZIP = 1
  static HEBE_ZIP = 3

  static NAMES = {
    [HtmlExportConfigOutputter.ZIP]: 'ZIP',
    [HtmlExportConfigOutputter.HEBE_ZIP]: 'ZIP for HEBE',
  }
}

export interface HtmlExport {
  id: number;
  download_url: string;
  export_status_message: string;
  cxh_product_uuid: string;
  cxh_product_name: string;
  cxh_ec_component_uuid: string;
  config: HtmlExportConfig;
  status: number;
  format: number;
  comment: string;
  user: {
    username: string;
  }
  preset: HtmlExportPreset
  js_enabled: boolean
  src_sets_enabled: boolean
  video_enabled: boolean
}


export class HtmlExportStatus{
  static INITIALIZED = 1
  static CREATION_IN_PROGRESS = 2
  static READY = 3
  static FAILED = 4

  static NAMES = {
    [HtmlExportStatus.INITIALIZED]: 'Initialized',
    [HtmlExportStatus.CREATION_IN_PROGRESS]: 'In progress',
    [HtmlExportStatus.READY]: 'Ready',
    [HtmlExportStatus.FAILED]: 'Failed',
  }
}

export interface HtmlExportParam{
  id: string
  label: string
}

export type HtmlExportPresetPayload = {
  name: string;
  scss_config: string;
  preview_head_addons: string;
  export_head_addons: string;
}

export type HtmlExportPreset = {
  id: number;
  name: string;
  scss_config: string;
  preview_head_addons: string;
  export_head_addons: string;
}
