import React from 'react'
import {Button, Card} from 'antd'
import {DeleteRowOutlined} from '@ant-design/icons'
import OzonWidgetTextObjectEditor from './OzonWidgetTextObjectEditor'
import {actions} from './slice'
import {useAppDispatch} from '../../hooks'
import OzonWidgetBlockImageControl from './OzonWidgetBlockImageControl'
import {iOzonWidgetBlock} from '../../types/ozon'

export default function OzonWidgetBlockEditor(
  {idx, blockIdx, block} : {blockIdx: number, idx: number, block: iOzonWidgetBlock}
) {
  const dispatch = useAppDispatch()

  const onBlockRemove = (blockIdx: number) => {
    if(window.confirm('Are you sure?')) {
      dispatch(actions.removeBlock({widgetIdx: idx, blockIdx}))
    }
  }

  return <Card
    key={blockIdx}
    size={'small'}
    style={{border: '1px solid #ccc', marginBottom: 5, display: 'relative'}}
    title={'Block ' + (blockIdx+1)}
    headStyle={{ backgroundColor: '#eeeeee' }}
  >
    <Button
      icon={<DeleteRowOutlined />}
      size={'small'}
      style={{position: 'absolute', right: 12, top: 5}}
      onClick={e => onBlockRemove(blockIdx)}
    />
    {
      block.title! !== undefined &&
        <>
          <strong>Title:</strong><br />
          <OzonWidgetTextObjectEditor
            blockIdx={blockIdx}
            widgetIdx={idx}
            prop={'title'}
            object={block.title!}
            placeholder={'...'}
          />
        </>
    }
    {
      block.text! !== undefined &&
        <>
          <strong>Text</strong>
          <OzonWidgetTextObjectEditor
            widgetIdx={idx}
            blockIdx={blockIdx}
            prop={'text'}
            object={block.text!}
            placeholder={'...'}
            big={true}
          />
      </>
    }
    {
      block.img! !== undefined &&
      <>
        <strong>Image</strong>
        <OzonWidgetBlockImageControl
          image={ block.img! }
          onImgLinkChange={(imgLink: string) => dispatch(actions.updateBlockProperty({
            widgetIdx: idx, blockIdx, prop: 'imgLink', value: imgLink
          }))}
          onImagePropertyChange={
            (prop: string, value: string) => {
              dispatch(actions.updateBlockImageProperty({ widgetIdx: idx, blockIdx, prop, value }))
            }
          }
        />
      </>
    }
  </Card>
}