import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Vendor} from '../../types/core'

export interface SwitchVendorModalState {
  isOpen: boolean
  query: string
  isLoading: boolean
  vendors: Vendor[]
}

const initialState: SwitchVendorModalState = {
  isOpen: false,
  query: '',
  vendors: [],
  isLoading: false,
}

export const switchVendorModalSlice = createSlice({
  name: 'switchVendorModal',
  initialState,
  reducers: {
    setVendors: (state, action: PayloadAction<{ total: number, vendors: Vendor[] }>) => {
      state.vendors = action.payload.vendors
      state.isLoading = false
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
    close: (state) => {
      state.isOpen = false
    },
    open: (state) => {
      state.isOpen = true
    },
  },
});

export const { actions, reducer } = switchVendorModalSlice
