import apiAccess from './apiAccess'


class AsyncTasksAPI {
  static taskDetails(task_id: string, next: Function){
    apiAccess.url(`/async-tasks/${task_id}`)
      .get()
      .json(response => { next(response) })
  }
}

export default AsyncTasksAPI