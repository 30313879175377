import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { reducer as vendorSelectorReducer } from './components/VendorSelector/slice'
import { reducer as productsListReducer } from './components/ProductsList/slice'
import { reducer as contentPublicationsListReducer } from './components/ContentPublicationsList/slice'
import { reducer as productEcModalReducer } from './components/ProductEcModal/slice'
import { reducer as ozonPublicationReducer } from './components/OzonPublication/slice'
import { reducer as asyncJobModalReducer } from './components/AsyncJobModal/slice'
import { reducer as contentBundleCreatorModalReducer } from './components/ContentBundleCreationModal/slice'
import { reducer as ecComponentContentSelectionFormReducer } from './components/ContentBundleCreationModal/EcComponentContentSelectionForm/slice'
import { reducer as exportPresetsListReducer } from './components/HtmlExportPresetsList/slice'
import { reducer as exportPresetModalReducer } from './components/HtmlExportPresetModal/slice'
import { reducer as htmlExportEditorReducer } from './components/HtmlExportEditor/slice'
import { reducer as htmlExportModalReducer } from './components/HtmlExportModal/slice'
import { reducer as contentExportsListReducer } from './components/ContentExportsList/slice'
import { reducer as htmlExportsListReducer } from './components/HtmlExportsList/slice'
import { reducer as htmlExportParamsModalReducer } from './components/HtmlExportParamsModal/slice'
import { reducer as exportConfigsListReducer } from './components/HtmlExportConfigsList/slice'
import { reducer as exportConfigModalReducer } from './components/HtmlExportConfigModal/slice'
import { reducer as switchVendorModalReducer } from './components/SwitchVendorModal/slice'
import { reducer as productDataExportModalReducer } from './components/ProductDataExportModal/slice'
import { reducer as productDataExportsListReducer } from './components/ProductDataExportsList/slice'
import { reducer as userReducer } from './userSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    switchVendorModal: switchVendorModalReducer,
    exportConfigModal: exportConfigModalReducer,
    exportConfigsList: exportConfigsListReducer,
    htmlExportsList: htmlExportsListReducer,
    contentExportsList: contentExportsListReducer,
    contentPublicationsList: contentPublicationsListReducer,
    asyncJobModal: asyncJobModalReducer,
    vendorSelector: vendorSelectorReducer,
    productsList: productsListReducer,
    exportPresetsList: exportPresetsListReducer,
    productEcModal: productEcModalReducer,
    ozonPublication: ozonPublicationReducer,
    contentBundleCreatorModal: contentBundleCreatorModalReducer,
    ecComponentContentSelectionForm: ecComponentContentSelectionFormReducer,
    exportPresetModal: exportPresetModalReducer,
    htmlExportEditor: htmlExportEditorReducer,
    htmlExportModal: htmlExportModalReducer,
    htmlExportParamsModal: htmlExportParamsModalReducer,
    productDataExportModal: productDataExportModalReducer,
    productDataExportsList: productDataExportsListReducer,
  },
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
