import React, {useState} from 'react'
import { withRouter, useHistory } from 'react-router'
import { Form, Input, Button, notification } from 'antd'
import CurrentUser from '../App/CurrentUser'

import AuthAPI from '../../api/auth'
import {Vendor} from '../../types/core'
import {useAppDispatch, useAppSelector} from '../../hooks'
import { actions as userActions } from '../../userSlice'

function LoginPage(){
  const [isRequesting, setIsRequesting] = useState(false)

  const history = useHistory()
  const dispatch = useAppDispatch()

  function onFinish(values: any){
    setIsRequesting(true)

    AuthAPI.login(values['username'], values['password'],
      (token: string, isAdmin: boolean, vendor: Vendor, groups: string[]) => {
        setIsRequesting(false)

        CurrentUser.setToken(token)

        dispatch(userActions.setDetails({
          wasTokenValidated: true,
          isTokenValid: true,
          token,
          isAdmin,
          locale: 'en-US',
          groups,
        }))

        if(vendor){
          CurrentUser.setSelectedVendor(vendor)
        }

        history.replace(CurrentUser.getSelectedVendorId() ? '/' : '/vendor-selector')
      },
      () => {
        setIsRequesting(false)
        notification.error({
          message: 'Error',
          description: 'Invalid login credentials',
        })
      }
    )
  }

  function onFinishFailed(errorInfo: any){
    notification.error({
      message: 'Error',
      description: 'Fill in login form correctly',
    })
  }

  return <div style={{ width: '100%'  }}>
    <div style={{ width: 400, margin: '0 auto' }}>
      <h1 style={{ width: '100%', textAlign: 'center'}}>Login</h1>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={ isRequesting }>
            OK
          </Button>
        </Form.Item>
      </Form>
    </div>
  </div>
}

export default withRouter(LoginPage)