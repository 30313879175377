import {Vendor} from '../../types/core'

class CurrentUser{
  static currentVendor: Vendor
  static vendorLoaded = false
  static token = ''
  static tokenLoaded = false

  static GROUP_DATA_EXPORTERS = 'Data exporters'
  static GROUP_HTML_EXPORTERS = 'HTML exporters'
  static GROUP_OZON_CREATORS = 'OZON creators'
  static GROUP_ASSET_EXPORTERS = 'Asset exporters'

  static getToken(): string{
    return window.localStorage.getItem('user_token') || ''
  }

  static setToken(token: string){
    window.localStorage.setItem('user_token', token)
  }

  static setSelectedVendor(vendor: Vendor){
    window.localStorage.setItem('vendor_name', vendor.name)
    window.localStorage.setItem('vendor_id', vendor.id)
  }

  static getSelectedVendorId(){
    let vendorId = window.localStorage.getItem('vendor_id')

    if(vendorId && vendorId.length > 0){
      return vendorId
    }else{
      return null
    }
  }

  static getSelectedVendorName(){
    return window.localStorage.getItem('vendor_name')
  }
}

export default CurrentUser