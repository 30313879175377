import React from 'react'
import {actions} from './slice'
import OzonWidgetEditor from './OzonWidgetEditor'
import {Button, Input, Select} from 'antd'
import {useAppDispatch} from '../../hooks'
import {DeleteRowOutlined} from '@ant-design/icons'
import {iOzonWidget} from '../../types/ozon'

export default function OzonVideoWidgetEditor({widget, idx, isLast}: { widget: iOzonWidget, idx: number, isLast: boolean }) {
  const dispatch = useAppDispatch()

  return <OzonWidgetEditor widget={widget} idx={idx} title={'Video - ' + widget.type} isLast={ isLast }>
    {
      widget.type === 'embeded' &&
      <div>
        Width: <Input
        value={widget.width}
        placeholder={''}
        style={{width: 200, marginRight: 10}}
        size={'small'}
        onChange={e => dispatch(actions.updateWidgetAttribute({
          widgetIdx: idx,
          attribute: 'width',
          value: e.target.value
        }))}
      />
        Height: <Input
        value={widget.height}
        placeholder={''}
        style={{width: 200}}
        size={'small'}

        onChange={e => dispatch(actions.updateWidgetAttribute({
          widgetIdx: idx,
          attribute: 'height',
          value: e.target.value
        }))}
      />
        {
          widget.sources &&
          <div style={{marginTop: 5}}>
            <strong>Sources</strong>
            <Button type={'link'} onClick={e => dispatch(actions.addVideoSource({widgetIdx: idx}))}
                    size={'small'}>Add</Button>
            <table>
              <thead>
              <tr>
                <th>Type</th>
                <th>URL</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              {
                widget.sources.map((source, sourceIdx) => {
                  return <tr key={sourceIdx}>
                    <td>
                      <Select
                        style={{width: 120}}
                        value={source.type}
                        onChange={value => dispatch(actions.updateVideoSourceProperty({
                          widgetIdx: idx,
                          sourceIdx,
                          prop: 'type',
                          value,
                        }))}
                      >
                        <Select.Option value={'video/mp4'}>mp4</Select.Option>
                        <Select.Option value={'video/webm'}>webm</Select.Option>
                      </Select>
                    </td>
                    <td>
                      <Input
                        style={{width: 400}}
                        value={source.src}
                        onChange={e => dispatch(actions.updateVideoSourceProperty({
                          widgetIdx: idx,
                          sourceIdx,
                          prop: 'src',
                          value: e.target.value
                        }))}
                      />
                    </td>
                    <td>
                      <Button
                        size={'small'}
                        icon={<DeleteRowOutlined />}
                        onClick={e => dispatch(actions.removeVideoSource({widgetIdx: idx, sourceIdx}))}
                      />
                    </td>
                  </tr>
                })
              }
              </tbody>
            </table>
          </div>
        }
      </div>
    }
    {
      widget.type === 'youtube' &&
      <div>
        <Input
          placeholder={'Enter Youtube video ID....'}
          value={widget.id}
          onChange={e => dispatch(actions.updateWidgetAttribute({
            widgetIdx: idx,
            attribute: 'id',
            value: e.target.value
          }))}
        />
      </div>
    }
  </OzonWidgetEditor>
}