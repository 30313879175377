import React, {useEffect, useRef, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../hooks'
import {useParams} from 'react-router'
import ExportPresetsAPI from '../../api/ExportPresetsAPI'
import {Tabs} from 'antd'
import {HtmlExportPreset} from '../../types/html_exports'
import {actions} from './slice'
import { Input, Button } from 'antd'
import HtmlExportsAPI from '../../api/HtmlExportsAPI'
import { cxhComponentPreviewUrl } from '../../lib/urls'


type HtmlExportEditorParams = {
  product_uuid: string
  component_uuid: string
}

const SIDEBAR_WIDTH = 500


export default function HtmlExportEditor() {
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.htmlExportEditor)
  const params = useParams() as HtmlExportEditorParams
  const productUuid = params.product_uuid
  const componentUuid = params.component_uuid
  const [mode, setMode] = useState('presets');
  const [selectedPreset, setPreset] = useState('');
  const iframeEl = useRef<HTMLIFrameElement>(null)

  function setIframeHTML(html: string) {
    let s = `${html}`;
    let blob = new Blob([s], {type: "text/html; charset=utf-8"});

    // "data:text/html;charset=utf-8," + escape(html_string);

    if (iframeEl && iframeEl.current && iframeEl.current !== null) {
      iframeEl.current.src = URL.createObjectURL(blob)
    }
  }

  function updatePreviewWithCode(){
    // HtmlExportsAPI.generate(productUuid, componentUuid, (response: string) => {
    //   setIframeHTML(response)
    // }, { scss: state.sassCode })
  }

  useEffect(() => {
    ExportPresetsAPI.fetchList((presets: Array<HtmlExportPreset>, total: number) => {
      dispatch(actions.setPresets(presets))
    })

    // HtmlExportsAPI.generate(productUuid, componentUuid, (response: string) => {
    //   setIframeHTML(response)
    // }, {})

    HtmlExportsAPI.fetchDefaultScssConfig((sass: string) => {
      dispatch(actions.setSassCode(sass))
    })

  }, [dispatch])
  //
  // useEffect(() => {
  //   HtmlExportsAPI.generate(productUuid, componentUuid, (response: string) => {
  //     setIframeHTML(response)
  //   }, {preset: selectedPreset})
  // }, [selectedPreset])

  return <div style={{height: '100%', width: '100%', overflow: 'hidden'}}>
    <div style={{
      width: SIDEBAR_WIDTH,
      float: 'left',
      backgroundColor: '#eee',
      height: '100%',
      borderRight: '1px solid #aaa',
      verticalAlign: 'top'
    }}
    >
      <div style={{padding: 10, height: '100%'}}>
        <strong>Product:</strong> {params.product_uuid}
        <br />
        <strong>Component:</strong> {params.component_uuid}
        <div style={{ height: 50, padding: '10px 0' }}>
          <Button
            type={'link'}
            target={'_blank'}
            href={ cxhComponentPreviewUrl(productUuid, componentUuid) }
          >CXH Preview</Button>
        </div>
        <Tabs
          tabBarStyle={{ paddingLeft: 10 }}
          activeKey={mode} onChange={setMode} style={{backgroundColor: '#fff', height: 'calc(100% - 110px)'}}>
          <Tabs.TabPane tab={'Presets'} key={'presets'}>
            <ul>
              <li
                style={{fontWeight: '' === selectedPreset ? 'bold' : 'normal'}}
                key={'--'} onClick={() => setPreset('')}
              >Default
              </li>
              {
                state.presets.map(preset => {
                  return <li
                    style={{fontWeight: String(preset.id) === selectedPreset ? 'bold' : 'normal'}}
                    key={preset.id} onClick={() => setPreset(String(preset.id))}
                  >{preset.name}</li>
                })
              }
            </ul>
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Edit'} key={'editor'} style={{ height: '100%' }}>
            <Input.TextArea
              value={ state.sassCode }
              style={{ height: 'calc(100vh - 242px)' }}
              onChange={e => dispatch(actions.setSassCode(e.target.value))}
            />
            <div style={{ height: 50, paddingTop: 10, textAlign: 'right' }}>
              <Button
                type={'primary'}
                onClick={ updatePreviewWithCode }
                style={{ marginRight: 10 }}
              >Update</Button>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
    <div style={{
      float: 'left',
      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
      height: '100%',
      verticalAlign: 'top',
    }}>
      <iframe title={'Preview'} ref={iframeEl} style={{width: '100%', height: '100%', border: 0}}></iframe>
    </div>
  </div>
}