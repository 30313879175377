import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ContentPublication} from '../../types/content_publications'

export interface ContentPublicationsListDataState {
  total: number;
  publications: Array<ContentPublication>;
}

export interface ContentPublicationsListState extends ContentPublicationsListDataState{
  query: string;
  page: number;
  isLoading: boolean;
}

const initialState: ContentPublicationsListState = {
  query: '',
  total: 0,
  page: 1,
  publications: [],
  isLoading: false,
}

export const contentPublicationsListSlice = createSlice({
  name: 'contentPublicationsList',
  initialState,
  reducers: {
    removeFromList: (state, action: PayloadAction<number>) => {
      state.publications = state.publications.filter(pub => pub.id !== action.payload)
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setPublications: (state, action: PayloadAction<ContentPublicationsListDataState>) => {
      state.publications = action.payload.publications
      state.total = action.payload.total
      state.isLoading = false
    }
  },
});

export const { actions, reducer } = contentPublicationsListSlice

