import React from 'react'
import {useAppDispatch, useAppSelector} from '../../hooks'
import {Modal, notification} from 'antd'
import {actions} from './slice'
import { ContentBundleFormat, ContentBundle } from '../../types/content_bundles'

import EcComponentContentSelectionForm from './EcComponentContentSelectionForm/EcComponentContentSelectionForm'
import AsyncProcessController from '../../lib/AsyncProcessController'
import ContentBundlesAPI from '../../api/ContentBundlesAPI'

const FORMAT_NAMES : { [key: number]: string} = {
  [ContentBundleFormat.ROSSMANN]: 'Rossmann',
  [ContentBundleFormat.ASSETS_AS_ZIP]: 'Assets as ZIP',
}

export default function ContentBundleCreationModal(){
  const state = useAppSelector(state => state.contentBundleCreatorModal)
  const dispatch = useAppDispatch()

  let content

  function closeModal(){ dispatch(actions.close()) }

  function onBundleCreationFinish(id: number, successful: boolean){
    if(successful){
      ContentBundlesAPI.getDetails(id, (details: ContentBundle) => {
        closeModal()
        window.location.href = details.download_url
      })
    }else{
      notification.error({
        message: 'Error occured during export process.'
      })
    }
  }

  switch(state.format){
     case ContentBundleFormat.ASSETS_AS_ZIP:
      content = <EcComponentContentSelectionForm
        textsEnabled={ false }
        assetsEnabled={ true }
        productId={ state.productId }
        componentId={ state.componentId}
        closeModal={ closeModal }
        onCreate={(texts: string[], assetIds: string[]) => {
          let cbc = new AsyncProcessController(dispatch)
          cbc.createContentBundle(state.format, state.productId, state.componentId, onBundleCreationFinish, { texts, assetIds })
        }}
      />
      break

    case ContentBundleFormat.ROSSMANN:
      content = <EcComponentContentSelectionForm
        textsEnabled={ true }
        assetsEnabled={ true }
        productId={ state.productId }
        componentId={ state.componentId}
        closeModal={ closeModal }
        onCreate={(texts: string[], assetIds: string[]) => {
          let cbc = new AsyncProcessController(dispatch)

          cbc.createContentBundle(state.format, state.productId, state.componentId, onBundleCreationFinish,{ texts, assetIds })
        }}
      />
      break

    default:
      content = ''
  }

  return <Modal
    visible={ state.isOpen }
    title={
      <span>
        Bundle creation - {state.format ? FORMAT_NAMES[state.format] : ''}
        &nbsp;&nbsp;&nbsp;
        <small style={{ color: '#bbb' }}>{ state.productId } / { state.componentId }</small>
      </span>
    }
    width={ 1050 }
    onCancel={e => dispatch(actions.close())}
    footer={ false }
  >{ content }</Modal>
}