import React, {useEffect} from 'react'
import {Input, Modal, notification} from 'antd'
import {useAppDispatch, useAppSelector} from '../../hooks'
import {actions} from './slice'
import ExportPresetsAPI from '../../api/ExportPresetsAPI'
import {HtmlExportPreset, HtmlExportPresetPayload} from '../../types/html_exports'
import HtmlExportsAPI from '../../api/HtmlExportsAPI'

export default function ExportPresetModal(){
  let state = useAppSelector(state => state.exportPresetModal)
  let presetId = state.presetId
  let dispatch = useAppDispatch()

  useEffect(() => {
    if(presetId){
      ExportPresetsAPI.fetchDetails(presetId, (preset: HtmlExportPreset) => {
        dispatch(actions.setName(preset.name))
        dispatch(actions.setScssConfig(preset.scss_config))
        dispatch(actions.setPreviewHeadAddons(preset.preview_head_addons))
        dispatch(actions.setExportHeadAddons(preset.export_head_addons))
      })
    }else{
      HtmlExportsAPI.fetchDefaultScssConfig((scss: string) => {
        dispatch(actions.setScssConfig(scss))
      })
    }
  }, [dispatch, presetId])

  function onOk(){
    let payload: HtmlExportPresetPayload = {
      name: state.name,
      scss_config: state.scssConfig,
      export_head_addons: state.exportHeadAddons,
      preview_head_addons: state.previewHeadAddons,
    }

    if(state.presetId){
      ExportPresetsAPI.update(state.presetId, payload, () => {
        notification.success({ message: 'Preset was updated'})
        dispatch(actions.close())
      })
    }else{
      ExportPresetsAPI.create(payload, () => {
        notification.success({ message: 'Preset was created'})
        dispatch(actions.close())
      })
    }
  }

  return <Modal
    visible={ state.isOpen }
    title={'Export preset'}
    onOk={ onOk }
    onCancel={() => dispatch(actions.close())}
    width={900}
  >
    <label>Name:</label>
    <Input value={state.name} onChange={e => dispatch(actions.setName(e.target.value))}/>
    <br/><br/>
    <label>SASS Config</label>
    <br/>
    <Input.TextArea
      rows={15}
      style={{ width: '100%' }}
      value={ state.scssConfig }
      onChange={e => dispatch(actions.setScssConfig(e.target.value))}
    />
    <br/><br/>
    <label>Export HEAD tag addons</label>
    <br/>
    <Input.TextArea
      rows={5}
      style={{ width: '100%' }}
      value={ state.exportHeadAddons }
      onChange={e => dispatch(actions.setExportHeadAddons(e.target.value))}
    />
    <br/><br/>
    <label>Preview HEAD tag addons</label>
    <br/>
    <Input.TextArea
      rows={5}
      style={{ width: '100%' }}
      value={ state.previewHeadAddons }
      onChange={e => dispatch(actions.setPreviewHeadAddons(e.target.value))}
    />
  </Modal>
}