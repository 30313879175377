import wretch from 'wretch'
import CurrentUser from '../components/App/CurrentUser'

interface CustomizedWindow extends Window{
  appHost: string;
}

declare let window: CustomizedWindow

export const appHost = window.appHost || 'http://localhost:8000'

const apiAccess = wretch()
  .url(appHost + "/api")
  .catcher(403, error => {
    localStorage.clear()
    if(window.location.href !== appHost){
      window.location.href = appHost
    }
  })
  .defer((w, url, callback) => {
  const token = CurrentUser.getToken()

  if (token) {
    return w.auth('Token ' + token)
  }

  return w
  })

export default apiAccess