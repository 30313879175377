import {AppDispatch} from '../store'
import ContentBundlesAPI from '../api/ContentBundlesAPI'
import { actions as asyncJobModalActions } from '../components/AsyncJobModal/slice'
import ContentPublicationsAPI from '../api/ContentPublicationsAPI'
import AsyncTasksAPI from '../api/AsyncTasksAPI'
import HtmlExportsAPI from '../api/HtmlExportsAPI'
import { HtmlExport } from '../types/html_exports'
import {ContentBundleFormat} from '../types/content_bundles'
import { AsyncTaskState } from '../types/other'
import {ProductDataExport} from '../types/core'
import ProductDataExportsAPI from '../api/ProductDataExportsAPI'

interface iBundlePayload{
  assetIds: string[]
  texts?: string[]
}

export default class AsyncProcessController {
  private readonly dispatch: AppDispatch

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch
  }

  createDataExport(
    dataOwnerUuid: string, receipientUuid: string, distributionTemplateUuid: string, productUuids: string[],
    format: string, dataScheme: string, includeEmptyProducts: boolean, deliverToFtp: boolean, onFinish: Function
  ){
    this.openJobModal('Initializing data export...')

    const payload = {
      'product_uuids': productUuids,
      'data_owner_uuid': dataOwnerUuid,
      'receipient_uuid': receipientUuid,
      'distribution_template_uuid': distributionTemplateUuid,
      'fmt': format,
      'data_scheme': dataScheme,
      'include_empty_products': includeEmptyProducts,
      'deliver_to_ftp': deliverToFtp
    }

    ProductDataExportsAPI.initialize(payload, ({ id, taskId } : { id: number, taskId: string}) => {
      this.monitorAsyncTaskStatus(
        taskId,
        (success: boolean) => {
          onFinish(id, success)
        },
        (iteration: number) => {
          ProductDataExportsAPI.getDetails(id, (exportDetails: ProductDataExport) => {
            this.setJobModalMessage(exportDetails.export_status_message)
          })
        }
      )
    })
  }

  createHtmlExport(
    productUuid: string, componentUuid: string, configId: number,
    variableValues: {[key:string]: string}, selectedWidgets: string[], preset: number, comment: string,
    onFinish: Function
  ){
    this.openJobModal('Exporting to HTML...')

    HtmlExportsAPI.initalizeHtmlExport(
      productUuid, componentUuid, configId, variableValues, selectedWidgets, preset, comment,
      ({ id, taskId } : { id: number, taskId: string}) => {
        this.monitorAsyncTaskStatus(
          taskId,
          (success: boolean) => {
            onFinish(id, success)
          },
          (iteration: number) => {
            HtmlExportsAPI.getDetails(id, (exportDetails: HtmlExport) => {
              this.setJobModalMessage(exportDetails.export_status_message)
            })
          }
        )
      }
    )
  }

  openJobModal(message: string){
    this.dispatch(asyncJobModalActions.open({ message }))
  }

  closeJobModal(){
    this.dispatch(asyncJobModalActions.close())
  }

  setJobModalMessage(message: string){
    this.dispatch(asyncJobModalActions.setMessage(message))
  }

  createContentBundle(
    format: number,
    productUuid: string,
    componentUuid: string,
    onFinish: Function,
    bundlePayload: iBundlePayload
  ): void{
    this.openJobModal('Exporting to ZIP...')

    const monitor = ({ id, taskId } : { id: number, taskId: string }) => {
      this.monitorAsyncTaskStatus(taskId, (success: boolean) => {
        onFinish(id, success)
      })
    }

    switch(format){
      case ContentBundleFormat.ASSETS_AS_ZIP:
        ContentBundlesAPI.initializeGenericAssetsBundle(
          productUuid, componentUuid,
          bundlePayload.assetIds,
          monitor
        )
        break
      case ContentBundleFormat.ROSSMANN:
        ContentBundlesAPI.initializeRossmannBundle(
          productUuid, componentUuid,
          bundlePayload.texts!, bundlePayload.assetIds,
          monitor
        )
        break
    }
  }

  publishContentPublication(contentPublicationId: number, onFinish: Function) : void{
    this.dispatch(asyncJobModalActions.open({ message: 'Publishing...' }))

    ContentPublicationsAPI.publish(contentPublicationId, (taskId: string) => {
      this.monitorAsyncTaskStatus(taskId, onFinish)
    })
  }

  private monitorAsyncTaskStatus(taskId: string, onFinish: Function, onIteration?: Function){
    let finished = false
    let iteration = 1

    let checkInterval = setInterval(() => {
      AsyncTasksAPI.taskDetails(taskId, ({ status, state} : { status: string, state: string}) => {
        if(state === AsyncTaskState.SUCCESS){
          finished = true
          clearInterval(checkInterval)
          this.closeJobModal()
          onFinish(true)
        }
      })

      if(iteration === 180 && !finished) {
        clearInterval(checkInterval)
        this.closeJobModal()
        onFinish(false)
      }

      if(onIteration){
        onIteration(iteration)
      }

      iteration++
    }, 1500)
  }
}