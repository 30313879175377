import React from 'react'
import {Input, Radio} from 'antd'
import {actions} from './slice'
import {useAppDispatch} from '../../hooks'
import TextDropBin, {DroppedText} from './TextDropBin'
import {iOzonWidgetTextObject} from '../../types/ozon'

export default function OzonWidgetTextObjectEditor(
  { widgetIdx, prop, object, placeholder, big = false, blockIdx = undefined }
    :
  { blockIdx?: number, big?: boolean, widgetIdx: number, prop: string, object: iOzonWidgetTextObject, placeholder: string })
{
  const dispatch = useAppDispatch()

  function handleDrop(dropped: DroppedText){
    dispatch(actions.setTextObjectContent({ widgetIdx, prop, content: dropped.text, blockIdx }))
  }

  return <div>
    <TextDropBin
      accepts={['text']}
      onDrop={(item) => handleDrop(item)}
    >
      {
        big ?
          <Input.TextArea
            rows={4}
            placeholder={placeholder}
            onChange={e => dispatch(actions.setTextObjectContent({
              widgetIdx,
              prop,
              contentArray: e.target.value.split("\n"),
              blockIdx
            }))}
            value={object.content.join("\n")}
          />
          :
          <Input
            placeholder={placeholder}
            onChange={e => dispatch(actions.setTextObjectContent({
              widgetIdx,
              prop,
              content: e.target.value,
              blockIdx
            }))}
            value={object.content[0]}
          />
      }
    </TextDropBin>
    <div style={{ display: 'inline-block', marginTop: 5 }}>
      <span style={{ display: 'inline-block' }}>
        <strong>Size</strong>:
        <Radio.Group
          size={'small'}
          value={ object.size }
          onChange={
          e => dispatch(actions.setTextObjectSize({ widgetIdx, prop, size: e.target.value, blockIdx }))
          }
          style={{ marginRight: 10 }}
        >
          <Radio.Button value={'size1'}>1</Radio.Button>
          <Radio.Button value={'size2'}>2</Radio.Button>
          <Radio.Button value={'size3'}>3</Radio.Button>
          <Radio.Button value={'size4'}>4</Radio.Button>
          <Radio.Button value={'size5'}>5</Radio.Button>
        </Radio.Group>
      </span>
      <span style={{ display: 'inline-block' }}>
        <strong>Color</strong>:
        <Radio.Group
          size={'small'}
          value={ object.color } onChange={
          e => dispatch(actions.setTextObjectColor({ widgetIdx, prop, color: e.target.value, blockIdx }))
          }
          style={{ marginRight: 10 }}
        >
          <Radio.Button value={'color1'}>1</Radio.Button>
          <Radio.Button value={'color2'}>2</Radio.Button>
          <Radio.Button value={'color3'}>3</Radio.Button>
          <Radio.Button value={'color4'}>4</Radio.Button>
          <Radio.Button value={'color5'}>5</Radio.Button>
        </Radio.Group>
      </span>
      <span>
        <strong>Align:</strong>
        <Radio.Group
          size={'small'}
          value={ object.align }
          onChange={
            e => dispatch(actions.setTextObjectAlign({ widgetIdx, prop, align: e.target.value, blockIdx }))
          }
          style={{ marginLeft: 0 }}>
          <Radio.Button value={'left'}>Left</Radio.Button>
          <Radio.Button value={'center'}>Center</Radio.Button>
          <Radio.Button value={'right'}>Right</Radio.Button>
        </Radio.Group>
      </span>

    </div>
  </div>
}