import {Button, Input, List, Modal} from 'antd'
import {useAppDispatch, useAppSelector} from '../../hooks'
import {actions} from './slice'
import {actions as ProductsListActions } from '../ProductsList/slice'
import React, {useEffect} from 'react'
import {useDebounce} from '../../utils'
import VendorsAPI from '../../api/VendorsAPI'
import {Vendor} from '../../types/core'
import CurrentUser from '../App/CurrentUser'

export function SwitchVendorModal(){
  const state = useAppSelector(state => state.switchVendorModal)
  const dispatch = useAppDispatch()

  const fetchVendorsDebounced = useDebounce((queryString: string) => {
    dispatch(actions.setIsLoading(true))
    VendorsAPI.fetchList((vendors: Vendor[], total: number) => {
      dispatch(actions.setVendors({ vendors, total }))
    }, { query: queryString })
  }, 500)

  useEffect(() => {
    if(state.isOpen){
      fetchVendorsDebounced(state.query)
    }
  }, [dispatch, state.query])

  function pickVendor(vendor: Vendor){
    CurrentUser.setSelectedVendor(vendor)
    dispatch(ProductsListActions.setVendorId(vendor.id))
    dispatch(actions.close())
  }

  return <Modal
    visible={ state.isOpen }
    title={ 'Switch vendor' }
    onCancel={() => dispatch(actions.close())}
    width={600}
  >
    <Input.Search
      autoFocus={ true }
      placeholder={'Search for vendors....'}
      value={state.query}
      onChange={(evt) => dispatch(actions.setQuery(evt.currentTarget.value))}
    />
      <div
        style={{
          height: 500, overflow: 'auto', padding: '0 16px',
          marginTop: 20,
          border: '1px solid rgba(140, 140, 140, 0.35)'}}
      >
        <List
          loading={state.isLoading}
          dataSource={state.vendors}
          renderItem={(item: Vendor) => (
            <List.Item extra={<Button size={'small'} onClick={() => pickVendor(item)}>Pick</Button> }>
              { item.name }
            </List.Item>
          )}
        />
      </div>
    </Modal>
}