import apiAccess from './apiAccess'

class VendorsAPI{
  static fetchList(next: Function, params: { query: string }){
    apiAccess.url("/vendors")
      .query(params)
      .get()
      .json(response => {
        next(response['vendors'], response['total'])
      })
  }

  static fetchVendorReceipients(vendorUuid: string, next: Function){
    apiAccess.url(`/vendors/${vendorUuid}/receipients`)
      .get()
      .json(response => {
        next(response['receipients'], response['total'])
      })
  }


  static fetchDistributionTemplates(vendorUuid: string, receipientUuid: string, next: Function){
    apiAccess.url(`/vendors/${vendorUuid}/templates/${receipientUuid}`)
      .get()
      .json(response => {
        next(response['templates'], response['total'])
      })
  }
}


export default VendorsAPI