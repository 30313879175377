import apiAccess from './apiAccess'

export default class ProductDataExportsAPI {
  static initialize(payload: any, next: Function) {
    apiAccess.url("/product-data-exports")
      .json(payload)
      .post()
      .json(resp => {
        next({ id: resp['id'], taskId: resp['task_id'] })
      })
  }

  static fetchList(next: Function) {
   apiAccess.url(`/product-data-exports`)
      .get()
      .json(resp => {
        next(resp)
      })
  }

  static getDetails(productDataExportId: number, next: Function) {
   apiAccess.url(`/product-data-exports/${productDataExportId}`)
      .get()
      .json(resp => {
        next(resp)
      })
  }
}