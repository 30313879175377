import apiAccess from './apiAccess'
import { ContentBundleFormat} from '../types/content_bundles'

class ContentBundlesAPI{
  static fetchList(next: Function){
    apiAccess.url(`/content-bundles`)
      .get()
      .json(resp => {
        next(resp)
      })
  }

  static getDetails(bundle_id: number, next: Function){
    apiAccess.url(`/content-bundles/${bundle_id}`)
      .get()
      .json(resp => {
        next(resp)
      })
  }

  static initializeGenericAssetsBundle(
    cxh_product_uuid: string, cxh_ec_component_uuid: string, selected_asset_uuids: string[], next: Function
  ){
    apiAccess.url(`/content-bundles`)
      .post({
        'format': ContentBundleFormat.ASSETS_AS_ZIP,
        cxh_product_uuid, cxh_ec_component_uuid, selected_asset_uuids
      })
      .json(resp => {
        next({ id: resp['id'], taskId: resp['task_id'] })
      })
  }

  static initializeRossmannBundle(
    cxh_product_uuid: string, cxh_ec_component_uuid: string,
    selected_texts: string[], selected_asset_uuids: string[],
    next: Function
  ){
    apiAccess.url(`/content-bundles`)
      .post({
        'format': ContentBundleFormat.ROSSMANN,
        cxh_product_uuid, cxh_ec_component_uuid,
        selected_texts, selected_asset_uuids
      })
      .json(resp => {
        next({ id: resp['id'], taskId: resp['task_id'] })
      })
  }
}

export default ContentBundlesAPI