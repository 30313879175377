import {iOzonWidget} from './ozon'

interface iContentPublicationData{
  ozon_widgets: Array<iOzonWidget>
}

interface iContentPublicationCreator{
  username: string
}

export interface ContentPublication{
  id: number
  user: iContentPublicationCreator
  cxh_product_uuid: string
  cxh_ec_component_uuid: string
  cxh_ec_component_language: string
  cxh_product_name: string
  cxh_product_gtin?: string
  status: number
  data: iContentPublicationData
  created: string
  format: number
}

export interface iContentPublicationUploadedAsset{
  id: number
  url: string
  name: string
  width?: number
  height?: number
  size: number
}

export interface iContentPublicationUpdate{
  status?: number
  ozon_widgets?: Array<iOzonWidget>
}

export class ContentPublicationStatuses{
  static readonly DRAFT = 1
  static readonly PUBLISHED = 5
  static readonly CEASED = 9
}

export class ContentPublicationFormats{
  static readonly OZON = 1
}

export const ContentPublicationStatusesNames: {[key: number]: string } = {
  [ContentPublicationStatuses.DRAFT]: 'Draft',
  [ContentPublicationStatuses.PUBLISHED]: 'Published',
  [ContentPublicationStatuses.CEASED]: 'Ceased',
}

export const ContentPublicationFormatsNames: { [key: number]: string } = {
  [ContentPublicationFormats.OZON]: 'Ozon',
}