import AppMenu from '../App/Menu'
import {Button, PageHeader, Table} from 'antd'
import React, {useEffect} from 'react'
import {
  HtmlExportConfig,
  HtmlExportPreset,
  HtmlExportConfigOutputter,
  HtmlExportConfigAssetsMode
} from '../../types/html_exports'
import {actions as exportConfigModalActions} from '../HtmlExportConfigModal/slice'
import {useAppDispatch, useAppSelector} from '../../hooks'
import HtmlExportConfigModal from '../HtmlExportConfigModal/HtmlExportConfigModal'
import {actions} from './slice'
import HtmlExportConfigsAPI from '../../api/HtmlExportConfigsAPI'

const FORMATS : {[key: number] : string} = {
  0: 'File only',
  1: 'Link',
  2: 'Embed',
}

export default function ExportConfigsList(){
  const state = useAppSelector(state => state.exportConfigsList)
  const dispatch = useAppDispatch()


  useEffect(() => {
    HtmlExportConfigsAPI.fetchList((configs: Array<HtmlExportConfig>, total: number) => {
      dispatch(actions.setConfigs({configs, total}))
    })
  }, [dispatch])


  function triggerDelete(configId: number){
    if(window.confirm('Are you sure?')){
      window.alert('OK')
    }
  }

  let content = <Table
    bordered={true}
    rowKey={'id'}
    size={'small'}
    dataSource={state.configs}
    loading={state.isLoading}
    pagination={false}
  >
    <Table.Column
      title={'ID'} key={'id'} dataIndex={'id'} render={(text, record) => text}
    />
    <Table.Column
      title={'Name'} key={'name'} dataIndex={'name'} render={(text, record) => text}
    />
    <Table.Column
      title={'Outputter'} key={'outputter'}
      dataIndex={'outputter_id'}
      render={(base_id: number, record: HtmlExportConfig) => HtmlExportConfigOutputter.NAMES[record.outputter_id]}
    />
    <Table.Column
      title={'Assets mode'} key={'assets_mode'} dataIndex={'assets_mode'}
      render={(text, record: HtmlExportConfig) => HtmlExportConfigAssetsMode.NAMES[record.assets_mode] }
    />
    <Table.Column
      title={'JS'} key={'js_enabled'} dataIndex={'name'}
      render={(text, record: HtmlExportConfig) => record.js_enabled ? 'Yes' : 'No'}
    />
    <Table.Column
      title={'Video'} key={'video_enabled'} dataIndex={'name'}
      render={(text, record: HtmlExportConfig) => record.video_enabled ? 'Yes' : 'No'}
    />
    <Table.Column
      title={'Src-sets'} key={'src_sets'} dataIndex={'name'}
      render={(text, record: HtmlExportConfig) => record.src_sets_enabled ? 'Yes' : 'No'}
    />
    <Table.Column
      title={'JS fmt'} key={'js_format'} dataIndex={'name'}
      render={(text, record: HtmlExportConfig) => FORMATS[record.scripts_format]}
    />
    <Table.Column
      title={'CSS fmt'} key={'css_format'} dataIndex={'name'}
      render={(text, record: HtmlExportConfig) => FORMATS[record.styles_format]}
    />
    <Table.Column render={(text, record: HtmlExportConfig) => {
      return <>
        <Button
          type={'default'}
          onClick={e => dispatch(exportConfigModalActions.openForEdit(record.id))}
          size={'small'}
          style={{ marginRight: 20 }}
        >Edit</Button>
        {
          !record.is_default &&
          <Button
            danger={true}
            type={'dashed'}
            onClick={e => triggerDelete(record.id) }
            size={'small'}
          >Delete</Button>
        }
      </>
    }}
    />
  </Table>

  return <div>
    <AppMenu selectedKey={'export-configs'} />
    <PageHeader
      title={'Export configs'}
      backIcon={false}
      extra={<Button onClick={() => dispatch(exportConfigModalActions.open())}>Add</Button>}
    />
    <div style={{padding: '0 20px'}}>
      {content}
    </div>
    <HtmlExportConfigModal />
  </div>
}