import React, {useEffect} from 'react'
import AppMenu from '../App/Menu'
import {Button, PageHeader, Table, Dropdown, Menu, Tag} from 'antd'
import {actions} from './slice'
import {useAppDispatch, useAppSelector} from '../../hooks'
import ContentPublicationsAPI from '../../api/ContentPublicationsAPI'
import {
  ContentPublicationFormatsNames,
  ContentPublicationStatuses,
  ContentPublication
} from '../../types/content_publications'
import {useHistory} from 'react-router-dom'
import {formatDateTime} from '../../lib'
import {ContentPublicationStatusTag} from '../minis'
import {CodeOutlined, DeleteOutlined} from '@ant-design/icons'

const PAGE_SIZE = 100

export default function ContentPublicationsList(){
  const dispatch = useAppDispatch()
  const history = useHistory()
  const state = useAppSelector(state => state.contentPublicationsList)

  useEffect(() => {
    function fetchProducts() {
      dispatch(actions.setIsLoading(true))

      ContentPublicationsAPI.fetchList((publications: Array<ContentPublication>, total: number) => {
        dispatch(actions.setPublications({ publications, total }))
      })
    }

    fetchProducts()
  }, [dispatch])

  function deletePublication(publicationId: number){
    if(window.confirm('Are you sure?')){
      ContentPublicationsAPI.delete(publicationId, (deleted: boolean) => {
        if(deleted){
          dispatch(actions.removeFromList(publicationId))
        }
      })
    }
  }

  function editPublication(publication: ContentPublication){
    function goToOzonEditor(){
      history.push(`/content-publications/${publication.id}/ozon`)
    }

    goToOzonEditor()
  }

  return <>
    <AppMenu selectedKey={'content-publications'}/>
    <PageHeader title={'Content publications'} backIcon={ false } />
    <div style={{ padding: '0 20px' }}>
      <Table
        bordered={true}
        rowKey={'id'}
        size={'small'}
        dataSource={state.publications}
        loading={state.isLoading}
        pagination={{
          total: state.total,
          showSizeChanger: false,
          pageSize: PAGE_SIZE,
          position: ['topLeft', 'bottomRight'],
          onChange: (page: number) => dispatch(actions.setPage(page)),
          current: state.page,
        }}
      >
        <Table.Column
          title={'ID'} key={'id'} dataIndex={'id'} render={(text, record) => text}
        />
        <Table.Column
          title={'Product name'} key={'name'} dataIndex={'name'} render={(text, record: ContentPublication) => {
          return <>
            <Button type={'link'}
              onClick={e => editPublication(record)}
            >{ record.cxh_product_name }</Button>
            {
              record.cxh_product_gtin && record.cxh_product_gtin.length > 0 &&

              <small style={{ display: 'inline-block', marginRight: 8 }}>
                <Tag>{ record.cxh_product_gtin }</Tag>
              </small>
            }
            <ContentPublicationStatusTag status={ record.status }/>
          </>
        }} />
        <Table.Column
          title={'Format'} key={'format'} dataIndex={'format'} render={(text, record: ContentPublication) => {
            return ContentPublicationFormatsNames[record.format]
        }} />
        <Table.Column
          title={'Language'} key={'language'} dataIndex={'language'} render={(text, record: ContentPublication) => {
            return record.cxh_ec_component_language
        }} />
        <Table.Column
          title={'User'} key={'user'} dataIndex={'user'} render={(text, record: ContentPublication) => {
          return record.user.username
        }} />
        <Table.Column
          title={'Created'} key={'created'} dataIndex={'created'} render={(text, record: ContentPublication) => {
            return formatDateTime(record.created)
          }
        }
        />
        <Table.Column
          title={'Published'} key={'published_at'} dataIndex={'published_at'} render={(text, record: ContentPublication) => {
            return text ? formatDateTime(text) : ''
          }
        }
        />
        <Table.Column
          title={'Actions'} key={'actions'} dataIndex={'actions'} render={(text, record: ContentPublication) => {
           const jsonMenu = (
            <Menu>
              <Menu.Item
                key="download"
                onClick={() => window.location.href =  `/api/content-publications/${record.id}/download-published-json`}
              >
                Download as file
              </Menu.Item>
              <Menu.Item
                key="open"
                onClick={() => window.location.href = `/api/content-publications/${record.id}/published-json` }
              >
                <Button
                  type={'link'}
                  href={`/api/content-publications/${record.id}`}
                  target={'_blank'}
                  style={{ padding: 0 }}
                >Open</Button>
              </Menu.Item>
            </Menu>
          );

            return <>
              {
                record.status === ContentPublicationStatuses.PUBLISHED &&
                  <>
                    <Dropdown overlay={jsonMenu}>
                      <Button size={'small'} style={{ marginRight: 15 }} icon={<CodeOutlined/>}>
                        JSON
                      </Button>
                    </Dropdown>
                  </>
              }
                <Button
                  danger={true}
                  type={'primary'}
                  icon={<DeleteOutlined/>}
                  onClick={e => deletePublication(record.id) }
                  size={'small'}
                ></Button>
            </>
        }}/>
      </Table>
    </div>
  </>
}