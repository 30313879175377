import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {HtmlExportPreset} from '../../types/html_exports'

export interface iHtmlExportEditorState {
  presets: Array<HtmlExportPreset>,
  sassCode: string,
}

const initialState: iHtmlExportEditorState = {
  presets: [],
  sassCode: '',
}

export const htmlExportEditorSlice = createSlice({
  name: 'exportPresetModal',
  initialState,
  reducers: {
    setSassCode: (state, action: PayloadAction<string>) => {
      state.sassCode = action.payload
    },
    setPresets: (state, action: PayloadAction<Array<HtmlExportPreset>>) => {
      state.presets = action.payload
    }
  },
});

export const { actions, reducer } = htmlExportEditorSlice
