import React from 'react'
import {actions} from './slice'
import {Button, Card, Input} from 'antd'
import {useAppDispatch} from '../../hooks'
import {
  DeleteColumnOutlined,
  DeleteRowOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined
} from '@ant-design/icons'
import OzonWidgetTextObjectEditor from './OzonWidgetTextObjectEditor'
import { iOzonWidget, iOzonTableWidgetHeadCell } from '../../types/ozon'
import ImageDropBin from './ImageDropBin'
import {AssetFetchedFromEcComponent} from '../../types/core'

interface iDroppedAsset{
  type: string
  asset: AssetFetchedFromEcComponent
}

export default function OzonTableWidgetEditor({
                                                widget,
                                                idx,
                                                isLast
                                              }: { widget: iOzonWidget, idx: number, isLast: boolean }) {
  const dispatch = useAppDispatch()

  function handleHeadCellDrop(columnIdx: number, droppedItem: iDroppedAsset){
    dispatch(actions.updateTableHeadCellImage({
      widgetIdx: idx,
      columnIdx,
      imageSrc: droppedItem.asset.url,
    }))
  }

  return <Card
    title={'Table'}
    size={'small'}
    bordered={true}
    extra={
      <>
        <Button.Group style={{marginRight: 10}}>
          {
            idx !== 0 &&
            <Button size={'small'} icon={<VerticalAlignTopOutlined />}
                    onClick={e => dispatch(actions.moveWidgetUp(idx))}>Up</Button>
          }
          {
            !isLast &&
            <Button size={'small'} icon={<VerticalAlignBottomOutlined />}
                    onClick={e => dispatch(actions.moveWidgetDown(idx))}>Down</Button>
          }
        </Button.Group>
        <Button size={'small'} onClick={e => dispatch(actions.removeWidget(idx))}>Remove</Button>
      </>
    }
    style={{border: '1px solid #ddd', marginBottom: 10}}
  >
    {
      widget.title !== undefined &&
      <OzonWidgetTextObjectEditor
        widgetIdx={idx}
        prop={'title'}
        object={widget.title}
        placeholder={'Enter title...'}
      />
    }
     <br />
     <table style={{ minWidth: '30%', maxWidth: '100%' }}>
       <thead>
       <tr>
         <th key={'empty'}></th>
         {
          widget.table!.head.map((headCell: iOzonTableWidgetHeadCell, columnIdx: number) => {
            return <th style={{border: '1px solid #000', padding: 4, height: 90 }} key={'h' + columnIdx}>
                <div style={{ display: 'inline-block', width: 'calc(50%)', verticalAlign: 'middle', paddingTop: 20 }}>
                <Input
                  style={{ marginRight: 10, display: 'inline-block', width: 'calc(100% - 40px)' }}
                  value={headCell.text[0]}
                  onChange={e => dispatch(actions.updateTableHeadCellText({
                    widgetIdx: idx,
                    columnIdx,
                    newText: e.target.value
                  }))}
                />
                <Button
                  icon={<DeleteColumnOutlined />}
                  onClick={e => dispatch(actions.removeTableColumn({widgetIdx: idx, columnIdx}))}
                  size={'small'}
                  style={{ width: 25 }}
                />
              </div>
              <div style={{ display: 'inline-block', width: 'calc(50%)', verticalAlign: 'top' }}>
                <ImageDropBin
                  accepts={['image']}
                  lastDroppedItem={ null }
                  onDrop={(item) => handleHeadCellDrop(columnIdx, item)}
                  backgroundImageUrl={ headCell.img.src }
                  size={[100, 70]}
                />
              </div>
            </th>
          })
        }
      </tr>
      </thead>
      <tbody>
      {
        widget.table!.body.map((row: Array<Array<string>>, rowIdx: number) => {
          return <tr key={rowIdx}>
            <td key={'delete'}>
              <Button
                icon={<DeleteRowOutlined />}
                onClick={e => dispatch(actions.removeTableRow({widgetIdx: idx, rowIdx}))}
                size={'small'}
              />
            </td>
            {
              row.map((cellContentLines, cellIdx) => {
                return <td key={cellIdx} style={{border: '1px solid #ccc', padding: 4}}>
                  <Input
                    value={cellContentLines.join("\n")}
                    onChange={e => dispatch(actions.updateTableBodyCellText({
                      newText: e.target.value,
                      widgetIdx: idx,
                      rowIdx, cellIdx
                    }))}
                  />
                </td>
              })
            }
          </tr>
        })
      }
      </tbody>
    </table>

    <Button type={'link'} onClick={e => dispatch(actions.addTableRow(idx))}>Add row</Button>
    <Button type={'link'} onClick={e => dispatch(actions.addTableColumn(idx))}>Add column</Button>
  </Card>
}