import apiAccess from './apiAccess'

interface SearchParams{
  query: string
  page: number
  locale: string
  gtins: string[]
}

interface ComponentDetailsParams{
  include_widgets: boolean
  include_assets: boolean
}

export default class ProductsAPI{
  static checkComponentValidity(product_uuid: string, component_uuid: string, next: Function){
    apiAccess.url(`/products/${product_uuid}/enhanced-content/${component_uuid}/validate`)
      .get()
      .json(response => {
        next(response['is_valid'])
      })
  }

  static fetchExportSeedParams(product_uuid: string, keys: string[], next: Function){
     apiAccess.url(`/products/${product_uuid}/html-export-seed-params`)
      .query({ keys })
      .get()
      .json(response => next(response))
  }

  static finder(mode: string, vendor_uuid: string | null, query: string, next: Function){
     apiAccess.url(`/products/finder`)
      .query({ mode, query, vendor_uuid })
      .get()
      .notFound(error => { next(error) })
      .json(response => next(response))
  }

  static fetchList(vendor_uuid: string, next: Function, params: SearchParams){
    apiAccess.url(`/vendors/${vendor_uuid}/products`)
      .query(params)
      .get()
      .json(response => {
        next(response['products'], response['total'])
      })
  }

  static fetchDetails(product_uuid: string, next: Function){
    apiAccess.url(`/products/${product_uuid}`)
      .get()
      .json(response => {
        next(response)
      })
  }

  static fetchProductEcComponentAssetsAndTexts(product_uuid: string, component_uuid: string, next: Function){
    apiAccess.url(`/products/${product_uuid}/enhanced-content/${component_uuid}/assets-and-texts`)
      .get()
      .json(response => {
        next(response['assets'], response['texts'])
      })
  }

  static fetchProductEcComponent(
    product_uuid: string, component_uuid: string,
    next: Function,
    params: ComponentDetailsParams = { include_widgets: false, include_assets: false }
  ){
    let query_params = {
      'include_widgets': params.include_widgets ? '1' : '0',
      'include_assets': params.include_assets ? '1' : '0',
    }

    apiAccess.url(`/products/${product_uuid}/enhanced-content/${component_uuid}`)
      .query(query_params)
      .get()
      .json(response => {
        next(response)
      })
  }

  static fetchProductEc(product_uuid: string, next: Function){
    apiAccess.url(`/products/${product_uuid}/enhanced-content`)
      .get()
      .json(response => {
        next(response['components'])
      })
  }
}
