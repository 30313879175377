import React, {useEffect} from 'react'
import {Button, notification, PageHeader, Table} from 'antd'
import {useAppDispatch, useAppSelector} from '../../hooks'
import AppMenu from '../App/Menu'
import {actions} from './slice'
import ExportPresetModal from '../HtmlExportPresetModal/ExportPresetModal'
import {actions as exportPresetModalActions} from '../HtmlExportPresetModal/slice'
import ExportPresetsAPI from '../../api/ExportPresetsAPI'
import {HtmlExportPreset} from '../../types/html_exports'

export default function ExportPresetsList() {
  const state = useAppSelector(state => state.exportPresetsList)
  const dispatch = useAppDispatch()

  useEffect(() => {
    ExportPresetsAPI.fetchList((presets: Array<HtmlExportPreset>, total: number) => {
      dispatch(actions.setPresets({presets, total}))
    })
  }, [dispatch])

  function triggerDelete(preset: HtmlExportPreset){
    if(window.confirm('Are you sure?')){
      ExportPresetsAPI.delete(preset.id, function({ deleted }: { deleted: boolean}){
        if(deleted){
          dispatch(actions.removeFromList(preset.id))
          notification.success(({
            message: 'Preset has been removed'
          }))
        }
      })
    }
  }

  let content = <Table
    bordered={true}
    rowKey={'id'}
    size={'small'}
    dataSource={state.presets}
    loading={state.isLoading}
    pagination={false}
  >
    <Table.Column
      title={'ID'} key={'id'} dataIndex={'id'} render={(text, record) => text}
    />
    <Table.Column
      title={'Nazwa'} key={'name'} dataIndex={'name'} render={(text, record) => text}
    />
    <Table.Column render={(text, record: HtmlExportPreset) => {
      return <>
        <Button
          type={'default'}
          onClick={e => dispatch(exportPresetModalActions.openForEdit(record.id))}
          size={'small'}
          style={{ marginRight: 20 }}
        >Edit</Button>
        <Button
          danger={true}
          type={'dashed'}
          onClick={e => triggerDelete(record) }
          size={'small'}
        >Delete</Button>
      </>
    }}
    />
  </Table>

  return <div>
    <AppMenu selectedKey={''} />
    <PageHeader
      title={'Export presets'}
      backIcon={false}
      extra={<Button onClick={() => dispatch(exportPresetModalActions.open())}>Add</Button>}
    />
    <div style={{padding: '0 20px'}}>
      {content}
    </div>
    <ExportPresetModal />
  </div>
}