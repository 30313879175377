import {Modal, Space, Radio, Tag, notification, Checkbox} from 'antd'
import {useAppDispatch, useAppSelector} from '../../hooks'
import {useEffect} from 'react'
import {DistributionTemplate, ProductDataExport, ProductDataExportDataSchemes, Receipient} from '../../types/core'
import {actions} from './slice'
import VendorsAPI from '../../api/VendorsAPI'
import CurrentUser from '../App/CurrentUser'
import AsyncProcessController from '../../lib/AsyncProcessController'
import ProductDataExportsAPI from '../../api/ProductDataExportsAPI'

const TEST_UUIDS = [
  '34e19e85-c20b-3ac0-5124-5783ae4d79e8',
  '9703a937-bbab-44de-9ab0-d89c13372233',
  '934c7221-5515-b2b3-efa9-f7de12a4b97b',
  'e69f3816-e167-43fa-8fd4-3072626b9c63',
  'd4b2f04b-dc6e-471b-ac99-c87f33d25155'
]

export default function ProductDataExportModal() {
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.productDataExportModal)
  const vendorId = CurrentUser.getSelectedVendorId() || ''

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(actions.open(TEST_UUIDS))
  //   }, 1000)
  // }, [dispatch])

  useEffect(() => {
    if (state.isOpen && state.receipientUuid) {
      VendorsAPI.fetchDistributionTemplates(vendorId, state.receipientUuid, (templates: DistributionTemplate[]) => {
        dispatch(actions.setTemplates(templates))
      })
    }
  }, [dispatch, state.isOpen, state.receipientUuid])

  useEffect(() => {
    if (state.isOpen) {
      VendorsAPI.fetchVendorReceipients(vendorId, (receipients: Receipient[], total: number) => {
        dispatch(actions.setReceipients(receipients))
      })
    }
  }, [state.isOpen])

  function onOk() {
    if (state.receipientUuid && state.distributionTemplateUuid && state.selectedFormat.length > 0 && state.dataScheme.length > 0) {
      let apc = new AsyncProcessController(dispatch)

      apc.createDataExport(
        vendorId, state.receipientUuid, state.distributionTemplateUuid, state.productUuids,
        state.selectedFormat, state.dataScheme, state.includeEmptyProducts, state.deliverToFtp,
        (exportId: number, success: boolean) => {
          if(success){

            ProductDataExportsAPI.getDetails(exportId, (exp: ProductDataExport) => {
              if(exp.deliver_to_ftp){
                notification.success({
                  message: 'Export was delivered to FTP server'
                })
              }else{
                let hst = window.location.protocol + '//' + window.location.host
                window.location.href =  `${hst}/api/product-data-exports/${exportId}/download`
              }
            })

            dispatch(actions.close())
          }
        }
      )
    }else{
      notification.warning({
        message: 'Please select all fields'
      })
    }
  }

  return <Modal
    visible={state.isOpen}
    title={'Export product data'}
    onCancel={() => dispatch(actions.close())}
    onOk={onOk}
  >
    { false && <>
        <h3>Select receipient</h3>
        <Radio.Group
          onChange={(e) => dispatch(actions.setSelectedReceipient(e.target.value))}
          value={state.receipientUuid}
        >
          <Space direction="vertical">
            {
              state.receipients.map((rec: Receipient) => {
                return <Radio value={rec.id}>{rec.name}</Radio>
              })
            }
          </Space>
        </Radio.Group>
        <br /><br />
      </>
    }
    {
      state.receipientUuid &&
      <>
        <h3>Select template</h3>
        {state.templates.length > 0 ?
          <Radio.Group
            onChange={(e) => dispatch(actions.setSelectedTemplateUuid(e.target.value))}
            value={state.distributionTemplateUuid}
          >
            <Space direction="vertical">
              {
                state.templates.map((dt: DistributionTemplate) => {
                  return <Radio value={dt.id}>
                    {dt.name}
                  </Radio>
                })
              }
            </Space>
          </Radio.Group>
          :
          <div>No templates found for this receipient</div>
        }
      </>
    }
    {
      state.distributionTemplateUuid &&
      <>
        <br /><br />
        <h3>Export settings</h3>
        <label style={{marginRight: 10, display: 'inline-block'}}>
          Data format
        </label>
        <Radio.Group
          onChange={(e) => dispatch(actions.setSelectedFormat(e.target.value))}
          value={state.selectedFormat}
        >
          <Radio value={'excel'}>Excel</Radio>
          <Radio value={'csv'}>CSV</Radio>
        </Radio.Group>
        <br /><br />
        <label style={{marginRight: 10, display: 'inline-block'}}>
          Data scheme
        </label>
        <br /><br />
        <Radio.Group
          onChange={(e) => dispatch(actions.setDataScheme(e.target.value))}
          value={state.dataScheme}
        >
          <Radio value={ProductDataExportDataSchemes.PRODUCTS_AS_ROWS}>
            Products as rows, attributes as columns
          </Radio>
          <Radio value={ProductDataExportDataSchemes.ATTRIBUTES_AS_ROWS}>
            Attributes as rows, products as columns
          </Radio>
          <Radio value={ProductDataExportDataSchemes.ATTRIBUTE_PER_LINE}>
            One attribute per line
          </Radio>
        </Radio.Group>
        <br/><br/>
        <Checkbox
          style={{ marginRight: 10 }}
          checked={ state.includeEmptyProducts }
          onClick={() => dispatch(actions.toggleIncludingEmptyProducts()) }
        />
        <span
          onClick={() => dispatch(actions.toggleIncludingEmptyProducts()) }
        >Include empty products</span>
        <br/><br/>
        <Checkbox
          style={{ marginRight: 10 }}
          checked={ state.deliverToFtp }
          onClick={() => dispatch(actions.toggleFtpDelivery()) }
        />
        <span
          onClick={() => dispatch(actions.toggleFtpDelivery()) }
        >Deliver to FTP</span>
      </>
    }
  </Modal>
}