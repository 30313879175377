import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Locale, Product} from '../../types/core'

export interface ProductsListDataState {
  total: number;
  products: Array<Product>;
}

export interface ProductsListState extends ProductsListDataState{
  query: string;
  vendorId: string;
  page: number;
  isLoading: boolean;
  selected: string[];
  locales: Locale[];
  locale: string;
  gtins: string
}

const initialState: ProductsListState = {
  query: '',
  total: 0,
  page: 1,
  vendorId: '',
  products: [],
  locales: [],
  isLoading: false,
  selected: [],
  locale: 'en-US',
  gtins: '',
}

export const productsListSlice = createSlice({
  name: 'productsList',
  initialState,
  reducers: {
    toggleSelectAll: (state) => {
      if(state.selected.length > 0){
        state.selected = []
      }else{
        state.selected = state.products.map((p: Product) => p.id)
      }
    },
    setLocales: (state, action: PayloadAction<Locale[]>) => {
      state.locales = action.payload
    },
    setGtins: (state, action: PayloadAction<string>) => {
      state.gtins = action.payload
    },
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload
      state.page = 1
      state.products = []
      state.selected = []
    },
    init: (state, action: PayloadAction<{ vendorId: string, locale: string }>) => {
      state.vendorId = action.payload.vendorId
      state.locale = action.payload.locale
      state.query = ''
      state.page = 1
      state.products = []
      state.selected = []
      state.gtins = ''
    },
    setVendorId: (state, action: PayloadAction<string>) => {
      state.vendorId = action.payload
      state.query = ''
      state.page = 1
      state.products = []
      state.selected = []
      state.gtins = ''
    },
    toggleSelectedProduct: (state, action: PayloadAction<string>) => {
      const productUuid = action.payload

      if(state.selected.includes(productUuid)){
        state.selected = state.selected.filter(item => item !== productUuid)
      }else{
        state.selected.unshift(productUuid)
      }
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setProducts: (state, action: PayloadAction<ProductsListDataState>) => {
      state.products = action.payload.products
      state.total = action.payload.total
      state.isLoading = false
    }
  },
});

export const { actions, reducer } = productsListSlice

