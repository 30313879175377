import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  iOzonWidget,
  iOzonWidgetBlockImage,
  iOzonWidgetBlock,
  iOzonWidgetTextObject,
  iOzonVideoSource
} from '../../types/ozon'
import {ContentPublication, iContentPublicationUploadedAsset} from '../../types/content_publications'
import {AssetFetchedFromEcComponent, TextFetchedFromEcComponent} from '../../types/core'

function newTextObject(size: string = 'size1'): iOzonWidgetTextObject{
  return {
    'size': size,
    'color': 'color1',
    'align': 'left',
    'content': ['']
  }
}

function newOzonWidgetBlockImage() : iOzonWidgetBlockImage{
  return {
    'src': '',
    'srcMobile': '',
    'alt': '',
    'width': '',
    'height': '',
    'heightMobile': '',
    'widthMobile': '',
  }
}

function newVideoSource(): iOzonVideoSource{
  return {
    'type': '',
    'src': '',
  }
}

function newWidgetBlock(elements: Array<string>): iOzonWidgetBlock{
  let out :iOzonWidgetBlock = {}

  if(elements.includes('texts')){
    out['title'] = newTextObject('size3')
    out['text'] = newTextObject()
  }

  if(elements.includes('img')){
    out['img'] = newOzonWidgetBlockImage()
  }

  if(elements.includes('link')){
    out['imgLink'] = ''
  }

  if(elements.includes('reverse')){
    out['reverse'] = false
  }

  return out
}

function newOzonWidget(): iOzonWidget{
  return {
    widgetName: '',
  }
}

export interface OzonPublicationState{
  isLoadingAvailableContent: boolean;
  textsFromEc: TextFetchedFromEcComponent[];
  assetsFromEc: Array<AssetFetchedFromEcComponent>;
  uploadedAssets: Array<iContentPublicationUploadedAsset>
  widgets: Array<iOzonWidget>;
  status: number;
  cxh_product_uuid: string;
  cxh_ec_component_uuid: string;
}


const initialState: OzonPublicationState = {
  isLoadingAvailableContent: false,
  assetsFromEc: [],
  textsFromEc: [],
  uploadedAssets: [],
  widgets: [],
  status: 0,
  cxh_product_uuid: '',
  cxh_ec_component_uuid: ''
}

export const ozonPublicationSlice = createSlice({
  name: 'ozonPublication',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<number>) => {
      state.status = action.payload
    },
    prependUploadedAsset: (state, action: PayloadAction<iContentPublicationUploadedAsset>) => {
      state.uploadedAssets.unshift(action.payload)
    },
    moveWidgetUp: (state, action: PayloadAction<number>) => {
      let widgetToMove = state.widgets[action.payload]
      state.widgets[action.payload] = state.widgets[action.payload - 1]
      state.widgets[action.payload - 1] = widgetToMove
    },
    moveWidgetDown: (state, action: PayloadAction<number>) => {
      let widgetToMove = state.widgets[action.payload]
      state.widgets[action.payload] = state.widgets[action.payload + 1]
      state.widgets[action.payload + 1] = widgetToMove
    },
    addWidget: (state, action: PayloadAction<string>) => {
      const name = action.payload

      if(name.indexOf('-') !== -1){
        let tmp = name.split('-')

        if(tmp[1] === 'list'){
          switch(tmp[0]){
            case 'bullet':
            case 'image':
            case 'number':
              let elements = ['texts']
              if(tmp[0] === 'image'){
                elements.push('img')
              }

              state.widgets.push({
                ...newOzonWidget(),
                ...{'widgetName': 'list', 'theme': tmp[0], 'blocks': [newWidgetBlock(elements)]}})
            break
          }
        }

        if(tmp[1] === 'showcase'){
           switch(tmp[0]){
              case 'billboard':
              case 'secondary':
              case 'chess':
              case 'tileM':
              case 'tileL':
              case 'tileXL':
              case 'roll':
                let elements = ['img', 'link']

                if(tmp[0] !== 'roll'){
                  elements.push('texts')
                }

                if(tmp[0] === 'chess'){
                  elements.push('reverse')
                }

                state.widgets.push({
                  ...newOzonWidget(),
                  ...{'widgetName': 'raShowcase', 'type': tmp[0], 'blocks': [newWidgetBlock(elements)]}})
              break
          }
        }

        if(tmp[1] === 'video'){
          switch(tmp[0]) {
            case 'youtube':
              state.widgets.push({ ...newOzonWidget(), ...{'widgetName': 'raVideo', 'id': '', 'type': tmp[0]}})
            break
            case 'embeded':
              state.widgets.push({
                ...newOzonWidget(),
                ...{'widgetName': 'raVideo', 'type': tmp[0], width: '', height: '0', sources: []}
              })
            break
          }
        }

      }else{
        switch(name){
          case 'text':
            state.widgets.push({
              ...newOzonWidget(),
              ...{
                'widgetName': 'raTextBlock',
                'theme': 'default',
                'text': newTextObject('size1'),
                'title': newTextObject('size3'),
                'gapSize': 'm',
                'padding': 'type1',
              }})
          break
          case 'table':
            const tmp = {
              'widgetName': 'raTable',
              'title': newTextObject(),
              'table': {
                'head': [{
                  'text': ['Nagłówek'],
                  'contentAlign': 'left',
                  'img': {
                    'alt': '',
                    'src': '',
                    'srcMobile': '',
                  }
                }],
                'body': [
                  [
                    ['Treść']
                  ]
                ],
              }
            }
            state.widgets.push({ ...newOzonWidget(), ...tmp})
          break
        }
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAvailableContent = action.payload
    },
    setAssetsFromEc: (state, action: PayloadAction<Array<AssetFetchedFromEcComponent>>) => {
      state.assetsFromEc = action.payload
    },
    setUploadedAssets: (state, action: PayloadAction<Array<iContentPublicationUploadedAsset>>) => {
      state.uploadedAssets = action.payload
    },
    setTextsFromEc: (state, action: PayloadAction<Array<TextFetchedFromEcComponent>>) => {
      state.textsFromEc = action.payload
    },
    setDetails: (state, action: PayloadAction<ContentPublication>) => {
      state.widgets = action.payload.data.ozon_widgets
      state.status = action.payload.status
      state.cxh_product_uuid = action.payload.cxh_product_uuid
      state.cxh_ec_component_uuid = action.payload.cxh_ec_component_uuid
    },
    setWidgetTheme: (state, action: PayloadAction<{ idx: number, theme: string }>) => {
      state.widgets[action.payload.idx].theme = action.payload.theme
    },
    removeWidget: (state, action: PayloadAction<number>) => {
      state.widgets.splice(action.payload, 1)
    },
    addTableRow: (state, action: PayloadAction<number>) => {
      let newRow = []

      for(let i = 0; i < state.widgets[action.payload].table!.body[0].length; i++){
        newRow.push([''])
      }
      state.widgets[action.payload].table!.body.push(newRow)
    },
    addTableColumn: (state, action: PayloadAction<number>) => {
      state.widgets[action.payload].table!.head.push({
        'text': [''],
        'contentAlign': 'left',
        'img': {
          'src': '',
          'srcMobile': '',
          'alt': '',
        }
      })
      for(let i = 0; i < state.widgets[action.payload].table!.body.length; i++){
        state.widgets[action.payload].table!.body[i].push([''])
      }
    },
    updateTableHeadCellText: (state, action: PayloadAction<{ widgetIdx: number, newText: string, columnIdx: number}>) => {
      state.widgets[action.payload.widgetIdx].table!.head[action.payload.columnIdx].text[0] = action.payload.newText
    },
    updateTableHeadCellImage: (state, action: PayloadAction<{ widgetIdx: number, columnIdx: number, imageSrc: string}>) => {
      state.widgets[action.payload.widgetIdx].table!.head[action.payload.columnIdx].img.src = action.payload.imageSrc
      state.widgets[action.payload.widgetIdx].table!.head[action.payload.columnIdx].img.srcMobile = action.payload.imageSrc
    },
    updateTableBodyCellText: (state, action: PayloadAction<{ widgetIdx: number, newText: string, cellIdx: number, rowIdx: number}>) => {
      state.widgets[action.payload.widgetIdx].table!.body[action.payload.rowIdx][action.payload.cellIdx] = [action.payload.newText]
    },
    removeTableRow: (state, action: PayloadAction<{ widgetIdx: number, rowIdx: number}>) => {
      state.widgets[action.payload.widgetIdx].table!.body.splice(action.payload.rowIdx, 1)
    },
    removeTableColumn: (state, action: PayloadAction<{ widgetIdx: number, columnIdx: number}>) => {
      state.widgets[action.payload.widgetIdx].table!.head.splice(action.payload.columnIdx, 1)
      for(let i = 0; i < state.widgets[action.payload.widgetIdx].table!.body.length; i++){
        state.widgets[action.payload.widgetIdx].table!.body[i].splice(action.payload.columnIdx, 1)
      }
    },
    addBlock: (state, action: PayloadAction<number>) => {
      let widget = state.widgets[action.payload]
      let elements: Array<string> = []

      switch(widget.widgetName){
        case 'list':
          elements = ['texts']
          if(widget.theme! === 'image'){
            elements.push('img')
          }
          break
        case 'raShowcase':
          elements = ['img', 'link']
          if(widget.type! !== 'roll'){
            elements.push('texts')
          }
          if(widget.type === 'chess'){
            elements.push('reverse')
          }
          break
      }

      let newBlock = newWidgetBlock(elements)

      state.widgets[action.payload].blocks!.push(newBlock)

      if(widget.type === 'chess'){
        for(let i=0; i < state.widgets[action.payload].blocks!.length; i++){
          state.widgets[action.payload].blocks![i].reverse = i % 2 === 0
        }
      }
    },
    updateBlockProperty: (state, action: PayloadAction<{widgetIdx: number, blockIdx: number, prop: string, value: string}>) => {
      switch (action.payload.prop) {
        case 'imgLink':
          state.widgets[action.payload.widgetIdx].blocks![action.payload.blockIdx].imgLink! = action.payload.value
        break
      }
    },
    removeBlock: (state, action: PayloadAction<{widgetIdx: number, blockIdx: number}>) => {
      state.widgets[action.payload.widgetIdx].blocks!.splice(action.payload.blockIdx, 1)

      if(state.widgets[action.payload.widgetIdx].type === 'chess'){
        for(let i=0; i < state.widgets[action.payload.widgetIdx].blocks!.length; i++){
          state.widgets[action.payload.widgetIdx].blocks![i].reverse = i % 2 === 0
        }
      }
    },
    setTextObjectContent: (
      state,
      action: PayloadAction<{widgetIdx: number, prop: string, content?: string, blockIdx?: number, contentArray?: Array<string>}>
    ) => {
      if(action.payload.blockIdx !== undefined){
        if(action.payload.prop === 'title'){
          state.widgets[action.payload.widgetIdx].blocks![action.payload.blockIdx].title!.content[0] = action.payload.content!
        }
        if(action.payload.prop === 'text'){
          if(action.payload.contentArray){
            state.widgets[action.payload.widgetIdx].blocks![action.payload.blockIdx].text!.content = action.payload.contentArray!
          }else{
            state.widgets[action.payload.widgetIdx].blocks![action.payload.blockIdx].text!.content = action.payload.content!.split("\n")
          }
        }
      }else{
        if(action.payload.prop === 'title'){
          state.widgets[action.payload.widgetIdx].title!.content[0] = action.payload.content!
        }
        if(action.payload.prop === 'text'){
          if(action.payload.contentArray){
            state.widgets[action.payload.widgetIdx].text!.content = action.payload.contentArray!
          }else{
            state.widgets[action.payload.widgetIdx].text!.content = action.payload.content!.split("\n")
          }

        }
      }
    },
    setTextObjectAlign: (state, action: PayloadAction<{widgetIdx: number, prop: string, align: string, blockIdx?: number }>) => {
      let prop = null, item = null

      if(action.payload.blockIdx !== undefined){
        item = state.widgets[action.payload.widgetIdx].blocks![action.payload.blockIdx]
      }else {
        item = state.widgets[action.payload.widgetIdx]
      }

      if(action.payload.prop === 'title'){
        prop = item!.title!
      }
      if(action.payload.prop === 'text'){
        prop = item!.text!
      }

      prop!.align = action.payload.align
    },
    setTextObjectColor: (state, action: PayloadAction<{widgetIdx: number, prop: string, color: string, blockIdx?: number}>) => {
      let item = null, prop = null

      if(action.payload.blockIdx !== undefined){
        item = state.widgets[action.payload.widgetIdx].blocks![action.payload.blockIdx]
      }else {
        item = state.widgets[action.payload.widgetIdx]
      }

      if(action.payload.prop === 'title'){
        prop = item!.title!
      }
      if(action.payload.prop === 'text'){
        prop = item!.text!
      }

      prop!.color = action.payload.color
    },
    setTextObjectSize: (state, action: PayloadAction<{widgetIdx: number, prop: string, size: string, blockIdx?: number}>) => {
      let item = null, prop = null

      if(action.payload.blockIdx !== undefined){
        item = state.widgets[action.payload.widgetIdx].blocks![action.payload.blockIdx]
      }else {
        item = state.widgets[action.payload.widgetIdx]
      }

      if(action.payload.prop === 'title'){
        prop = item!.title!
      }
      if(action.payload.prop === 'text'){
        prop = item!.text!
      }

      prop!.size = action.payload.size
    },
    updateWidgetAttribute: (state, action: PayloadAction<{ widgetIdx: number, attribute: string, value: string}>) => {
      let widget = state.widgets[action.payload.widgetIdx]

      switch(action.payload.attribute){
        case 'theme':
          widget.theme = action.payload.value
        break
        case 'gapSize':
          widget.gapSize = action.payload.value
        break
        case 'padding':
          widget.padding = action.payload.value
        break
        case 'width':
          widget.width = action.payload.value
        break
        case 'height':
          widget.height = action.payload.value
        break
        case 'id':
          widget.id = action.payload.value
        break
      }
    },
    addVideoSource: (state, action: PayloadAction<{ widgetIdx: number }>) => {
      state.widgets[action.payload.widgetIdx].sources!.push(newVideoSource())
    },
    removeVideoSource: (state, action: PayloadAction<{ widgetIdx: number, sourceIdx: number }>) => {
      state.widgets[action.payload.widgetIdx].sources!.splice(action.payload.sourceIdx, 1)
    },
    updateVideoSourceProperty: (state, action: PayloadAction<{ widgetIdx: number, sourceIdx: number, prop: string, value: string }>) => {
      let source = state.widgets[action.payload.widgetIdx].sources![action.payload.sourceIdx]

      switch(action.payload.prop){
        case 'type':
          source.type = action.payload.value
        break
        case 'src':
          source.src = action.payload.value
        break
      }
    },
    updateBlockImageProperty: (state, action: PayloadAction<{ widgetIdx: number, blockIdx: number, prop: string, value: string }>) => {
      let img = state.widgets[action.payload.widgetIdx].blocks![action.payload.blockIdx].img!
      let val = action.payload.value

      switch(action.payload.prop){
        case 'src':
          img.src = val
          break
        case 'srcMobile':
          img.srcMobile = val
          break
        case 'width':
          img.width = val
          break
        case 'height':
          img.height = val
          break
        case 'containWidth':
          img.containWidth = val
          break
        case 'containHeight':
          img.containHeight = val
          break
        case 'widthMobile':
          img.widthMobile = val
          break
        case 'heightMobile':
          img.heightMobile = val
          break
        case 'containWidthMobile':
          img.containWidthMobile = val
          break
        case 'containHeightMobile':
          img.containHeightMobile = val
          break
        case 'alt':
          img.alt = val
          break
      }
    }
  },
});

export const { actions, reducer } = ozonPublicationSlice

