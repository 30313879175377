export interface Vendor {
  id: string;
  name: string;
}

export interface Locale {
  code: string;
  name: string;
}

interface PerLocaleAttributes {
  [key: string]: string;
}

export interface Product {
  id: string;
  name: string;
  created_at: string;
  data_owner_id: string;
  from_webcollage: boolean;
  target_part_id: string;
  attributes: {[key: string]: PerLocaleAttributes};
}

interface EcComponentExperiences{
  power_page: {
    widget_ids: Array<string>
  }
}

export interface EcComponent{
  id: string;
  language: string;
  target_party_id: string;
  updated_at: string;
  power_page_widget_uuids: Array<string>
  experiences: EcComponentExperiences;
  parent_product_uuid?: string
}

interface AssetInfo{
  id: string
  name: string
  format: string
  size: number
  width: number
  height: number
}

export interface WidgetAssetsInfo{
  images_total_size: number
  videos_total_size: number
  images: AssetInfo[]
  videos: AssetInfo[]
}

export interface EcComponentWidgetInfo{
  id: string;
  type: string;
  caption: string;
  assets: WidgetAssetsInfo;
}

export interface EcComponentWithWidgetsInfo extends EcComponent{
  widgets: EcComponentWidgetInfo[];
}

export interface TextFetchedFromEcComponent{
  kind: string;
  content: string;
}

export interface AssetFetchedFromEcComponent{
  id: string;
  format: string;
  width: number;
  height: number;
  name: string;
  size: number;
  url: string;
}

export interface Receipient{
  id: string;
  name: string;
}


export interface DistributionTemplateSectionAttribute{
  id: string
  export_alias: string
  locale: string
  order: number
  value_override: string
}

export interface DistributionTemplateSection{
  name: string
  order: number
  table_style: boolean
  attributes: DistributionTemplateSectionAttribute[]
}

export interface DistributionTemplate{
  id: string
  name: string
  format: string
  sections: DistributionTemplateSection[]
}

export class ProductDataExportDataSchemes{
  static PRODUCTS_AS_ROWS = 'products_as_rows'
  static ATTRIBUTES_AS_ROWS = 'attributes_as_rows'
  static ATTRIBUTE_PER_LINE = 'attribute_per_line'
}

export interface ProductDataExport{
  id: string
  url: string
  export_status_message: string
  status: number
  receipient_name: string
  distribution_template_name: string
  created: string
  deliver_to_ftp: boolean
}

export class ProductDataExportStatus{
  static INITIALIZED = 1
  static BUILD_STARTED = 2
  static BUILT = 3
  static BUILD_FAILED = 4
  static DELIVERED = 5
  static DELIVERY_FAILED = 6

  static NAMES = {
    [ProductDataExportStatus.INITIALIZED]: 'Initialized',
    [ProductDataExportStatus.BUILD_STARTED]: 'Build started',
    [ProductDataExportStatus.BUILT]: 'Built',
    [ProductDataExportStatus.BUILD_FAILED]: 'Build failed',
    [ProductDataExportStatus.DELIVERED]: 'Delivered',
    [ProductDataExportStatus.DELIVERY_FAILED]: 'Delivery failed',
  }
}