import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {EcComponent, Product} from '../../types/core'


export interface ProductEcModalState {
  isOpen: boolean;
  productId: string;
  product: Product | null,
  components: Array<EcComponent>;
  isLoading: boolean;
}

const initialState: ProductEcModalState = {
  isOpen: false,
  productId: '',
  product: null,
  components: [],
  isLoading: false,
}

export const productEcModalSlice = createSlice({
  name: 'productEcModal',
  initialState,
  reducers: {
   setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    close: (state) => {
      state.isOpen = false
    },
    open: (state, action: PayloadAction<string>) => {
      state.isOpen = true
      state.productId = action.payload
    },
    setComponents: (state, action: PayloadAction<Array<EcComponent>>) => {
      state.components = action.payload
    },
    setProduct: (state, action: PayloadAction<Product>) => {
      state.product = action.payload
    }
  },
});

export const { actions, reducer } = productEcModalSlice
