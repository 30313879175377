import React from 'react'
import {Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { actions } from './slice'
import {useAppDispatch, useAppSelector} from '../../hooks'
import OzonListWidgetEditor from './OzonListWidgetEditor'
import OzonTextBlockEditor from './OzonTextBlockEditor'
import OzonVideoWidgetEditor from './OzonVideoWidgetEditor'
import OzonShowcaseWidgetEditor from './OzonShowcaseWidgetEditor'
import OzonTableWidgetEditor from './OzonTableWidgetEditor'

function OzonWidgetsBuilder({ publicationId }: { publicationId: string }){
  const dispatch = useAppDispatch()
  let state = useAppSelector(state => state.ozonPublication)

   const menu = (
    <Menu onClick={({ key }: { key: string }) => dispatch(actions.addWidget(key)) }>
      <Menu.ItemGroup title={'List'}>
        <Menu.Item key={'bullet-list'}>
          Bullet
        </Menu.Item>
        <Menu.Item key={'number-list'}>
          Number
        </Menu.Item>
        <Menu.Item key={'image-list'}>
          Small images
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Item key={'text'}>
        Text
      </Menu.Item>
      <Menu.ItemGroup title={'Showcase'}>
        <Menu.Item key={'roll-showcase'}>
          Roll
        </Menu.Item>
        <Menu.Item key={'billboard-showcase'}>
          Billboard
        </Menu.Item>
        <Menu.Item key={'chess-showcase'}>
          Chess
        </Menu.Item>
        <Menu.Item key={'tileM-showcase'}>
          Tile-M
        </Menu.Item>
        <Menu.Item key={'tileL-showcase'}>
          Tile-L
        </Menu.Item>
        <Menu.Item key={'tileXL-showcase'}>
          Tile-XL
        </Menu.Item>
        <Menu.Item key={'secondary-showcase'}>
          Secondary
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title={'Video'}>
        <Menu.Item key={'youtube-video'}>
          Youtube
        </Menu.Item>
        <Menu.Item key={'embeded-video'} disabled={ true }>
          Native
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Item key={'table'}>
        Table
      </Menu.Item>
    </Menu>
  );

  let widgetsIdxMax = state.widgets ? state.widgets.length - 1 : 0

  return <div style={{ width: '100%', height: '100%' }}>
    <div style={{ width: '90%', height: 45, borderBottom: '1px solid #ddd', marginBottom: 30 }}>
      <span style={{ float: 'left', fontWeight: 'bold', fontSize: 18, marginTop: 6, marginLeft: 15 }}>Widgets</span>
      <div style={{ float: 'right', marginRight: 15, marginTop: 10 }}>
        <Dropdown overlay={menu}>
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            Add widget <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </div>
    <div style={{ width: '90%', height: 'calc(100% - 75px)', overflow: 'auto' }}>
      {
        state.widgets && state.widgets.map((widget, idx) => {
          if(!widget) return null

          let isLast = idx === widgetsIdxMax

          switch(widget.widgetName){
            case 'list':
              return <OzonListWidgetEditor widget={ widget } key={ idx } idx={ idx } isLast={ isLast }/>
            case 'raTextBlock':
              return <OzonTextBlockEditor widget={ widget } key={ idx } idx={ idx } isLast={ isLast }/>
            case 'raVideo':
              return <OzonVideoWidgetEditor widget={ widget } key={ idx } idx={ idx } isLast={ isLast }/>
            case 'raShowcase':
              return <OzonShowcaseWidgetEditor widget={ widget } key={ idx } idx={ idx } isLast={ isLast }/>
            case 'raTable':
              return <OzonTableWidgetEditor widget={ widget } key={ idx } idx={ idx } isLast={ isLast }/>
            default:
              return <div>Some other type</div>
          }
        })
      }
    </div>
  </div>
}

export default OzonWidgetsBuilder