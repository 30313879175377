import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { HtmlExportConfig } from '../../types/html_exports'

export interface iExportConfigsListDataState {
  total: number;
  configs: Array<HtmlExportConfig>;
}

export interface iExportPresetsListState extends iExportConfigsListDataState{
  isLoading: boolean;
}

const initialState: iExportPresetsListState = {
  total: 0,
  configs: [],
  isLoading: false,
}

export const exportConfigsListSlice = createSlice({
  name: 'exportConfigsList',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setConfigs: (state, action: PayloadAction<iExportConfigsListDataState>) => {
      state.configs = action.payload.configs
      state.total = action.payload.total
      state.isLoading = false
    }
  },
});

export const { actions, reducer } = exportConfigsListSlice

