import apiAccess from './apiAccess'


class LocalesAPI {
  static fetchList(next: Function){
    apiAccess.url(`/locales`)
      .get()
      .json(response => { next(response['locales']) })
  }
}

export default LocalesAPI