import React from 'react'
import {actions} from './slice'
import OzonWidgetEditor from './OzonWidgetEditor'
import {Button} from 'antd'
import {useAppDispatch} from '../../hooks'
import OzonWidgetBlockEditor from './OzonWidgetBlockEditor'
import { iOzonWidget} from '../../types/ozon'

export default function OzonShowcaseWidgetEditor({
                                                   widget,
                                                   idx,
                                                   isLast
                                                 }: { widget: iOzonWidget, idx: number, isLast: boolean }) {
  const dispatch = useAppDispatch()

  return <OzonWidgetEditor widget={widget} idx={idx} title={'Showcase - ' + widget.type} isLast={isLast}>
    {
      widget.blocks!.map((block, blockIdx) => {
        return <OzonWidgetBlockEditor blockIdx={blockIdx} idx={idx} block={block} key={blockIdx} />
      })
    }
    <Button type={'link'} onClick={e => dispatch(actions.addBlock(idx))}>Add block</Button>
  </OzonWidgetEditor>
}