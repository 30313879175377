import React, {useEffect} from 'react'
import {useAppDispatch, useAppSelector} from '../../hooks'
import AppMenu from '../App/Menu'
import {Button, notification, PageHeader, Table} from 'antd'
import HtmlExportsAPI from '../../api/HtmlExportsAPI'
import {
  HtmlExport,
  HtmlExportConfig,
  HtmlExportConfigOutputter,
  HtmlExportPreset,
  HtmlExportStatus
} from '../../types/html_exports'
import {actions} from './slice'
import {DeleteOutlined} from '@ant-design/icons'
import {cxhProductUrl} from '../../lib/urls'

const PAGE_SIZE = 100

export default function HtmlExportsList(){
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.htmlExportsList)

  useEffect(() => {
    HtmlExportsAPI.fetchList(({ exports, total }: { exports: HtmlExport[], total: number}) => {
      dispatch(actions.setData({ exports, total }))
    })
  }, [dispatch])

  function deleteExport(exportId: number){
    if(window.confirm("Are you sure?")){
      HtmlExportsAPI.delete(exportId, (deleted: boolean) => {
        if(deleted){
          dispatch(actions.removeFromList(exportId))
        }else{
          notification.error({
            message: 'Error occured during removal'
          })
        }
      })
    }
  }

  return <>
    <AppMenu selectedKey={'html-exports'}/>
    <PageHeader title={'HTML exports'} backIcon={ false } />
    <div style={{ padding: '0 20px' }}>
      <Table
        bordered={true}
        rowKey={'id'}
        size={'small'}
        dataSource={state.exports}
        loading={state.isLoading}
        pagination={{
          total: state.total,
          showSizeChanger: false,
          pageSize: PAGE_SIZE,
          position: ['topLeft', 'bottomRight'],
          onChange: (page: number) => {},
          current: state.page,
        }}
      >
        <Table.Column title={'ID'} key={'id'} dataIndex={'id'} />
        <Table.Column
          title={'Product & comment'}
          key={'name'}
          dataIndex={'cxh_product_name'}
          render={(name: string, html_export: HtmlExport) => {
            return <>
              <a
                href={ cxhProductUrl(html_export.cxh_product_uuid) }
                target={'_blank'}
                rel={'noreferrer'}
              >{ html_export.cxh_product_name }</a>
              { html_export.comment && html_export.comment.length > 0 &&
                <>
                  <br/>
                  <i>{ html_export.comment }</i>
                </>
              }
            </>
          }}
        />
        <Table.Column title={'User'} key={'user'} dataIndex={'user'} render={(text, exp: HtmlExport) => exp.user.username }/>
        <Table.Column title={'Config'} key={'config'} dataIndex={'config'}
                      render={(cfg: HtmlExportConfig) => cfg ? cfg.name : '' }/>


        <Table.Column
          title={'Preset'}
          key={'export_preset'}
          dataIndex={'export_preset'}
          render={(preset: HtmlExportPreset) => preset ? preset.name : 'Default'}
        />
        <Table.Column title={'Status'} key={'status'} dataIndex={'status'} render={(sts: number) => HtmlExportStatus.NAMES[sts]}/>
        <Table.Column title={''} key={'actions'} dataIndex={'actions'} render={(sts: number, exp: HtmlExport) => {
          return <>
            {
              exp.status === HtmlExportStatus.READY &&
                <Button
                  size={'small'}
                  style={{ marginRight: 10 }}
                  onClick={() => window.location.href = exp.download_url }
                >Download</Button>
            }
            <Button
              danger={true}
              type={'primary'}
              icon={<DeleteOutlined/>}
              onClick={e => deleteExport(exp.id) }
              size={'small'}
            ></Button>
          </>
        }}/>
      </Table>
    </div>
  </>
}
