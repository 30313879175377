import apiAccess from './apiAccess'
import { HtmlExportConfigPayload } from '../types/html_exports'


export default class HtmlExportConfigsAPI {
  static fetchOutputters(next: Function){
    apiAccess.url(`/export-configs-outputters`)
      .get()
      .json(response => { next(response) })
  }

  static delete(configId: number, next: Function){
    apiAccess.url(`/export-configs/${configId}`)
      .delete()
      .json(response => { next(response) })
  }

  static fetchDetails(configId: number, next: Function){
    apiAccess.url(`/export-configs/${configId}`)
      .get()
      .json(response => { next(response) })
  }

  static fetchList(next: Function){
    apiAccess.url(`/export-configs`)
      .get()
      .json(response => { next(response['configs'], response['total']) })
  }

  static update(configId: number, payload: HtmlExportConfigPayload, next: Function){
    apiAccess.url(`/export-configs/${configId}`)
      .patch(payload)
      .json(response => { next(response) })
  }

  static create(payload: HtmlExportConfigPayload, next: Function){
    apiAccess.url(`/export-configs`)
      .post( payload )
      .json(response => { next(response['id']) })
  }
}
