import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ContentBundleCreatorModalState {
  isOpen: boolean
  productId: string
  componentId: string
  format: number
}

const initialState: ContentBundleCreatorModalState = {
  isOpen: false,
  productId: '',
  componentId: '',
  format: 0,
}

export const contentBundleCreatorModalSlice = createSlice({
  name: 'contentBundleCreatorModal',
  initialState,
  reducers: {
    close: (state) => {
      state.isOpen = false
    },
    open: (state, action: PayloadAction<{ productId: string, componentId: string, format: number }>) => {
      state.isOpen = true
      state.productId = action.payload.productId
      state.componentId = action.payload.componentId
      state.format = action.payload.format
    },
  },
});

export const { actions, reducer } = contentBundleCreatorModalSlice
