import React, {useEffect} from 'react'
import ProductsAPI from '../../../api/ProductsAPI'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import { actions } from './slice'
import {Button, Card, Checkbox, Col, Row, Spin, Switch, Alert} from 'antd'
import {formatFileSize, stripHTML} from '../../../lib'
import {AssetFetchedFromEcComponent, TextFetchedFromEcComponent} from '../../../types/core'

export default function EcComponentContentSelectionForm(
  { productId, componentId, onCreate, closeModal, textsEnabled, assetsEnabled }
    :
  { productId: string, componentId: string, onCreate: Function, closeModal: Function, assetsEnabled: boolean, textsEnabled: boolean }
){
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.ecComponentContentSelectionForm)
  const LENGTH_THREASHOLD = 40

  useEffect(() => {
    if(productId.length > 0 && componentId.length > 0){
      dispatch(actions.markAsIsLoading())

      ProductsAPI.fetchProductEcComponentAssetsAndTexts(
        productId, componentId,
        (assets: Array<AssetFetchedFromEcComponent>, texts: Array<TextFetchedFromEcComponent>) => {
          dispatch(actions.setAvailableContent({ assets, texts }))
        }
      )
    }
  }, [productId, componentId, dispatch])

  function createBundle() {
    onCreate(state.selectedTexts, state.selectedAssets)
  }

  let texts = state.texts.map((txt: TextFetchedFromEcComponent) => {
    return { 'content': stripHTML(txt.content)}
  })

  if(state.onlyLonger){
    texts = texts.filter(txt => txt.content.length > LENGTH_THREASHOLD)
  }

  if(state.isLoading){
    return <div style={{ width: '100%', height: 300, textAlign: 'center', paddingTop: 100 }}>
       <Spin size={'large'} style={{ fontSize: 18 }} tip={'Loading ...'}/>
    </div>
  }

  return <div>
    {
      textsEnabled &&
        <>
        <div style={{ height: 35, width: '100%' }}>
          <h3 style={{
            display: 'inline-block' }}
          >Select texts for export</h3>
          <div style={{ display: 'inline-block', marginLeft: 15 }}>
            <Switch size={'small'} checked={ state.onlyLonger } onChange={() => dispatch(actions.toggleOnlyLonger()) } />
            <span style={{ display: 'inline-block', marginTop: 6, marginLeft: 4, fontSize: 12 }}>
              Only longer than <strong>{LENGTH_THREASHOLD}</strong> characters
            </span>
          </div>
          <div style={{ display: 'inline-block', float: 'right' }}>
            <Switch
              size={'small'}
              checked={ state.texts.length === state.selectedAssets.length }
              onChange={() => dispatch(actions.toggleAllTexts()) }
            />
            <span style={{ display: 'inline-block', marginTop: 6, marginLeft: 4, fontSize: 12 }}>
              Select all texts
            </span>
          </div>
        </div>
        {
          texts.length === 0 ?
            <Alert type={'warning'} message={'No texts found'} showIcon={true}/>
            :
          texts.map(text => {
            // const isChecked = state.selectedTexts.includes(text.content)

            const isChecked = state.selectedTexts.indexOf(text.content) !== -1

            return <Card
              size={'small'}
              style={{
                backgroundColor: isChecked ? '#eee' : '#fff', fontSize: 12,
                border: '1px solid #ccc', marginBottom: 5
              }}>
              <Checkbox
                checked={ isChecked }
                onChange={e => dispatch(actions.toggleSelectedText({ text: text.content }))}
              >
                { text.content }
              </Checkbox>
            </Card>
          })
        }
      </>
    }
    {
      assetsEnabled &&
      <>
        <div style={{ height: 35, width: '100%' }}>
          <h3 style={{
            display: 'inline-block' }}
          >Available assets:</h3>
          <div style={{ display: 'inline-block', float: 'right' }}>
            <Switch
              size={'small'}
              checked={ state.assets.length === state.selectedAssets.length }
              onChange={() => dispatch(actions.toggleAllAssets()) }
              style={{ marginRight: 10 }}
            />
              Select all
          </div>
        </div>

        <Row gutter={32}>
        {
          state.assets.length === 0 ?
            <Alert type={'warning'} message={'No assets found'} showIcon={ true }/>
            :
          state.assets.map(asset => {
            const isAnImage = ['jpeg', 'gif', 'png'].includes(asset.format)
            const isChecked = state.selectedAssets.includes(asset.id)

            let prevStyle: { [key: string]: string } = { width: '100%', height: '150px' }

            if(isAnImage){
              prevStyle['backgroundImage'] = `url(https://assets.syndigo.com/${asset.id}?size=500&thumbnail=true)`
              prevStyle['backgroundSize'] = 'contain'
              prevStyle['backgroundPosition'] = 'center center'
              prevStyle['backgroundRepeat'] = 'no-repeat'
            }

            return <Col span={12} key={asset.id}>
              <Card
                size={'small'}
                style={{
                  border: '1px solid #ccc',
                  marginBottom: 5,
                  backgroundColor: isChecked ? '#eee' : '#fff',
                }}
              >
                <div style={ prevStyle } key={'preview'}>
                  {
                    !isAnImage && <p>Preview not available</p>
                  }
                </div>
                <Card.Meta
                  key={'meta'}
                  title = {<Checkbox
                    style={{ width: '100%' }}
                    checked={ isChecked }
                    onChange={e => dispatch(actions.toggleSelectedAsset({ assetId: asset.id }))}
                  >
                    { asset.name }
                  </Checkbox>}
                  description={
                    <>
                    <span>
                      { formatFileSize(asset.size) }
                    </span>
                    {
                      isAnImage && <small>{ ' - ' + asset.width + ' x ' + asset.height }</small>
                    }
                    </>
                  }
                />
              </Card>
            </Col>
          })
        }
        </Row>
      </>
    }
    <div style={{ width: '100%', height: 40, textAlign: 'right' }}>
      <Button type={'ghost'} style={{ marginRight: 20 }} onClick={e => closeModal() }>Cancel</Button>
      <Button
        type={'primary'}
        disabled={ state.selectedAssets.length === 0 && state.selectedTexts.length === 0}
        onClick={e => createBundle() }
      >Create</Button>
    </div>
  </div>
}