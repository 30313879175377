import React, { useEffect } from 'react'
import {Input, Modal, notification, Switch, Radio, Select, Button} from 'antd'
import {useAppDispatch, useAppSelector} from '../../hooks'
import {actions, OutputterDetails} from './slice'
import {
  HtmlExportConfig, HtmlExportConfigAssetsMode,
  HtmlExportConfigPayload,
  HtmlExportConfigVariable
} from '../../types/html_exports'
import HtmlExportConfigsAPI from '../../api/HtmlExportConfigsAPI'


export default function HtmlExportConfigModal(){
  let state = useAppSelector(state => state.exportConfigModal)
  let configId = state.configId
  let dispatch = useAppDispatch()

  useEffect(() => {
    if(state.isOpen && state.outputters.length === 0){
      // dispatch(actions.openForEdit(4))
      HtmlExportConfigsAPI.fetchOutputters((resp: any) => {
        dispatch(actions.setOutputters(resp))
      })
    }
  }, [state.isOpen])

  useEffect(() => {
    if(configId){
      HtmlExportConfigsAPI.fetchDetails(configId, (config: HtmlExportConfig) => {
        dispatch(actions.setDetails(config))
      })
    }
  }, [dispatch, configId])

  function onOk(){
    let payload: HtmlExportConfigPayload = state

    if(state.configId){
      HtmlExportConfigsAPI.update(state.configId, payload, () => {
        notification.success({ message: 'Config was updated'})
        dispatch(actions.close())
      })
    }else{
      HtmlExportConfigsAPI.create(payload, () => {
        notification.success({ message: 'Preset was created'})
        dispatch(actions.close())
      })
    }
  }

  function renderInput(label: string, name: string, value: string){
    return <>
      <label>{ label + ': '}</label>
      <Input value={value} onChange={e => dispatch(actions.setString({ name, 'value': e.target.value }))}/>
      <br/><br/>
    </>
  }

  function renderSwitch(label: string, name: string, value: boolean){
    return <>
      <label>{ label + ': ' }</label>
      <Switch checked={ value } onChange={val => dispatch(actions.setBoolean({ name, 'value': val }))}/>
      <br/><br/>
    </>
  }

  function renderFormatToggle(label: string, name: string, value: number){
    return <>
      <label>{ label + ': '}</label>
      <Radio.Group onChange={evt => dispatch(actions.setNumber({ name, value: evt.target.value }))} value={value}>
        <Radio value={0}>File only</Radio>
        <Radio value={1}>Linked</Radio>
        <Radio value={2}>Embed</Radio>
      </Radio.Group>
      <br/><br/>
    </>
  }

  function renderAssetsToggle(label: string, name: string, value: number){
    return <>
      <label>{ label + ': '}</label>
      <Radio.Group onChange={evt => dispatch(actions.setNumber({ name, value: evt.target.value }))} value={value}>
        <Radio value={1}>In file</Radio>
        <Radio value={2}>Served from CXH Preview</Radio>
        <Radio value={3}>Served from PCC</Radio>
      </Radio.Group>
      <br/><br/>
    </>
  }

  function deleteVariable(variableIdx: number){
    if(window.confirm('Are you sure?')){
      dispatch(actions.removeVariable(variableIdx))
    }
  }

  let selectedOutputter = state.outputters.find((o: OutputterDetails) => o.key === state.outputter_id)
  let outputterValue = selectedOutputter ? selectedOutputter.label : ''
  let assetsInFile = state.assets_mode === HtmlExportConfigAssetsMode.IN_FILE

  return <Modal
    visible={ state.isOpen }
    title={'Export config'}
    onOk={ onOk }
    onCancel={() => dispatch(actions.close())}
    width={650}
  >
    { renderInput('Name', 'name', state.name) }
    <label>Outputter: </label>
    <Select
      style={{ width: 200 }}
      value={ outputterValue }
      onChange={v => dispatch(actions.setNumber({ name: 'outputter_id', value: parseInt(v)}))}>
      {
        state.outputters.map((outputter: OutputterDetails) => {
          return <Select.Option value={outputter.key}>
            { outputter.label }
          </Select.Option>
        })
      }
    </Select>
    <br/><br/>
    { renderInput('Main file name', 'main_file_name', state.main_file_name) }
    { renderInput('Main file directory nane', 'main_file_dir_name', state.main_file_dir_name) }
    { renderAssetsToggle('Assets', 'assets_mode', state.assets_mode) }
    { renderSwitch('JS enabled', 'js_enabled', state.js_enabled) }
    { renderSwitch('Video enabled', 'video_enabled', state.video_enabled) }
    { renderSwitch('Source-sets enabled', 'src_sets_enabled', state.src_sets_enabled) }
    { renderSwitch('Is default', 'is_default', state.is_default) }
    { renderSwitch('Inject base HTML', 'inject_base_html_structure', state.inject_base_html_structure) }
    <hr/>
    <p>
      <strong>Variables</strong>
      <Button style={{ marginLeft: 20 }} size={'small'} onClick={() => dispatch(actions.addVariable())}>Add new</Button>
    </p>
    {
      state.variables.length > 0 &&
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <td><strong>Key</strong></td><td><strong>Name</strong></td><td></td>
          </tr>
        </thead>
        {
          state.variables.map((v: HtmlExportConfigVariable) => {
            return <tr key={ v.idx }>
              <td style={{ width: '35%' }}>
                {
                  v.idx === state.editedVariableIdx ?
                    <Input size={'small'} value={v.key} onChange={e => dispatch(actions.setEditedVariableKey({
                    idx: v.idx, value: e.currentTarget.value
                  }))}/>
                  :
                  v.key
                }
              </td>
              <td style={{ width: '35%' }}>
                {
                  v.idx === state.editedVariableIdx ?
                    <Input size={'small'} value={v.name} onChange={e => dispatch(actions.setEditedVariableName({
                    idx: v.idx, value: e.currentTarget.value
                  }))}/>
                  :
                  v.name
                }
              </td>
              <td>
                {
                  v.idx === state.editedVariableIdx ?
                     <Button type={'link'} onClick={() => dispatch(actions.setEditedVariableIdx(-1)) }>OK</Button>
                  :
                    <>
                      <Button type={'link'} onClick={() => dispatch(actions.setEditedVariableIdx(v.idx)) }>Edit</Button>
                      <Button type={'link'} onClick={() => deleteVariable(v.idx) }>Delete</Button>
                    </>
                }
              </td>
            </tr>
          })
        }
      </table>
    }
    <hr/>
    <p><strong>Javascript</strong></p>
    { renderFormatToggle('Scripts format', 'scripts_format', state.scripts_format) }
    { renderSwitch('Minify scripts', 'minify_scripts', state.minify_scripts) }
    { renderInput('Scripts file name', 'scripts_filename', state.scripts_filename) }
    { renderInput('Minified Scripts file name', 'minified_scripts_filename', state.minified_scripts_filename) }
    { renderInput('Scripts directory name', 'scripts_dir_name', state.scripts_dir_name) }
    { renderSwitch('Include both scripts files', 'include_both_scripts_files', state.include_both_scripts_files) }
    <br/>
    <p>Scripts wrapper (use <strong>###CODE###</strong> to embed code)</p>
    <Input.TextArea
      value={ state.scripts_wrapper || ''}
      rows={ 6 }
      onChange={(e) => dispatch(actions.setString({ name: 'scripts_wrapper', value: e.currentTarget.value }))}
    />
    <hr/>
    <p><strong>Styles</strong></p>
    { renderFormatToggle('Styles format', 'styles_format', state.styles_format) }
    { renderSwitch('Minify styles', 'minify_scripts', state.minify_scripts) }
    { renderInput('Styles file name', 'styles_filename', state.styles_filename) }
    { renderInput('Minified styles file name', 'minified_styles_filename', state.minified_styles_filename) }
    { renderInput('Styles directory name', 'styles_dir_name', state.styles_dir_name) }
    { renderSwitch('Include both styles files', 'include_both_styles_files', state.include_both_styles_files) }
    <br/>
    <p>Style addons (will be appended to styles code)</p>
    <Input.TextArea
      value={ state.style_addons || ''}
      rows={ 6 }
      onChange={(e) => dispatch(actions.setString({ name: 'style_addons', value: e.currentTarget.value }))}
    />
    <hr/>
    <p><strong>Other</strong></p>
    { renderInput('Root indicator', 'root_indicator', state.root_indicator) }
    { renderSwitch('Relate contents to root indicator in HTML', 'relate_contents_to_root_indicator', state.relate_contents_to_root_indicator) }
    { renderInput('Content directory name', 'content_dir_name', state.content_dir_name || '') }
    { renderInput('Images directory name', 'images_dir_name', state.images_dir_name) }
    { renderInput('Videos directory name', 'videos_dir_name', state.videos_dir_name) }
    { renderInput('Documents directory name', 'documents_dir_name', state.documents_dir_name) }
    { renderInput('Assets suffix', 'asset_suffix', state.asset_suffix || '') }
  </Modal>
}