import React from 'react'
import {Tag} from 'antd'
import {ContentPublicationStatusesNames, ContentPublicationStatuses} from '../types/content_publications'

const STATUS_TAG_COLORS : { [key: number]: string } = {
  [ContentPublicationStatuses.DRAFT]: '#2db7f5',
  [ContentPublicationStatuses.PUBLISHED]: '#87d068',
  [ContentPublicationStatuses.CEASED]: '#f50'
}

export function ContentPublicationStatusTag({ status } : { status: number }){
  return <Tag color={ STATUS_TAG_COLORS[status] }>{ ContentPublicationStatusesNames[status] }</Tag>
}