import React from 'react'
import { CSSProperties, FC, memo } from 'react'
import { ConnectDropTarget, DropTargetMonitor } from 'react-dnd'
import { DropTarget } from 'react-dnd'

const style: CSSProperties = {
  display: 'inline-block',
  width: '100%',
  // marginRight: '1.5rem',
  // marginBottom: '1.5rem',
  // color: '#000',
  // padding: '1rem',
  // textAlign: 'center',
  // fontSize: '1rem',
  // lineHeight: 'normal',
  // float: 'left',
}

export interface DustbinProps {
  accepts: string[]
  onDrop: (item: any) => void
  // Collected Props
  canDrop: boolean
  isOver: boolean
  connectDropTarget: ConnectDropTarget
  children: React.ReactNode
}

export interface DroppedText {
  type: string
  text: string
}

export const Dustbin: FC<DustbinProps> = memo(function Dustbin({
  accepts,
  isOver,
  canDrop,
  connectDropTarget,
  children
}) {
  const isActive = isOver && canDrop

  let backgroundColor = '#eee'
  if (isActive) {
    backgroundColor = 'darkgreen'
  } else if (canDrop) {
    backgroundColor = 'darkkhaki'
  }

  return connectDropTarget(
    <div
      ref={connectDropTarget}
      style={{ ...style, backgroundColor }}
    >
      { children }
    </div>,
  )
})

export default DropTarget(
  (props: DustbinProps) => props.accepts,
  {
    drop(props: DustbinProps, monitor: DropTargetMonitor) {
      props.onDrop(monitor.getItem())
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
)(Dustbin)
