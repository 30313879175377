import apiAccess from './apiAccess'

class AuthAPI{
  static login(username: string, password: string, onSuccess: Function, onError: Function){
    apiAccess.url("/auth")
      .catcher(422, (err, request) => { onError(err) })
      .post({ username, password })
      .json(response => {
        onSuccess(
          response['token'],
          response['is_admin'],
          response['vendor'],
          response['groups'],
        )
      })
  }

  static validateToken(token: string, next: Function){
    apiAccess.url("/auth")
      .catcher(422, (err, request) => { next(false) })
      .query({ token })
      .get()
      .json(response => {
        next(response['valid'], response['is_admin'], response['vendor'], response['groups'])
    })
  }

  static logout(){

  }
}

export default AuthAPI