import apiAccess from './apiAccess'

interface iGenerateOptions{
  selectedWidgetUuids: string[],
  exportPreset: number,
  configId: number,
}

export default class HtmlExportsAPI {
  static delete(exportId: number, next: Function){
    apiAccess.url(`/html-exports/${exportId}`)
      .delete()
      .json(resp => {
        next(resp['deleted'])
      })
  }

  static fetchList(next: Function){
    apiAccess.url(`/html-exports`)
      .get()
      .json(resp => {
        next(resp)
      })
  }

  static getDetails(exportId: number, next: Function){
    apiAccess.url(`/html-exports/${exportId}`)
      .get()
      .json(resp => {
        next(resp)
      })
  }

  static initalizeHtmlExport(
    cxh_product_uuid: string, cxh_ec_component_uuid: string,
    config_id: number, variable_values: {[key: string]: string}, selected_widgets: string[], preset_id: number,
    comment: string, next: Function
  ){
    apiAccess.url(`/html-exports`)
      .post({
        cxh_product_uuid, cxh_ec_component_uuid, config_id, variable_values, selected_widgets, preset_id,
        comment
      })
      .json(resp => {
        next({ id: resp['id'], taskId: resp['task_id'] })
      })
  }

  static fetchDefaultScssConfig(next: Function) {
    apiAccess.url(`/export-presets/default-scss-config`)
      .get().text(response => {
      next(response)
    })
  }

  static generate(productUuid: string, componentUuid: string, next: Function, options: iGenerateOptions) {
    let payload = {
      product_uuid: productUuid,
      component_uuid: componentUuid,
      preset_id: options.exportPreset,
      config_id: options.configId,
      widget_uuids: options.selectedWidgetUuids,
    }

    apiAccess.url('/html-exports/generate-preview-html')
      .post(payload).text(response => {
      next(response)
    })
  }
}
