import {Checkbox, Table} from 'antd'
import {formatDateTime, shortenUuid} from '../../lib'
import {Product} from '../../types/core'
import React from 'react'
import {actions} from '../ProductsList/slice'
import {useAppDispatch} from '../../hooks'

const PAGE_SIZE = 100

export default function ProductsTable(
  {
    products,
    locale,
    isLoading,
    total,
    page,
    onProductSelect,
    onPageChange,
    showPagination = true,
    selected = [],
    selectable = false,
    toggleProductSelection = undefined
  }:
    {
      products: Product[], locale: string, isLoading: boolean, total: number, showPagination?: boolean
      page: number, onProductSelect: Function, onPageChange: Function, selected: string[], selectable: boolean,
      toggleProductSelection?: Function
    }
) {
  const dispatch = useAppDispatch()

  return <Table
    bordered={true}
    rowKey={'id'}
    size={'small'}
    dataSource={products}
    loading={isLoading}
    pagination={showPagination ? {
      total: total,
      showSizeChanger: false,
      pageSize: PAGE_SIZE,
      position: ['topLeft', 'bottomRight'],
      onChange: (page: number) => onPageChange(page),
      current: page,
    } : false}
  >
    {selectable &&
    <Table.Column
      title={
        <Checkbox
          onClick={() => dispatch(actions.toggleSelectAll())}
          checked={ selected.length === products.length }
        />
      } key={'id'} dataIndex={'id'} render={(text, record: Product) => {
      return <Checkbox
        checked={selected.includes(record.id)}
        onClick={() => toggleProductSelection!(record.id)}
      />
    }}
    />
    }
    <Table.Column
      title={'ID'} key={'id'} dataIndex={'id'} render={(text, record) => shortenUuid(text)}
    />
    <Table.Column
      title={'Name'} key={'name'} dataIndex={'name'} render={(text, record: Product) => {
      return <a onClick={() => onProductSelect(record)}>
        {
          record.attributes[locale] ?
            record.attributes[locale].product_name
            :
            <i>No product name</i>
        }
      </a>
    }} />
    <Table.Column
      title={'GTIN'} key={'gtin'} dataIndex={'id'} render={(text, record: Product) => {
      return record.attributes[locale] ?
        record.attributes[locale]['gtin']
        :
        <i>No GTIN</i>
    }} />
    <Table.Column
      title={'Updated'} key={'last_modified_at'} dataIndex={'last_modified_at'} render={(text, record) => {
      return formatDateTime(text)
    }}/>
    <Table.Column
      title={'Created'} key={'created_at'} dataIndex={'created_at'} render={(text, record) => {
      return formatDateTime(text)
    }}
    />
  </Table>
}