import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  HtmlExportConfig,
  HtmlExportConfigPayload,
  HtmlExportConfigVariable,
  HtmlExportConfigOutputter,
} from '../../types/html_exports'

export interface OutputterDetails{
  key: number
  label: string
}

export interface iExportConfigModalState extends HtmlExportConfigPayload{
  isOpen: boolean
  configId: number
  editedVariableIdx: number
  outputters: OutputterDetails[],
}

const initialState: iExportConfigModalState = {
  isOpen: false,
  editedVariableIdx: -1,
  configId: 0,
  outputters: [],
  name: '',
  outputter_id: HtmlExportConfigOutputter.ZIP,
  main_file_name: 'index.html',
  main_file_dir_name: '',
  assets_mode: 1,
  js_enabled: true,
  video_enabled: true,
  src_sets_enabled: true,
  variables: [],

  scripts_format: 1,
  minify_scripts: true,
  scripts_filename: 'pcc.js',
  minified_scripts_filename: 'pcc.min.js',
  scripts_dir_name: '',
  include_both_scripts_files: false,
  scripts_wrapper: '',

  styles_format: 1,
  minify_styles: true,
  styles_filename: 'pcc.css',
  minified_styles_filename: 'pcc.min.css',
  styles_dir_name: '',
  include_both_styles_files: false,
  style_addons: '',

  content_dir_name: undefined,
  root_indicator: './',
  relate_contents_to_root_indicator: false,
  images_dir_name: 'images',
  videos_dir_name: 'videos',
  documents_dir_name: 'documents',
  inject_base_html_structure: true,
  is_default: false,
}

function fixVariablesIdxes(inp: HtmlExportConfigVariable[]) : HtmlExportConfigVariable[]{
  let newIdx = 1
  return inp.map((v: HtmlExportConfigVariable) => { v.idx = newIdx; newIdx++; return v })
}

export const exportPresetModalSlice = createSlice({
  name: 'exportPresetModal',
  initialState,
  reducers: {
    setOutputters: (state, action: PayloadAction<OutputterDetails[]>) => {
      state.outputters = action.payload
    },
    removeVariable: (state, action: PayloadAction<number>) => {
      let newVariables = state.variables.filter((v: HtmlExportConfigVariable) => v.idx !== action.payload)
      state.variables = fixVariablesIdxes(newVariables)
    },
    setEditedVariableKey: (state, action: PayloadAction<{ idx: number, value: string}>) => {
      state.variables[action.payload.idx].key = action.payload.value
    },
    setEditedVariableName: (state, action: PayloadAction<{ idx: number, value: string}>) => {
      state.variables[action.payload.idx].name = action.payload.value
    },
    setEditedVariableIdx: (state, action: PayloadAction<number>) => {
      state.editedVariableIdx = action.payload
    },
    addVariable: (state) => {
      state.editedVariableIdx = state.variables.length
      state.variables.push({
        idx: state.variables.length,
        key: '',
        name: '',
      })
    },
    close: (state) => {
      state.isOpen = false

      state.name = ''
      state.outputter_id = -1
      state.main_file_name = ''
      state.main_file_dir_name = ''
      state.assets_mode = 1
      state.js_enabled = true
      state.video_enabled = true
      state.src_sets_enabled = true
      state.variables = []
      state.scripts_format = 1
      state.minify_scripts = true
      state.scripts_filename = 'pcc.js'
      state.minified_scripts_filename = 'pcc.min.js'
      state.include_both_scripts_files = false
      state.styles_format = 1
      state.minify_styles = true
      state.styles_filename = 'pcc.css'
      state.minified_styles_filename = 'pcc.min.css'
      state.include_both_styles_files = false
      state.content_dir_name = ''
      state.root_indicator = './'
      state.relate_contents_to_root_indicator = false
      state.images_dir_name = 'images'
      state.videos_dir_name = 'videos'
      state.documents_dir_name = 'documents'
      state.asset_suffix = ''
    },
    setDetails: (state, action: PayloadAction<HtmlExportConfig>) => {
      let cfg = action.payload

      state.name = cfg.name
      state.outputter_id = cfg.outputter_id
      state.main_file_name = cfg.main_file_name
      state.main_file_dir_name = cfg.main_file_dir_name
      state.assets_mode = cfg.assets_mode
      state.js_enabled = cfg.js_enabled
      state.video_enabled = cfg.video_enabled
      state.src_sets_enabled = cfg.src_sets_enabled
      state.variables = fixVariablesIdxes(cfg.variables || [])
      state.scripts_format = cfg.scripts_format
      state.minify_scripts = cfg.minify_scripts
      state.scripts_filename = cfg.scripts_filename
      state.scripts_dir_name = cfg.scripts_dir_name
      state.scripts_wrapper = cfg.scripts_wrapper

      state.minified_scripts_filename = cfg.minified_scripts_filename
      state.include_both_scripts_files = cfg.include_both_scripts_files
      state.styles_format = cfg.styles_format
      state.minify_styles = cfg.minify_styles
      state.styles_filename = cfg.styles_filename
      state.styles_dir_name = cfg.styles_dir_name
      state.minified_styles_filename = cfg.minified_styles_filename
      state.include_both_styles_files = cfg.include_both_styles_files
      state.style_addons = cfg.style_addons

      state.content_dir_name = cfg.content_dir_name
      state.root_indicator = cfg.root_indicator
      state.images_dir_name = cfg.images_dir_name
      state.videos_dir_name = cfg.videos_dir_name
      state.documents_dir_name = cfg.documents_dir_name
      state.asset_suffix = cfg.asset_suffix
      state.inject_base_html_structure = cfg.inject_base_html_structure
      state.relate_contents_to_root_indicator = cfg.relate_contents_to_root_indicator
    },
    setString: (state, action: PayloadAction<{ name: string, value: string}>) => {
      switch(action.payload.name){
        case 'style_addons':
          state.style_addons = action.payload.value
          break
        case 'scripts_wrapper':
          state.scripts_wrapper = action.payload.value
          break
        case 'name':
          state.name = action.payload.value
          break
        case 'main_file_name':
          state.main_file_name = action.payload.value
          break
        case 'asset_suffix':
          state.asset_suffix = action.payload.value
          break
        case 'root_indicator':
          state.root_indicator = action.payload.value
          break
        case 'content_dir_name':
          state.content_dir_name = action.payload.value
          break
        case 'scripts_dir_name':
          state.scripts_dir_name = action.payload.value
          break
        case 'styles_dir_name':
          state.styles_dir_name = action.payload.value
          break
        case 'documents_dir_name':
          state.documents_dir_name = action.payload.value
          break
        case 'images_dir_name':
          state.images_dir_name = action.payload.value
          break
        case 'videos_dir_name':
          state.videos_dir_name = action.payload.value
          break
        case 'main_file_dir_name':
          state.main_file_dir_name = action.payload.value
          break
      }
    },
    setBoolean: (state, action: PayloadAction<{ name: string, value: boolean}>) => {
      switch(action.payload.name){
        case 'is_default':
          state.is_default = action.payload.value
          break
        case 'inject_base_html_structure':
          state.inject_base_html_structure = action.payload.value
          break
        case 'js_enabled':
          state.js_enabled = action.payload.value
          break
        case 'video_enabled':
          state.video_enabled = action.payload.value
          break
        case 'src_sets_enabled':
          state.src_sets_enabled = action.payload.value
          break
        case 'minify_scripts':
          state.minify_scripts = action.payload.value
          break
        case 'include_both_scripts_files':
          state.include_both_scripts_files = action.payload.value
          break
        case 'include_both_styles_files':
          state.include_both_styles_files = action.payload.value
          break
        case 'relate_contents_to_root_indicator':
          state.relate_contents_to_root_indicator = action.payload.value
          break
      }
    },
    setNumber: (state, action: PayloadAction<{ name: string, value: number}>) => {
      switch(action.payload.name){
        case 'assets_mode':
          state.assets_mode = action.payload.value
          break
        case 'outputter_id':
          state.outputter_id = action.payload.value
          break
        case 'scripts_format':
          state.scripts_format = action.payload.value
          break
        case 'styles_format':
          state.styles_format = action.payload.value
          break
      }
    },
    open: (state) => {
      state.isOpen = true
    },
    openForEdit: (state, action: PayloadAction<number>) => {
      state.isOpen = true
      state.configId = action.payload
    }
  },
});

export const { actions, reducer } = exportPresetModalSlice
