import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface UserState {
  wasTokenValidated: boolean
  isTokenValid: boolean
  token: string
  locale: string
  isAdmin: boolean
  groups: string[]
}

const initialState: UserState = {
  wasTokenValidated: false,
  isTokenValid: false,
  locale: 'en-US',
  token: '',
  isAdmin: false,
  groups: [],
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setDetails: (state, action: PayloadAction<UserState>) => {
      const pl = action.payload

      state.wasTokenValidated = pl.wasTokenValidated
      state.isTokenValid = pl.isTokenValid
      state.locale = pl.locale
      state.isAdmin = pl.isAdmin
      state.groups = pl.groups
    },
    setTokenValidation: (state, action: PayloadAction<{ isValid: boolean, wasValidated: boolean }>) => {
      state.wasTokenValidated = action.payload.wasValidated
      state.isTokenValid = action.payload.isValid
    },
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload
    }
  },
});

export const { actions, reducer } = userSlice
