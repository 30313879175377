import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {stripHTML} from '../../../lib'
import {AssetFetchedFromEcComponent, TextFetchedFromEcComponent} from '../../../types/core'

export interface EcComponentContentSelectionFormState {
  texts: Array<TextFetchedFromEcComponent>
  assets: Array<AssetFetchedFromEcComponent>
  selectedTexts: Array<string>
  selectedAssets: Array<string>
  onlyLonger: boolean
  isLoading: boolean
}

const initialState: EcComponentContentSelectionFormState = {
  texts: [],
  assets: [],
  selectedTexts: [],
  selectedAssets: [],
  onlyLonger: true,
  isLoading: true,
}

export const ecComponentContentSelectionFormSlice = createSlice({
  name: 'ecComponentContentSelectionForm',
  initialState,
  reducers: {
    toggleAllTexts: (state) => {
      let selected: string[] = []

      if(state.selectedTexts.length !== state.texts.length){
        selected = state.texts.map(txt => stripHTML(txt.content))
      }

      state.selectedTexts = selected
    },
    toggleAllAssets: (state) => {
      let selected: string[] = []

      if(state.selectedAssets.length !== state.assets.length){
        selected = state.assets.map(asset => asset['id'])
      }

      state.selectedAssets = selected
    },
    toggleSelectedAsset: (state, action: PayloadAction<{ assetId: string }>) => {
      if(state.selectedAssets.includes(action.payload.assetId)){
        state.selectedAssets = state.selectedAssets.filter(text => text !== action.payload.assetId)
      }else{
        state.selectedAssets.push(action.payload.assetId)
      }
    },
    toggleSelectedText: (state, action: PayloadAction<{ text: string }>) => {
      if(state.selectedTexts.includes(action.payload.text)){
        state.selectedTexts = state.selectedTexts.filter(text => text !== action.payload.text)
      }else{
        state.selectedTexts.push(action.payload.text)
      }
    },
    toggleOnlyLonger: (state) => {
      state.onlyLonger = !state.onlyLonger
    },
    markAsIsLoading: (state) => {
      state.isLoading = true
    },
    setAvailableContent: (state, action: PayloadAction<{ texts: Array<TextFetchedFromEcComponent>, assets: Array<AssetFetchedFromEcComponent> }>) => {
      state.texts = action.payload.texts
      state.assets = action.payload.assets
      state.selectedAssets = []
      state.selectedTexts = []
      state.isLoading = false
    }
  },
});

export const {actions, reducer} = ecComponentContentSelectionFormSlice
