import {Button, Menu} from 'antd'
import React from 'react'
import CurrentUser from './CurrentUser'
import {useHistory} from 'react-router-dom'
import {useAppSelector} from '../../hooks'

interface CustomizedWindow extends Window{
  isUat: boolean;
}

declare let window: CustomizedWindow

export default function AppMenu({ selectedKey } : { selectedKey: string }){
  const history = useHistory()
  const user = useAppSelector(state => state.user)

  function logOut(){
    window.localStorage.clear()
    history.replace('/login')
  }

  function isInGroupOrAdmin(groupName: string){
    return user.isAdmin || user.groups.includes(groupName)
  }

  return <div style={{ width: '100%', height: 45 }}>
    <div style={{ display: 'inline-block', width: '80%', verticalAlign: 'top' }}>
      <Menu onClick={e => {}} selectedKeys={[selectedKey]} mode="horizontal">
        {
          window.isUat &&
          <Menu.Item key={'uat'} disabled={true}><strong style={{ color: '#000' }}>UAT</strong></Menu.Item>
        }
        {
          user.isAdmin &&
          <Menu.Item key="finder" onClick={e => window.location.href = '/finder'}>
            Finder
          </Menu.Item>
        }
        <Menu.Item key="products" onClick={e => window.location.href = '/products'}>
          Products
        </Menu.Item>
        {
          isInGroupOrAdmin(CurrentUser.GROUP_HTML_EXPORTERS) &&
          <Menu.Item key="html-exports" onClick={e => window.location.href = '/html-exports'}>
            HTML Exports
          </Menu.Item>
        }
        {
          isInGroupOrAdmin(CurrentUser.GROUP_DATA_EXPORTERS) &&
          <Menu.Item key="data-exports" onClick={e => window.location.href = '/data-exports'}>
            Data exports
          </Menu.Item>
        }
        {
          isInGroupOrAdmin(CurrentUser.GROUP_ASSET_EXPORTERS) &&
          <Menu.Item key="content-exports" onClick={e => window.location.href = '/content-exports'}>
            Content exports
          </Menu.Item>
        }
        {
          isInGroupOrAdmin(CurrentUser.GROUP_OZON_CREATORS) &&
          <Menu.Item key="content-publications" onClick={e => window.location.href = '/content-publications'}>
            Content publications
          </Menu.Item>
        }
        {
          user.isAdmin &&
          <>
            <Menu.Item key="export-presets" onClick={e => window.location.href = '/export-presets'}>
              Export presets
            </Menu.Item>
            <Menu.Item key="export-configs" onClick={e => window.location.href = '/export-configs'}>
              Export configs
            </Menu.Item>
          </>
        }
      </Menu>
    </div>
    <div style={{ display: 'inline-block', width: '20%', verticalAlign: 'top', textAlign: 'right' }}>
      <Button style={{ marginTop: 8, marginRight: 16 }} onClick={() => logOut() }>Logout</Button>
    </div>
  </div>
}