import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ProductDataExport} from '../../types/core'

export interface DataExportsListDataState {
  total: number;
  exports: Array<ProductDataExport>;
}

export interface DataExportsListState extends DataExportsListDataState{
  query: string;
  page: number;
  isLoading: boolean;
}

const initialState: DataExportsListState = {
  query: '',
  total: 0,
  page: 1,
  exports: [],
  isLoading: false,
}

export const dataExportsListSlice = createSlice({
  name: 'dataExportsList',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setExports: (state, action: PayloadAction<DataExportsListDataState>) => {
      state.exports = action.payload.exports
      state.total = action.payload.total
      state.isLoading = false
    }
  },
});

export const { actions, reducer } = dataExportsListSlice

