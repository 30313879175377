export class ContentBundleFormat{
  static ROSSMANN = 1
  static ASSETS_AS_ZIP = 2

  static NAMES = {
    [ContentBundleFormat.ROSSMANN]: 'Rossmann',
    [ContentBundleFormat.ASSETS_AS_ZIP]: 'Assets as zip',
  }
}

export class ContentBundleStatus{
  static INITIALIZED = 1
  static CREATION_IN_PROGRESS = 2
  static READY = 3
  static FAILED = 4

  static NAMES = {
    [ContentBundleStatus.INITIALIZED]: 'Initialized',
    [ContentBundleStatus.CREATION_IN_PROGRESS]: 'Initialized',
    [ContentBundleStatus.READY]: 'Ready',
    [ContentBundleStatus.FAILED]: 'Failed',
  }
}

interface ContentBundleCreator{
  username: string
}

export interface ContentBundle{
  id: number;
  user: ContentBundleCreator;
  status: number;
  cxh_product_name: string;
  cxh_product_uuid: string;
  cxh_ec_component_uuid: string;
  download_url: string;
}