import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AsyncJobModalState {
  isOpen: boolean
  message: string
}

const initialState: AsyncJobModalState = {
  isOpen: false,
  message: ''
}

export const asyncJobModalSlice = createSlice({
  name: 'asyncJobModalSlice',
  initialState,
  reducers: {
    close: (state) => {
      state.isOpen = false
    },
    open: (state, action: PayloadAction<{ message: string }>) => {
      state.isOpen = true
      state.message = action.payload.message
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    }
  },
});

export const { actions, reducer } = asyncJobModalSlice
