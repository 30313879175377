import apiAccess from './apiAccess'
import {ContentPublicationFormats} from '../types/content_publications'
import {iOzonWidget} from '../types/ozon'


class ContentPublicationsAPI {
  static prepareOzonTemporaryJson(publicationId: number, ozon_widgets: Array<iOzonWidget>, next: Function){
    apiAccess.url(`/content-publications/${publicationId}/prepare-temporary-json`)
      .post({ ozon_widgets })
      .json(response => { next(response) })
  }

  static publish(publicationId: number, next: Function){
    apiAccess.url(`/content-publications/${publicationId}/publish`)
      .patch()
      .json(response => { next(response['task_id']) })
  }

  static fetchUploadedAssets(publicationId: number, next: Function){
    apiAccess.url(`/content-publications/${publicationId}/uploaded-assets`)
      .get()
      .json(response => { next(response['assets']) })
  }

  static uploadAsset(publicationId: number, filename: string, content: string, next: Function){
    apiAccess.url(`/content-publications/${publicationId}/uploaded-assets`)
      .post({ filename, content })
      .json(response => {
        next(response)
      })
  }

  static updateOzonWidgets(publicationId: number, ozon_widgets: iOzonWidget[], next: Function){
    apiAccess.url(`/content-publications/${publicationId}/update-ozon-widgets`)
      .patch({ ozon_widgets })
      .json(response => {
        next(response)
      })
  }

  static fetchDetails(publicationId: number, next: Function){
    apiAccess.url(`/content-publications/${publicationId}`)
      .get()
      .json(response => {
        next(response)
      })
  }

  static delete(publicationId: number, next: Function){
    apiAccess.url(`/content-publications/${publicationId}`)
      .delete()
      .json(response => {
        next(true)
      })
  }

  static fetchEcAssetsAndTexts(publicationId: number, next: Function){
    apiAccess.url(`/content-publications/${publicationId}/ec-assets-and-texts`)
      .get()
      .json(response => {
        next(response['assets'], response['texts'])
      })
  }
  static fetchList(next: Function){
    apiAccess.url(`/content-publications`)
      .get()
      .json(response => {
        next(response['publications'], response['total'])
      })
  }

  static createOzon(cxh_product_uuid: string, cxh_ec_component_uuid: string, cxh_ec_component_language: string, next: Function){
    apiAccess.url(`/content-publications`)
      .post({
        format: ContentPublicationFormats.OZON,
        cxh_product_uuid, cxh_ec_component_uuid, cxh_ec_component_language
      })
      .json(resp => {
        next(resp['id'])
      })
  }
}

export default ContentPublicationsAPI