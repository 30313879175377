import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {DistributionTemplate, ProductDataExportDataSchemes, Receipient} from '../../types/core'

export interface SwitchVendorModalState {
  isOpen: boolean
  productUuids: string[]
  receipients: Receipient[]
  receipientUuid?: string
  templates: DistributionTemplate[]
  distributionTemplateUuid?: string
  selectedFormat: string
  dataScheme: string
  includeEmptyProducts: boolean
  deliverToFtp: boolean
}

const OX_REC_UUID = '521e4842-710b-44cd-82b1-f894ac48f85f'

const initialState: SwitchVendorModalState = {
  isOpen: false,
  productUuids: [],
  receipients: [],
  receipientUuid: OX_REC_UUID,
  templates: [],
  distributionTemplateUuid: undefined,
  selectedFormat: '',
  dataScheme: '',
  includeEmptyProducts: false,
  deliverToFtp: false,
}

export const productDataExportModalSlice = createSlice({
  name: 'productDataExportModal',
  initialState,
  reducers: {
    toggleFtpDelivery: (state) => {
      state.deliverToFtp = !state.deliverToFtp
    },
    setDataScheme: (state, action: PayloadAction<string>) => {
      state.dataScheme = action.payload
    },
    setSelectedFormat: (state, action: PayloadAction<string>) => {
      state.selectedFormat = action.payload
    },
    setSelectedTemplateUuid: (state, action: PayloadAction<string>) => {
      state.distributionTemplateUuid = action.payload
      state.dataScheme = ProductDataExportDataSchemes.PRODUCTS_AS_ROWS

      const tpl = state.templates.find((tpl: DistributionTemplate) => tpl.id === action.payload)

      if(tpl){
        state.selectedFormat = tpl.format
      }
    },
    setTemplates: (state, action: PayloadAction<DistributionTemplate[]>) => {
      state.templates = action.payload
      state.distributionTemplateUuid = undefined
    },
    setSelectedReceipient: (state, action: PayloadAction<string>) => {
      state.receipientUuid = action.payload
    },
    setReceipients: (state, action: PayloadAction<Receipient[]>) => {
      state.receipients = action.payload
    },
    close: (state) => {
      state.isOpen = false
    },
    open: (state, action: PayloadAction<string[]>) => {
      state.isOpen = true
      state.productUuids = action.payload
      state.includeEmptyProducts = false
    },
    toggleIncludingEmptyProducts: (state) => {
      state.includeEmptyProducts = !state.includeEmptyProducts
    }
  },
});

export const { actions, reducer } = productDataExportModalSlice
