import { CSSProperties, FC, memo } from 'react'
import { ConnectDropTarget, DropTargetMonitor } from 'react-dnd'
import { DropTarget } from 'react-dnd'
import {applySizeToAssetUrl} from '../../lib'

const style: CSSProperties = {
  display: 'inline-block',
  height: '100%',
  width: '100%',
  color: '#000',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  backgroundPosition: 'center center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  border: '1px dashed #aaa'
}

export interface DustbinProps {
  accepts: string[]
  lastDroppedItem?: any
  onDrop: (item: any) => void
  backgroundImageUrl: string
  // Collected Props
  canDrop: boolean
  isOver: boolean
  connectDropTarget: ConnectDropTarget
  size?: Array<number>
}

export const Dustbin: FC<DustbinProps> = memo(function Dustbin({
  accepts,
  isOver,
  canDrop,
  connectDropTarget,
  lastDroppedItem,
  backgroundImageUrl,
  size,
}) {
  const isActive = isOver && canDrop

  let backgroundColor = '#fff'
  let backgroundImage = ''

  if (isActive) {
    backgroundColor = 'darkgreen'
  } else if (canDrop) {
    backgroundColor = 'darkkhaki'
  }

  if(backgroundImageUrl.length > 0){
    backgroundImageUrl = applySizeToAssetUrl(backgroundImageUrl)
    backgroundImage = `url(${backgroundImageUrl})`
  }

  let divStyle = { ...style, backgroundColor, backgroundImage }

  if(size){
    divStyle['width'] = size[0]
    divStyle['height'] = size[1]
  }

  return connectDropTarget(
    <div
      ref={connectDropTarget}
      style={ divStyle }
    >
      {isActive
        ? 'Loading ...'
        : ''
      }

      {lastDroppedItem && (
        <p>Last dropped: {JSON.stringify(lastDroppedItem)}</p>
      )}
    </div>,
  )
})

export default DropTarget(
  (props: DustbinProps) => props.accepts,
  {
    drop(props: DustbinProps, monitor: DropTargetMonitor) {
      props.onDrop(monitor.getItem())
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
)(Dustbin)
