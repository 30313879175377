import { format } from 'date-fns'

const ASSETS_EDGENET_HOSTNAME = 'assets.edgenet.com'
const SYNDIGO_CONTENT_HOSTNAME = 'assets.edgenet.com'

function applySizeToAssetUrl(assetUrl: string, size: number = 300){
  if(assetUrl.indexOf(SYNDIGO_CONTENT_HOSTNAME) !== -1 || assetUrl.indexOf(ASSETS_EDGENET_HOSTNAME) !== -1){
    return `${assetUrl}?size=${size}`
  }else{
    return assetUrl
  }
}


function formatDateTime(dt: string){
  if(dt){
    return format(new Date(dt), 'dd-MM-yyyy HH:ii')
  }else{
    return ''
  }
}


function stripHTML(html: string){
   let doc = new DOMParser().parseFromString(html, 'text/html');
   return doc.body.textContent || "";
}


function formatFileSize(bytes: number, si: boolean=false, dp: number=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

function shortenUuid(uuid: string){
  return uuid.substr(0, 4) + '...' + uuid.substr(uuid.length - 4)
}

export { stripHTML, formatFileSize, shortenUuid, formatDateTime, applySizeToAssetUrl  }