import React, {useEffect, useState} from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom"
import VendorSelector from "../VendorSelector/VendorSelector"
import LoginPage from "../LoginPage/LoginPage"
import ProductsList from '../ProductsList/ProductsList'
import OzonPublication from '../OzonPublication/OzonPublication'
import ContentPublicationsList from '../ContentPublicationsList/ContentPublicationsList'
import ExportPresetsList from '../HtmlExportPresetsList/ExportPresetsList'
import ContentExportsList from '../ContentExportsList/ContentExportsList'
import './App.css'
import AuthAPI from '../../api/auth'
import AsyncJobModal from '../AsyncJobModal/AsyncJobModal'
import HtmlExportEditor from '../HtmlExportEditor/HtmlExportEditor'
import Finder from '../Finder/Finder'
import ProductEcModal from '../ProductEcModal/ProductEcModal'
import HtmlExportModal from '../HtmlExportModal/HtmlExportModal'
import HtmlExportsList from '../HtmlExportsList/HtmlExportsList'
import ContentBundleCreationModal from '../ContentBundleCreationModal/ContentBundleCreationModal'
import ExportConfigsList from '../HtmlExportConfigsList/ExportConfigsList'
import {SwitchVendorModal} from '../SwitchVendorModal/SwitchVendorModal'
import ProductDataExportModal from '../ProductDataExportModal/ProductDataExportModal'
import CurrentUser from './CurrentUser'
import ProductDataExportsList from '../ProductDataExportsList/ProductDataExportsList'
import {useAppDispatch, useAppSelector} from '../../hooks'
import { actions as userActions } from '../../userSlice'
import {Vendor} from '../../types/core'

export default function App(){
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user)

  useEffect(() => {
    const token = CurrentUser.getToken()

    if(!token){
      dispatch(userActions.setTokenValidation({ isValid: false, wasValidated: true }))
    }else{
      AuthAPI.validateToken(
        token,
        (isTokenValid: boolean, isAdmin: boolean, vendor: Vendor, groups: string[] ) => {

          if(isTokenValid) {
            if(vendor){
              CurrentUser.setSelectedVendor(vendor)
            }

            dispatch(userActions.setDetails({
              wasTokenValidated: true,
              token,
              isTokenValid: true,
              locale: 'en-US',
              isAdmin,
              groups,
            }))
          }else{
            dispatch(userActions.setTokenValidation({ isValid: false, wasValidated: true }))
          }
        },
      )
    }
  }, [dispatch])

  if(!user.wasTokenValidated){
    return <div></div>
  }

  if(user.wasTokenValidated && user.isTokenValid){
    return <>
      <BrowserRouter>
        <Switch>
          <Route path={"/login"}>
            <LoginPage/>
          </Route>
          <Route path={"/data-exports"}>
            <ProductDataExportsList />
          </Route>
          <Route path={"/html-exports"}>
            <HtmlExportsList />
          </Route>
          <Route path={"/vendor-selector"}>
            <VendorSelector />
          </Route>
          <Route path={"/content-publications/:publication_id/ozon"}>
            <OzonPublication/>
          </Route>
          <Route path={"/content-exports"}>
            <ContentExportsList/>
          </Route>
          <Route path={"/content-publications"}>
            <ContentPublicationsList/>
          </Route>
          <Route path={"/products"}>
            <ProductsList />
          </Route>
          <Route path={"/export-presets"}>
            <ExportPresetsList />
          </Route>
          <Route path={"/finder"}>
            <Finder/>
          </Route>
          <Route path={"/export-configs"}>
            <ExportConfigsList/>
          </Route>
          <Route path={"/html-export-editor/:product_uuid/:component_uuid"}>
            <HtmlExportEditor/>
          </Route>
          <Route path={"/"}>
            <ProductsList/>
          </Route>
        </Switch>
        <AsyncJobModal/>
        <ProductEcModal/>
        <HtmlExportModal/>
        <ContentBundleCreationModal/>
        <SwitchVendorModal/>
        <ProductDataExportModal/>
      </BrowserRouter>
    </>
  }else{
    return <BrowserRouter>
      <Switch>
        <Route>
          <LoginPage/>
        </Route>
      </Switch>
    </BrowserRouter>
  }
}
