const SID = '39e7db2d-3435-4ad1-b0d7-beeb31a725db'

function cxhComponentPreviewUrl(productId: string, componentId: string, addSideId=false){
  let end = addSideId ? `&siteId=${SID}` : ''
  return `https://platform.syndigo.com/enhanced-content/previews/${productId}?componentId=${componentId}${end}`
}

function htmlExportPreviewUrl(productId: string, componentId: string){
  return `/html-export-preview/${productId}/${componentId}`
}

function cxhProductUrl(productUuid: string){
  return `https://platform.syndigo.com/products/details/${productUuid}`
}

export { cxhComponentPreviewUrl, htmlExportPreviewUrl, cxhProductUrl }