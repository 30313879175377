import React, {useEffect} from 'react'
import {Button, PageHeader, Alert, Input, Select, Layout} from 'antd'
import ProductsAPI from '../../api/ProductsAPI'
import {Locale, Product} from '../../types/core'
import { actions } from './slice'
import { actions as ProductEcModalActions } from '../ProductEcModal/slice'
import { actions as SwitchVendorModalActions } from '../SwitchVendorModal/slice'
import { actions as ProductDataExportModalActions } from '../ProductDataExportModal/slice'
import { useAppDispatch, useAppSelector } from '../../hooks'
import AppMenu from '../App/Menu'
import ProductsTable from '../ProductsTable/ProductsTable'
import {useDebounce} from '../../utils'
import CurrentUser from '../App/CurrentUser'
import {useHistory} from 'react-router-dom'
import LocalesAPI from '../../api/LocalesAPI'
import { actions as userActions } from '../../userSlice'


function ProductsList(){
  const state = useAppSelector(state => state.productsList)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const user = useAppSelector(state => state.user)

  const fetchProducts = useDebounce(
    function(vendorId: string, query: string, page: number, locale: string, gtins: string) {
      dispatch(actions.setIsLoading(true))

      if(vendorId.length > 0){
        let gtinsList: string[] = []

        if(gtins.length > 0){
          gtinsList = gtins.trim().split('\n').map((gtin: string) => gtin.trim() )
        }

        ProductsAPI.fetchList(vendorId, (products: Product[], total: number) => {
          dispatch(actions.setProducts({products, total}))
        }, {query, page, locale, gtins: gtinsList })
      }
    }
  , 500)

  useEffect(() => {
    const vendorId = CurrentUser.getSelectedVendorId()

    if(!vendorId){
      history.replace('/vendor-selector')
    }else{
      dispatch(actions.init({ vendorId: vendorId, locale: user.locale }))

      LocalesAPI.fetchList((locales: Locale[]) => {
        dispatch(actions.setLocales(locales))
      })
    }
  }, [dispatch])


  useEffect(() => {
    fetchProducts(state.vendorId, state.query, state.page, state.locale, state.gtins)
  }, [dispatch, state.page, state.query, state.vendorId, state.locale, state.gtins])

  function onQueryChange(evt: React.ChangeEvent<HTMLInputElement>) {
    dispatch(actions.setQuery(evt.target.value))
  }

  function exportAllTheThings() {
    dispatch(ProductDataExportModalActions.open(state.selected))
  }

  let vendorId = CurrentUser.getSelectedVendorId()
  let content = null
  let title = <span>Products list</span>

  if(vendorId){
    title = <span>
      Products list - <small>Vendor: <strong>{ CurrentUser.getSelectedVendorName() }</strong></small>
      {
        user.isAdmin &&
        <Button
          type={'link'}
          onClick={() => dispatch(SwitchVendorModalActions.open())}
        >Change</Button>
      }
      {
        (user.isAdmin || user.groups.includes(CurrentUser.GROUP_DATA_EXPORTERS)) && state.selected.length > 0 &&
        <Button
            size={'small'}
          type={'primary'}
          onClick={ exportAllTheThings }
            style={{ marginLeft: 10 }}
        >
          {
            state.selected.length > 0 ?
              <span>Export <strong> ({state.selected.length}) </strong> product(s)</span>
              :
            <span>Export all products data</span>
          }
        </Button>
      }
    </span>
  }

  function handleGtinPaste(event: React.ClipboardEvent<HTMLTextAreaElement>){
    dispatch(actions.setGtins(event.clipboardData.getData('text')))
    event.stopPropagation()
    return false
  }

  function handleGtinChange(gtinsString: string){
    dispatch(actions.setGtins(gtinsString))
  }

  if(vendorId) {
    content = <Layout>
      <Layout.Sider width={ 300 } theme={'light'}>
        <h3><strong>Search</strong></h3>
        <Input.Search
          placeholder={'Enter your query ...'}
          value={state.query}
          onChange={onQueryChange}
        />
        <br/><br/>
        <h3><strong>Filter by GTINs</strong></h3>
        <Input.TextArea
          rows={10}
          onPaste={ handleGtinPaste }
          onChange={(e) => handleGtinChange(e.currentTarget.value)}
        />
      </Layout.Sider>
      <Layout.Content style={{ backgroundColor: '#fff', paddingLeft: 30 }}>
        <ProductsTable
          locale={ user.locale }
          products={state.products}
          isLoading={state.isLoading}
          total={state.total}
          page={state.page}
          onProductSelect={(product: Product) => {
            if(user.isAdmin || user.groups.includes(CurrentUser.GROUP_HTML_EXPORTERS)){
              dispatch(ProductEcModalActions.open(product.id))
            }else{
              dispatch(actions.toggleSelectedProduct(product.id))
            }
          }}
          onPageChange={(page: number) => dispatch(actions.setPage(page))}
          selectable={ true }
          selected={ state.selected }
          toggleProductSelection={(productUuid: string) => dispatch(actions.toggleSelectedProduct(productUuid))}
        />
      </Layout.Content>
    </Layout>
  }else{
    content = <div>
      <Alert message={"You must select a vendor to see products"} type={"warning"} />
      <br /><br />
      <Button href={"/vendor-selector"}>Go to vendor select</Button>
    </div>
  }

  function setNewLocale(locale: string){
    dispatch(userActions.setLocale(locale))
  }

  let localeSelector = state.locales.length > 0 && <Select
    defaultValue={ user.locale }
    style={{ width: 200 }}
    onChange={ setNewLocale }
  >
    {
      state.locales.map((loc: Locale) => {
        return <Select.Option value={ loc.code }>{ loc.code }</Select.Option>
      })
    }
  </Select>

  return <div>
    <AppMenu selectedKey={'products'}/>
    <PageHeader
      title={title}
      backIcon={ false }
      extra={ localeSelector }
    />
      <div style={{ padding: '0 20px' }}>
        {content}
      </div>
  </div>
}

export default ProductsList