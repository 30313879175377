import React from 'react'
import { CSSProperties, FC, memo } from 'react'
import { ConnectDragSource, DragSourceMonitor } from 'react-dnd'
import { DragSource, DragSourceConnector } from 'react-dnd'

const style: CSSProperties = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  cursor: 'move',
  float: 'left',
}

export interface BoxProps {
  name: string
  type: string
  text?: string
  asset?: object
  isDropped: boolean
  content: React.ReactNode

  // Collected Props
  connectDragSource: ConnectDragSource
  isDragging: boolean
}

export const Box: FC<BoxProps> = memo(function Box({
  content,
  isDropped,
  isDragging,
  connectDragSource,
}) {
  const opacity = isDragging ? 0.4 : 1
  return connectDragSource(
    <div style={{ ...style, opacity }}>
      {content}
    </div>,
  )
})

export default DragSource(
  (props: BoxProps) => props.type,
  {
    beginDrag: (props: BoxProps) =>
      ({
        type: props.type,
        text: props.text,
        asset: props.asset,
      }),
  },
  (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(Box)
