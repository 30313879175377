import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {Button, Descriptions, Dropdown, Menu, Modal, Table} from 'antd'
import {CloudDownloadOutlined, HighlightOutlined, EyeOutlined, CodepenOutlined, SmileOutlined} from '@ant-design/icons'

import {actions} from './slice'
import {actions as contentBundleCreatorModalActions} from '../ContentBundleCreationModal/slice'
import ProductsAPI from '../../api/ProductsAPI'
import ContentPublicationsAPI from '../../api/ContentPublicationsAPI'
import {useAppDispatch, useAppSelector} from '../../hooks'
import { EcComponent, Product } from '../../types/core'
import {cxhComponentPreviewUrl, htmlExportPreviewUrl} from '../../lib/urls'
import { formatDateTime } from '../../lib'
import { actions as htmlExportModalActions } from '../HtmlExportModal/slice'
import {ContentBundleFormat} from '../../types/content_bundles'
import CurrentUser from '../App/CurrentUser'


export default function ProductEcModal() {
  const state = useAppSelector(state => state.productEcModal)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const user = useAppSelector(state => state.user)

  useEffect(() => {
    // dispatch(htmlExportModalActions.open({
    //   productUuid: '09441d2b-2f30-d9d4-668f-ac5bee1a8645',
    //   componentUuid: '20c0a9bf-e286-e101-ac3f-f9d61cd7ede0',
    //   format: 1,
    // }))

    if (state.productId) {
      dispatch(actions.setIsLoading(true))

      ProductsAPI.fetchProductEc(state.productId, (components: Array<EcComponent>) => {
        dispatch(actions.setComponents(components))
        dispatch(actions.setIsLoading(false))
      })

      ProductsAPI.fetchDetails(state.productId, (product: Product) => {
        dispatch(actions.setProduct(product))
      })

    }
  }, [dispatch, state.productId])

  function checkComponentValidity(componentUuid: string, ifValid: Function){
    if(state.productId){
      ProductsAPI.checkComponentValidity(state.productId, componentUuid, (isValid: boolean) => {
        if(isValid){
          ifValid()
        }else{
          Modal.error({
            width: 700,
            title: 'Error',
            content: <div>
              <p></p>
              <p>This Enhanced Content component is currently not compatible with our system.</p>
              <p>Maintainers has been notified about this problem.</p>
              <p>Please try again later or contact appropriate people.</p>
              <p></p>
              <p>Sorry for the Inconvenience.</p>
            </div>
          })
        }
      })
    }
  }

  function createOzon(componentId: string, language: string) {
    checkComponentValidity(componentId, () => {
      ContentPublicationsAPI.createOzon(state.productId, componentId, language, (ozonPublicationId: number) => {
        dispatch(actions.close())
        history.push(`/content-publications/${ozonPublicationId}/ozon`)
      })
    })
  }

  function triggerExport(componentUuid: string, format: number) {
    checkComponentValidity(componentUuid, () => {
      dispatch(contentBundleCreatorModalActions.open({
        productId: state.productId,
        componentId: componentUuid,
        format
      }))
    })
  }

  function initHtmlExport(componentUuid: string) {
    checkComponentValidity(componentUuid, () => {
      dispatch(htmlExportModalActions.open({ productUuid: state.productId, componentUuid }))
    })
  }

  return <Modal
    visible={state.isOpen}
    width={950}
    title={<span>Product details&nbsp;&nbsp;&nbsp;<small style={{color: '#bbb'}}>{state.productId}</small></span>}
    onCancel={() => dispatch(actions.close())}
  >
    {state.product && state.product.attributes &&
      <Descriptions
        size={'small'}
        style={{ marginBottom: 10 }}
        labelStyle={{ fontWeight: 'bold' }}
      >
        <Descriptions.Item label={"Name"}>
          {
            state.product.attributes['en-US'] ?
              state.product.attributes['en-US'].product_name
              :
              <i>Name not found</i>
          }
        </Descriptions.Item>
        <Descriptions.Item label={"GTIN"}>
          {
            state.product.attributes['en-US'] ?
            state.product.attributes['en-US'].gtin
              :
              <i>GTIN not found</i>
          }
        </Descriptions.Item>
      </Descriptions>
    }
    <Table
      pagination={false}
      size={'small'}
      loading={state.isLoading}
      dataSource={state.components}
      rowKey={'id'}
    >
      <Table.Column key={'language'} dataIndex={'language'} title={'Lang.'}/>
      <Table.Column key={'countries'} dataIndex={'countries'} title={'Countries'} render={(countries: string[]) => {
        return countries.join(', ')
      }}/>
      <Table.Column key={'updated_at'} dataIndex={'updated_at'} title={'Updated'} render={(text: string) => {
        return formatDateTime(text)
      }}/>
      <Table.Column
        key={'widgets_count'}
        dataIndex={'id'}
        title={'Widgets'}
        render={(text: string, comp: EcComponent) => {
          return comp.power_page_widget_uuids.length
        }}
      />
      <Table.Column
        key={'parent_product_uuid'}
        dataIndex={'parent_product_uuid'}
        title={'From parent?'}
        render={(text: string, comp: EcComponent) => {
          return comp.parent_product_uuid ? 'Yes' : 'No'
        }}
      />
      <Table.Column
        width={300}
        key={'actions'}
        dataIndex={'id'}
        title={'Actions'}
        render={(text: string, comp: EcComponent) => {
          let widgets_count = comp.power_page_widget_uuids.length
          const exportDropdown = (
            <Menu>
              <Menu.Item key="assets-zip" onClick={() => triggerExport(comp.id, ContentBundleFormat.ASSETS_AS_ZIP)}>
                Assets only
              </Menu.Item>
              {
                false &&
                <Menu.Item key="rossmann" onClick={() => triggerExport(comp.id, ContentBundleFormat.ROSSMANN)}>
                  For Rossmann
                </Menu.Item>
              }
            </Menu>
          );

          return widgets_count > 0 ?
            <>
              <Button
                type={'link'}
                target={'_blank'}
                href={htmlExportPreviewUrl(state.productId, comp.id)}
                icon={ <CodepenOutlined/> }
              >HTML Preview</Button>
              {
                (user.isAdmin || user.groups.includes(CurrentUser.GROUP_HTML_EXPORTERS)) &&
                <Button
                  size={'small'}
                  type={'link'}
                  target={'_blank'}
                  href={ cxhComponentPreviewUrl(state.productId, comp.id) }
                  icon={ <EyeOutlined/> }
                >CXH Preview</Button>
              }
              <Button.Group>
              {
                (user.isAdmin || user.groups.includes(CurrentUser.GROUP_ASSET_EXPORTERS)) &&
                <Dropdown overlay={exportDropdown}>
                  <Button size={'small'}
                    style={{marginLeft: 10}}
                  >
                    Export <CloudDownloadOutlined />
                  </Button>
                </Dropdown>
              }
              {
                (user.isAdmin || user.groups.includes(CurrentUser.GROUP_OZON_CREATORS)) &&
                <Button
                  onClick={() => createOzon(comp.id, comp.language)}
                  style={{marginLeft: 10}}
                  icon={<HighlightOutlined />}
                  size={'small'}
                >Create&nbsp;<strong style={{color: 'blue'}}>Ozon</strong></Button>
              }
              {
                (user.isAdmin || user.groups.includes(CurrentUser.GROUP_HTML_EXPORTERS)) &&
                <Button
                  size={'small'}
                  style={{marginLeft: 10}}
                  icon={ <SmileOutlined/> }
                  onClick={() => initHtmlExport(comp.id)}
                ><strong> HTML </strong> export</Button>
              }
              </Button.Group>
            </>
            :
          null
        }}
      />
    </Table>
  </Modal>
}