import AppMenu from '../App/Menu'
import {PageHeader, Table, Button} from 'antd'
import React, {useEffect} from 'react'
import {actions} from './slice'
import {useAppDispatch, useAppSelector} from '../../hooks'
import ProductDataExportsAPI from '../../api/ProductDataExportsAPI'
import {ProductDataExport} from '../../types/core'
import {formatDateTime} from '../../lib'
import {ProductDataExportStatus} from '../../types/core'
import {ContentBundle, ContentBundleStatus} from '../../types/content_bundles'

const PAGE_SIZE = 100


export default function ProductDataExportsList(){
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.productDataExportsList)

  useEffect(() => {
    ProductDataExportsAPI.fetchList((resp: { exports: ProductDataExport[], total: number }) => {
      dispatch(actions.setExports(resp))
    })
  }, [dispatch])

  return <>
    <AppMenu selectedKey={'data-exports'}/>
    <PageHeader title={'Product data exports'} backIcon={ false } />
    <div style={{ padding: '0 20px' }}>
      <Table
        bordered={true}
        rowKey={'id'}
        size={'small'}
        dataSource={state.exports}
        loading={state.isLoading}
        pagination={{
          total: state.total,
          showSizeChanger: false,
          pageSize: PAGE_SIZE,
          position: ['topLeft', 'bottomRight'],
          onChange: (page: number) => {},
          current: state.page,
        }}
      >
        <Table.Column
          title={'ID'}
          key={'id'}
          dataIndex={'id'}
        />
        <Table.Column
          title={'Created'}
          key={'created'}
          dataIndex={'created'}
          render={(text, record: ProductDataExport) => formatDateTime(record.created)}
        />
        <Table.Column
          title={'Receipient'}
          key={'receipient_name'}
          dataIndex={'receipient_name'}
        />
        <Table.Column
          title={'Template'}
          key={'distribution_template_name'}
          dataIndex={'distribution_template_name'}
        />
        <Table.Column
          title={'Status'}
          key={'status'}
          dataIndex={'status'}
          render={(sts: number, record: ProductDataExport) => ProductDataExportStatus.NAMES[record.status]}
        />
        <Table.Column
          title={''}
          key={'actions'}
          dataIndex={'actions'}
          render={(_, record: ProductDataExport) => {
            return <>
              {
                (record.status === ProductDataExportStatus.BUILT || record.status === ProductDataExportStatus.DELIVERED)
                &&
                <Button
                  href={`/api/product-data-exports/${record.id}/download`}
                  type={'link'}
                >Download</Button>
              }
            </>
          }}
        />
      </Table>
    </div>
  </>
}