import AppMenu from '../App/Menu'
import {PageHeader, Table, Button} from 'antd'
import React, {useEffect} from 'react'
import {actions} from './slice'
import {useAppDispatch, useAppSelector} from '../../hooks'
import ContentBundlesAPI from '../../api/ContentBundlesAPI'
import {ContentBundle, ContentBundleFormat, ContentBundleStatus} from '../../types/content_bundles'

const PAGE_SIZE = 100

export default function ContentExportsList(){
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.contentExportsList)

  useEffect(() => {
    ContentBundlesAPI.fetchList((resp: { bundles: ContentBundle[], total: number }) => {
      dispatch(actions.setBundles(resp))
    })
  }, [dispatch])

  return <>
    <AppMenu selectedKey={'content-exports'}/>
    <PageHeader title={'Content exports'} backIcon={ false } />
    <div style={{ padding: '0 20px' }}>
      <Table
        bordered={true}
        rowKey={'id'}
        size={'small'}
        dataSource={state.bundles}
        loading={state.isLoading}
        pagination={{
          total: state.total,
          showSizeChanger: false,
          pageSize: PAGE_SIZE,
          position: ['topLeft', 'bottomRight'],
          onChange: (page: number) => {},
          current: state.page,
        }}
      >
        <Table.Column
          title={'ID'} key={'id'} dataIndex={'id'} render={(text, record) => text}
        />
        <Table.Column
          title={'Product name'}
          key={'cxh_product_name'}
          dataIndex={'cxh_product_name'}
          render={(text, record) => text}
        />
        <Table.Column
          title={'Created'}
          key={'created'}
          dataIndex={'created'}
          render={(text, record) => text}
        />
         <Table.Column
          title={'User'}
          key={'user'}
          dataIndex={'user'}
          render={(text, record: ContentBundle) => record.user.username}
        />
        <Table.Column
          title={'Format'}
          key={'format'}
          dataIndex={'format'}
          render={(fmt: number, record) => ContentBundleFormat.NAMES[fmt]}
        />
        <Table.Column
          title={'Status'}
          key={'status'}
          dataIndex={'status'}
          render={(sts: number, record) => ContentBundleStatus.NAMES[sts]}
        />
        <Table.Column
          title={''}
          key={'actions'}
          dataIndex={'actions'}
          render={(_, record: ContentBundle) => {
            return <>
              {
                record.status === ContentBundleStatus.READY &&
                <Button
                  href={`/api/content-bundles/${record.id}/download`}
                  type={'link'}
                >Download</Button>
              }
            </>
          }}
        />
      </Table>
    </div>
  </>
}