import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {HtmlExport} from '../../types/html_exports'

export interface HtmlExportsListDataState {
  total: number;
  exports: HtmlExport[];
}

export interface HtmlExportsListState extends HtmlExportsListDataState{
  query: string;
  page: number;
  isLoading: boolean;
}

const initialState: HtmlExportsListState = {
  query: '',
  total: 0,
  page: 1,
  exports: [],
  isLoading: false,
}

export const htmlExportsListSlice = createSlice({
  name: 'htmlExportsList',
  initialState,
  reducers: {
    removeFromList: (state, action: PayloadAction<number>) => {
      state.exports = state.exports.filter(exp => exp.id !== action.payload)
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setData: (state, action: PayloadAction<HtmlExportsListDataState>) => {
      state.exports = action.payload.exports
      state.total = action.payload.total
      state.isLoading = false
    }
  },
});

export const { actions, reducer } = htmlExportsListSlice

