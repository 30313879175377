import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { HtmlExportPreset } from '../../types/html_exports'

export interface iExportPresetsListDataState {
  total: number;
  presets: Array<HtmlExportPreset>;
}

export interface iExportPresetsListState extends iExportPresetsListDataState{
  isLoading: boolean;
}

const initialState: iExportPresetsListState = {
  total: 0,
  presets: [],
  isLoading: false,
}

export const exportPresetsListSlice = createSlice({
  name: 'exportPresetsList',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    removeFromList: (state, action: PayloadAction<number>) => {
      state.presets = state.presets.filter(p => p.id !== action.payload)
    },
    setPresets: (state, action: PayloadAction<iExportPresetsListDataState>) => {
      state.presets = action.payload.presets
      state.total = action.payload.total
      state.isLoading = false
    }
  },
});

export const { actions, reducer } = exportPresetsListSlice

