import { Checkbox, Collapse, Modal, Radio, Spin } from 'antd'
import {useAppDispatch, useAppSelector} from '../../hooks'
import {useEffect, useRef} from 'react'
import {actions} from './slice'
import ProductsAPI from '../../api/ProductsAPI'
import {HtmlExportPreset, HtmlExportConfig} from '../../types/html_exports'
import {EcComponentWidgetInfo, EcComponentWithWidgetsInfo} from '../../types/core'

import ExportPresetsAPI from '../../api/ExportPresetsAPI'
import HtmlExportsAPI from '../../api/HtmlExportsAPI'
import HtmlExportParamsModal from '../HtmlExportParamsModal/HtmlExportParamsModal'
import { actions as htmlExportParamsModalActions } from '../HtmlExportParamsModal/slice'
import HtmlExportConfigsAPI from '../../api/HtmlExportConfigsAPI'


export default function HtmlExportModal(){
  const dispatch = useAppDispatch()
  let state = useAppSelector(state => state.htmlExportModal)
  const isOpen = state.isOpen
  const stylingPresets = state.stylingPresets
  const iframeEl = useRef<HTMLIFrameElement>(null)

  // f1ea7645-0ea4-dc94-6d94-e91694a30f69 / 7dec9d6d-68d0-4b04-68b3-1d61ae9aa27d

  useEffect(() => {
    if(!isOpen) {
      // dispatch(actions.open({
      //   productUuid: 'bd4049b4-f099-10f0-614e-07875aa4e437',
      //   componentUuid: 'b5420b27-9063-f57b-7af1-23180210f31c',
      // }))
    }
  }, [isOpen, dispatch])

  useEffect(() => {
    if(isOpen){
      ProductsAPI.fetchProductEcComponent(state.productUuid, state.componentUuid, (ec: EcComponentWithWidgetsInfo) => {
        dispatch(actions.setComponentInfo(ec))
      }, {include_widgets: true, include_assets: true})

      ExportPresetsAPI.fetchList((presets: HtmlExportPreset[]) => {
        dispatch(actions.setPresets(presets))
      })

      HtmlExportConfigsAPI.fetchList((configs: HtmlExportConfig[]) => {
        dispatch(actions.setConfigs(configs))
      })
    }
  }, [isOpen, dispatch, state.productUuid, state.componentUuid])

  useEffect(() => {
    if(isOpen && state.selectedWidgets.length > 0 && state.configId > 0){
      dispatch(actions.setIsPreviewLoading(true))

      HtmlExportsAPI.generate(state.productUuid, state.componentUuid, (response: string) => {
        dispatch(actions.setIsPreviewLoading(false))
        setIframeHTML(response)
      }, {
        selectedWidgetUuids: state.selectedWidgets,
        configId: state.configId,
        exportPreset: state.selectedPreset,
      })
    }
  }, [dispatch, isOpen, state.productUuid, state.componentUuid,
    state.selectedPreset, state.selectedWidgets, state.configId,
  ])

  function setIframeHTML(html: string) {
    let s = `${html}`;
    let blob = new Blob([s], {type: "text/html; charset=utf-8"});

    // "data:text/html;charset=utf-8," + escape(html_string);

    if (iframeEl && iframeEl.current && iframeEl.current !== null) {
      iframeEl.current.src = URL.createObjectURL(blob)
    }
  }


  function renderConfig(configId: number, label: string){
    const isChecked = state.configId === configId

    return <div key={ configId }>
      <Radio
        onClick={() => dispatch(actions.setConfigId(configId)) }
        checked={ isChecked }
      >{ label }</Radio>
    </div>
  }

  function onOk(){
    dispatch(htmlExportParamsModalActions.open({
      productUuid: state.productUuid,
      componentUuid: state.componentUuid,
      configId: state.configId,
    }))
  }

  const settings = <Collapse defaultActiveKey={['configuration', 'preset']}>
    <Collapse.Panel key={'configuration'} header={'Configuration'}>
      {
        state.configs.map((config: HtmlExportConfig) => {
          return renderConfig(config.id, config.name)
        })
      }
    </Collapse.Panel>
    {state.componentInfo &&
      <Collapse.Panel key={'widgets'} header={'Widgets'}>
        {
         state.widgets.map((widget: EcComponentWidgetInfo) => {
            return <div key={ widget.id }>
              <Checkbox
                onChange={() => dispatch(actions.toggleSelectedWidget(widget.id))}
                checked={state.selectedWidgets.includes(widget.id)}
              >

                <span style={{ fontSize: 13 }}>{widget.caption.length > 0 ? widget.caption : <i>No caption</i>}</span>
                <span style={{color: '#aaa', fontSize: 11 }}> - {widget.type}</span>
              </Checkbox>
            </div>
          })
        }
      </Collapse.Panel>
    }
    <Collapse.Panel key={'preset'} header={'Styling preset'}>
      <Radio
        onChange={() => dispatch(actions.setSelectedPreset(0))}
        key={ 0 }
        checked={ state.selectedPreset === 0 }
      >Default</Radio>
      <br/>
      {
        stylingPresets.map((preset: HtmlExportPreset) => {
          return <>
            <Radio
              onChange={() => dispatch(actions.setSelectedPreset(preset.id))}
              key={preset.id}
              checked={ preset.id === state.selectedPreset }
            >{ preset.name }</Radio>
            <br/>
          </>
        })
      }
    </Collapse.Panel>
  </Collapse>

  return <Modal
    title={<span>Export modal - <small>{ state.productUuid + ' / ' + state.componentUuid }</small></span>}
    visible={ state.isOpen }
    onOk={ onOk }
    onCancel={() => dispatch(actions.close())}
    width={ '98%' }
    style={{ top: 10 }}
    bodyStyle={{ height: 'calc(100vh - 150px)', padding: '10px 10px' }}
  >
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ width: 250, float: 'left', height: '100%', paddingRight: 10 }}>
        { settings }
      </div>
      <div style={{ paddingLeft: 20, position: 'relative', width: 'calc(100% - 260px)', display: 'inline-block', height: '100%'  }}>
        <div style={{
          display: state.isLoadingPreview ? 'block': 'none',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.35)',
          position: 'absolute',
          // transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}>
          <div style={{
            backgroundColor: '#fff',
            width: 100,
            height: 100,
            display: 'inline-block',
            marginTop: 'calc(100vh - 650px)',
            paddingTop: 20,
          }}><Spin size={'large'} tip={'Loading...'}/></div>
        </div>
        <iframe title={'preview'} ref={iframeEl} style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          width: '100%',
          height: '100%'
        }}></iframe>
      </div>
    </div>
    <HtmlExportParamsModal/>
  </Modal>
}