import React from 'react'
import {Col, Input, Row} from 'antd'
import ImageDropBin from './ImageDropBin'
import {iOzonWidgetBlockImage} from '../../types/ozon'
import {AssetFetchedFromEcComponent} from '../../types/core'

interface iDroppedAsset{
  type: string
  asset: AssetFetchedFromEcComponent
}

export default function OzonWidgetBlockImageControl(
  { image, imgLink, onImgLinkChange, onImagePropertyChange }
    :
  { image: iOzonWidgetBlockImage, imgLink?: string, onImgLinkChange: Function, onImagePropertyChange: Function }
){

  function handleDrop(droppedItem: iDroppedAsset){
    onImagePropertyChange('src', droppedItem.asset.url)
    onImagePropertyChange('width', droppedItem.asset.width)
    onImagePropertyChange('height', droppedItem.asset.height)
    onImagePropertyChange('containWidth', '')
    onImagePropertyChange('containHeight', '')

    onImagePropertyChange('srcMobile', droppedItem.asset.url)
    onImagePropertyChange('widthMobile', droppedItem.asset.width)
    onImagePropertyChange('heightMobile', droppedItem.asset.height)
    onImagePropertyChange('containWidthMobile', '')
    onImagePropertyChange('containHeightMobile', '')
  }

  return <Row>
    <Col span={8}>
      <div style={{ height:200, width: 200, backgroundColor: '#eee', border: '1px solid #aaa', margin: '9px 0 0 9px' }}>
        <ImageDropBin
          accepts={['image']}
          lastDroppedItem={ null }
          onDrop={(item) => handleDrop(item)}
          backgroundImageUrl={ image.src }
        />
      </div>
    </Col>
    <Col span={16}>
      {
        imgLink! !== undefined &&
          <>
            <label style={{width: 100, display: 'inline-block'}}>Link</label>
            <Input
              style={{width: 'calc(100% - 180px)'}}
              value={image.src}
              onChange={e => onImgLinkChange(e.target.value) }
            />
            <div style={{display: 'inline-block', width: '100%', height: 1, backgroundColor: '#fff'}} />
          </>
      }
      <label style={{width: 100, display: 'inline-block'}}>Source</label>
      <Input
        style={{width: 'calc(100% - 120px)'}}
        value={image.src}
        onChange={e => onImagePropertyChange('src', e.target.value) }
      />
      <div style={{display: 'inline-block', width: '100%', height: 1, backgroundColor: '#fff'}} />
      <label style={{width: 100, display: 'inline-block'}}>Size</label>
      <Input
        style={{width: 50, padding: 5 }}
        value={image.width}
        onChange={e => onImagePropertyChange('width', e.target.value) }
      />
      &nbsp;×&nbsp;
      <Input
        style={{width: 50, padding: 5 }}
        value={image.height}
        onChange={e => onImagePropertyChange('height', e.target.value) }
      />
      <label style={{width: 70, display: 'inline-block', marginLeft: 20 }}>Contain in</label>
       <Input
        style={{width: 50, padding: 5 }}
        value={image.containWidth}
        onChange={e => onImagePropertyChange('containWidth', e.target.value) }
      />
      &nbsp;×&nbsp;
      <Input
        style={{width: 50, padding: 5 }}
        value={image.containHeight}
        onChange={e => onImagePropertyChange('containHeight', e.target.value) }
      />
      <hr />
      <label style={{width: 100, display: 'inline-block'}}>Mobile source</label>
      <Input
        style={{width: 'calc(100% - 180px)'}}
        value={image.srcMobile}
        onChange={e => onImagePropertyChange('srcMobile', e.target.value) }
      />
      <div style={{display: 'inline-block', width: '100%', height: 1, backgroundColor: '#fff'}} />
      <label style={{width: 100, display: 'inline-block'}}>Mobile size</label>
      <Input
        style={{width: 60}}
        value={image.widthMobile}
        onChange={e => onImagePropertyChange('widthMobile', e.target.value) }
      />
      &nbsp;x&nbsp;
      <Input
        style={{width: 60}}
        value={image.heightMobile}
        onChange={e => onImagePropertyChange('heightMobile', e.target.value) }
      />
      <label style={{width: 70, display: 'inline-block', marginLeft: 20 }}>Contain in</label>
       <Input
        style={{width: 50, padding: 5 }}
        value={image.containWidthMobile}
        onChange={e => onImagePropertyChange('containWidthMobile', e.target.value) }
      />
      &nbsp;×&nbsp;
      <Input
        style={{width: 50, padding: 5 }}
        value={image.containHeightMobile}
        onChange={e => onImagePropertyChange('containHeightMobile', e.target.value) }
      />
      <hr />
      <label style={{width: 100, display: 'inline-block'}}>Alt</label>
      <Input
        style={{width: 'calc(100% - 180px)'}}
        value={image.alt}
        onChange={e => onImagePropertyChange('alt', e.target.value) }
      />
    </Col>
  </Row>
}