import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {EcComponentWidgetInfo, EcComponent, EcComponentWithWidgetsInfo} from '../../types/core'
import {HtmlExportConfig, HtmlExportParam, HtmlExportPreset} from '../../types/html_exports'

export interface iHtmlExportModalState {
  isOpen: boolean
  productUuid: string
  componentUuid: string
  configId: number
  componentInfo: EcComponent | null
  widgets: EcComponentWidgetInfo[],
  selectedWidgets: Array<string>,
  selectedPreset: number,
  stylingPresets: HtmlExportPreset[],
  configs: HtmlExportConfig[],
  formatParams: { [key: string]: string },
  isLoadingPreview: boolean
}

const initialState: iHtmlExportModalState = {
  isOpen: false,
  productUuid: '',
  componentUuid: '',
  configId: 0,
  selectedPreset: 0,
  componentInfo: null,
  widgets: [],
  selectedWidgets: [],
  stylingPresets: [],
  configs: [],
  formatParams: {},
  isLoadingPreview: false,
}

export const htmlExportModalSlice = createSlice({
  name: 'htmlExportModal',
  initialState,
  reducers: {
    close: (state) => {
      state.isOpen = false
    },
    setConfigs: (state, action: PayloadAction<HtmlExportConfig[]>) => {
      state.configs = action.payload

      action.payload.forEach((config: HtmlExportConfig) => {
        if(config.is_default){
          state.configId = config.id
        }
      })
    },
    setFormatParamValue: (state, action: PayloadAction<{ id: string, value: string}>) => {
      state.formatParams[action.payload.id] = action.payload.value
    },
    setSelectedPreset: (state, action: PayloadAction<number>) => {
      state.selectedPreset = action.payload
    },
    setPresets: (state, action: PayloadAction<HtmlExportPreset[]>) => {
      state.stylingPresets = action.payload
    },
    setComponentInfo: (state, action: PayloadAction<EcComponentWithWidgetsInfo>) => {
      state.componentInfo = action.payload
      state.widgets = action.payload.widgets
      state.selectedWidgets = action.payload.widgets.map((w: EcComponentWidgetInfo) => w.id)
    },
    toggleSelectedWidget: (state, action: PayloadAction<string>) => {
      if(state.selectedWidgets.includes(action.payload)){
        state.selectedWidgets = state.selectedWidgets.filter((wId: string) => wId !== action.payload)
      }else{
        state.selectedWidgets.push(action.payload)
      }
    },
    setIsPreviewLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadingPreview = action.payload
    },
    open: (state, action: PayloadAction<{ productUuid: string, componentUuid: string }>) => {
      state.isOpen = true
      state.productUuid = action.payload.productUuid
      state.componentUuid = action.payload.componentUuid
      state.configId = 1
      state.selectedWidgets = []
      state.isLoadingPreview = true
    },
    setConfigId: (state, action: PayloadAction<number>) => {
      state.configId = action.payload
      state.formatParams = {}
    },
  },
});

export const { actions, reducer } = htmlExportModalSlice
