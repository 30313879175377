import React, {useState} from 'react'
import AppMenu from '../App/Menu'
import {PageHeader, Input, Button, Tabs, Select, notification} from 'antd'
import ProductsAPI from '../../api/ProductsAPI'
import VendorsAPI from '../../api/VendorsAPI'
import { actions as ProductEcModalActions } from '../ProductEcModal/slice'
import {useAppDispatch, useAppSelector} from '../../hooks'
import {useDebounce} from '../../utils'
import {Product, Vendor} from '../../types/core'
import ProductsTable from '../ProductsTable/ProductsTable'
import CurrentUser from '../App/CurrentUser'

interface iFinderResponse{
  found: boolean
  only_one: boolean
  product_uuid: string
  products: Product[]
}

export default function Finder(){
  const dispatch = useAppDispatch()
  const [searchQuery, setSearchQuery] = useState('')
  const [mode, setMode] = useState('uuid')
  const [isLoading, setIsLoading] = useState(false)
  const [vendorSearchQuery, setVendorSearchQuery] = useState('')
  const [foundVendors, setFoundVendors] = useState<Vendor[]>([])
  const [foundProducts, setFoundProducts] = useState<Product[]>([])
  const [selectedVendor, setSelectedVendor] = useState<Vendor | undefined>(undefined)

  const user = useAppSelector(state => state.user)

  function findProducts(){
    setIsLoading(true)
    ProductsAPI.finder(mode, selectedVendor ? selectedVendor.id : null,  searchQuery, (resp: iFinderResponse) => {
      setIsLoading(false)

      if(resp.found){
        if(resp.only_one){
          dispatch(ProductEcModalActions.open(resp.product_uuid))
        }else{
          setFoundProducts(resp.products)
        }
      }else{
        notification.error({
          message: 'No products found',
        })
      }
    })
  }

  const searchForVendors = useDebounce(function(query: string){
    VendorsAPI.fetchList((vendors: Array<Vendor>, total: Number) => {
      setFoundVendors(vendors)
    }, {'query': query})
  }, 200)

  function onVendorQueryChange(query: string){
    setVendorSearchQuery(query)
    searchForVendors(query)
  }

  function onVendorChange(selectedVendorUuid: string){
    setSelectedVendor(foundVendors.find(v => v['id'] === selectedVendorUuid))
    setSearchQuery('')
    setFoundProducts([])
  }

  const options = foundVendors.map((v: Vendor) => <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>);

  const vendorSearch = <Select
    showSearch
    size={'large'}
    searchValue={ vendorSearchQuery }
    placeholder={'Search for vendor'}
    style={{ width: 350, marginRight: 20 }}
    defaultActiveFirstOption={false}
    showArrow={false}
    filterOption={false}
    onSearch={ onVendorQueryChange }
    onChange={ onVendorChange }
    dropdownMatchSelectWidth={ true }
    notFoundContent={null}
  >
    {options}
  </Select>

  let content = <Tabs onChange={tab => { setMode(tab); setSearchQuery('') }} activeKey={mode}>
    <Tabs.TabPane tab={"By UUID or product URL"} key={"uuid"}>
      <Input
        size={'large'}
        value={ searchQuery }
        onChange={e => setSearchQuery(e.target.value)}
        style={{ width: 'calc(80% - 440px)', marginRight: 20 }}
        placeholder={'Enter CXH product UUID or full product URL'}
        onPressEnter={ findProducts }
      />
      <Button
        loading={ isLoading }
        size={'large'}
        style={{ width: 150 }}
        onClick={ findProducts }
      >Search</Button>
    </Tabs.TabPane>
    <Tabs.TabPane tab={"Vendor & SKU/GTIN"} key={"vendor"}>
      {
        selectedVendor ?
          <>
            <div style={{
              display: 'inline-block',
              width: 350, height: 40,
              textAlign: 'left', border: '1px solid #aaa',
              fontSize: 16, padding: '6px 8px',
              marginRight: 20
            }}>
              <span style={{ display: 'inline-block', width:'calc(100% - 80px)', height: 30, overflow: 'hidden' }}>
                { selectedVendor.name }
              </span>
              <Button
                type={'link'}
                style={{ display: 'inline-block', verticalAlign: 'top', position: 'relative', top: -3 }}
                onClick={() => setSelectedVendor(undefined) }
              >Cancel</Button>
            </div>
            <Input
              size={'large'}
              value={ searchQuery }
              onChange={e => setSearchQuery(e.target.value)}
              style={{ width: 'calc(60% - 440px)', marginRight: 20, verticalAlign: 'top', display: 'inline-block' }}
              placeholder={'SKU / GTIN / etc'}
              onPressEnter={ findProducts }
            />
            <Button
              loading={ isLoading }
              size={'large'}
              style={{ width: 150, verticalAlign: 'top' }}
              onClick={ findProducts }
            >Search</Button>
            {
              foundProducts && foundProducts.length > 0 &&
                <div style={{ marginTop: 30 }}>
                  <ProductsTable
                    locale={ user.locale }
                    showPagination={false}
                    products={foundProducts}
                    isLoading={false}
                    total={foundProducts.length}
                    page={1}
                    onProductSelect={(product: Product) => { dispatch(ProductEcModalActions.open(product.id)) }}
                    onPageChange={(p: number) => {}}
                    selected={[]}
                    selectable={ false }
                  />
                </div>
            }
          </>
          :
          vendorSearch
      }
    </Tabs.TabPane>
  </Tabs>

   return <div>
    <AppMenu selectedKey={'finder'}/>
    <PageHeader title={'Finder'} backIcon={ false } />
    <div style={{ padding: '0 20px' }}>
      {content}
    </div>
  </div>
}