import apiAccess from './apiAccess'
import {HtmlExportPresetPayload} from '../types/html_exports'


export default class ExportPresetsAPI {
  static delete(presetId: number, next: Function){
    apiAccess.url(`/export-presets/${presetId}`)
      .delete()
      .json(response => { next(response) })
  }

  static fetchDetails(presetId: number, next: Function){
    apiAccess.url(`/export-presets/${presetId}`)
      .get()
      .json(response => { next(response) })
  }

  static fetchList(next: Function){
    apiAccess.url(`/export-presets`)
      .get()
      .json(response => { next(response['presets'], response['total']) })
  }

  static update(presetId: number, payload: HtmlExportPresetPayload, next: Function){
    apiAccess.url(`/export-presets/${presetId}`)
      .patch(payload)
      .json(response => { next(response) })
  }

  static create(payload: HtmlExportPresetPayload, next: Function){
    apiAccess.url(`/export-presets`)
      .post( payload )
      .json(response => { next(response['id']) })
  }
}
