import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {HtmlExportConfigVariable, HtmlExportParam} from '../../types/html_exports'

export interface HtmlExportParamsModalState {
  isOpen: boolean
  productUuid: string
  componentUuid: string
  configId: number
  variables: HtmlExportConfigVariable[]
  variableValues: {[key: string]: string},
  comment: string
}

const initialState: HtmlExportParamsModalState = {
  isOpen: false,
  productUuid: '',
  componentUuid: '',
  configId: -1,
  comment: '',
  variables: [],
  variableValues: {},
}

export const htmlExportParamsModalSlice = createSlice({
  name: 'htmlExportParamsModalSlice',
  initialState,
  reducers: {
    setSeedValues: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.variableValues = action.payload
    },
    setComment: (state, action: PayloadAction<string>) => {
      state.comment = action.payload
    },
    setVariables: (state, action: PayloadAction<HtmlExportConfigVariable[]>) => {
      state.variables = action.payload
    },
    close: (state) => {
      state.isOpen = false
    },
    setVariableValue: (state, action: PayloadAction<{ key: string, value: string}>) => {
      state.variableValues[action.payload.key] = action.payload.value
    },
    open: (state, action: PayloadAction<{ productUuid: string, componentUuid: string, configId: number }>) => {
      state.isOpen = true
      state.productUuid = action.payload.productUuid
      state.componentUuid = action.payload.componentUuid
      state.configId = action.payload.configId
      // state.va = action.payload.params
      // state.paramValues = {}
    },
  },
});

export const { actions, reducer } = htmlExportParamsModalSlice
