import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Vendor } from '../../types/core'


export interface VendorSelectorState {
  total: Number;
  vendors: Array<Vendor>;
}

const initialState: VendorSelectorState = {
  total: 0,
  vendors: [],
};

export const vendorSelectorSlice = createSlice({
  name: 'vendorSelector',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setVendors: (state, action: PayloadAction<VendorSelectorState>) => {
      state.vendors = action.payload.vendors
    }
  },
});

export const { actions, reducer } = vendorSelectorSlice
