import React from 'react'
import {Modal, Spin} from 'antd'
import {useAppSelector} from '../../hooks'

export default function AsyncJobModal(){
  const state = useAppSelector(state => state.asyncJobModal)

  return <Modal visible={ state.isOpen } footer={ false } closable={ false } bodyStyle={{ textAlign: 'center', padding: 60 }} width={ 300 }>
    <Spin size={'large'} style={{ fontSize: 18 }} tip={state.message}/>
  </Modal>
}