import React from 'react'
import {Button, Tabs, Upload} from 'antd'
import {useAppDispatch, useAppSelector} from '../../hooks'
import styles from './content_browser.module.scss'
import {actions} from './slice'
import DraggableItem from './DraggaleItem'
import {applySizeToAssetUrl, formatFileSize, stripHTML} from '../../lib'
import ContentPublicationsAPI from '../../api/ContentPublicationsAPI'
import { UploadOutlined} from '@ant-design/icons'
import {RcFile} from 'antd/es/upload/interface'
import {iContentPublicationUploadedAsset} from '../../types/content_publications'
import {AssetFetchedFromEcComponent, TextFetchedFromEcComponent} from '../../types/core'

const IMAGE_EXTS = ['jpg', 'jpeg', 'png']

function AssetMiniature({ asset } : { asset: iContentPublicationUploadedAsset | AssetFetchedFromEcComponent }){
  const tmp = asset.name.split('.')
  const ext = tmp[tmp.length-1].toLowerCase()
  let imageRes = null

  if(IMAGE_EXTS.includes(ext)){
    imageRes = <>
      { ' • ' }
      { asset.width }
      {' × '}
      { asset.height }
    </>
  }

  let assetUrl = applySizeToAssetUrl(asset.url)

  return <div className={styles.imageAsset}>
    <a href={ asset.url }
       target={'_blank'}
      style={{ backgroundImage: `url(${assetUrl})`, }}
       rel={'noreferrer'}
      className={ styles.preview }
    >
    </a>
    <div className={ styles.meta }>
      { ext }
      { ' • ' }
      { formatFileSize(asset.size) }
      { imageRes }
    </div>
  </div>
}

function AvailableContentBrowser({ publicationId }: { publicationId: number }){
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.ozonPublication)

  let texts = state.textsFromEc.map((txt: TextFetchedFromEcComponent) => {
    const t = stripHTML(txt.content).trim()
    return { 'content': t, 'length': t.trim().length }
  })

  texts = texts.filter(txt => txt.content.length)

  const uploadProps = {
    name: 'file',
    multiple: false,
    fileList: [],
    // beforeUpload(){ return false },
    customRequest({ file } : { file: Blob | string | RcFile  }){
      const reader = new FileReader()
      reader.addEventListener('load', function () {
        let f = file as RcFile
        ContentPublicationsAPI.uploadAsset(
          publicationId, f.name, String(reader.result),
          (asset: iContentPublicationUploadedAsset) => {
            dispatch(actions.prependUploadedAsset(asset))
          }
        )
      })
      reader.readAsDataURL(file as Blob)
    },
  }

  return <>
    <Tabs size={'small'}>
      <Tabs.TabPane tab={`Assets (${state.assetsFromEc.length})`} key={'assets'}>
        <div className={ styles.scrollableList }>
          <div className={ styles.content }>
            {
              state.assetsFromEc.map(asset => {
                return <DraggableItem
                  key={asset.id}
                  isDropped={false}
                  type={'image'}
                  name={''}
                  asset={ asset }
                  content={ <AssetMiniature asset={asset}/> }
                />
              })
            }
          </div>
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab={`Texts (${state.textsFromEc.length})`} key={'texts'}>
        <div className={ styles.scrollableList }>
          <div className={ styles.content }>
            {
              texts.map((text, idx) => {
                return <DraggableItem
                  key={idx}
                  isDropped={false}
                  type={'text'}
                  name={''}
                  text={ text.content }
                  content={
                    <div className={styles.availableText}>
                      { text.content }
                    </div>
                  }
                />
              })
            }
          </div>
        </div>
      </Tabs.TabPane>

      <Tabs.TabPane tab={`Uploads (${state.uploadedAssets.length})`} key={'uploads'}>
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />} style={{ marginBottom: 20  }}>Click to Upload</Button>
        </Upload>
          <div className={ styles.scrollableList } style={{ height: 'calc(100vh - 220px)' }}>
            <div className={ styles.content }>
            {
              state.uploadedAssets.map(asset => {
                return <DraggableItem
                  key={asset.id}
                  isDropped={false}
                  type={'image'}
                  name={''}
                  asset={asset}
                  content={ <AssetMiniature asset={asset}/>}
                />
              })
            }
          </div>
        </div>
      </Tabs.TabPane>
    </Tabs>
  </>
}

export default AvailableContentBrowser