import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface iExportPresetModalState {
  isOpen: boolean
  presetId: number | null
  name: string
  scssConfig: string
  exportHeadAddons: string
  previewHeadAddons: string
}

const initialState: iExportPresetModalState = {
  isOpen: false,
  presetId: null,
  name: '',
  scssConfig: '',
  exportHeadAddons: '',
  previewHeadAddons: '',
}

export const exportPresetModalSlice = createSlice({
  name: 'exportPresetModal',
  initialState,
  reducers: {
    close: (state) => {
      state.isOpen = false
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    setScssConfig: (state, action: PayloadAction<string>) => {
      state.scssConfig = action.payload
    },
    setExportHeadAddons: (state, action: PayloadAction<string>) => {
      state.exportHeadAddons = action.payload
    },
    setPreviewHeadAddons: (state, action: PayloadAction<string>) => {
      state.previewHeadAddons = action.payload
    },
    open: (state) => {
      state.isOpen = true
    },
    openForEdit: (state, action: PayloadAction<number>) => {
      state.isOpen = true
      state.presetId = action.payload
    }
  },
});

export const { actions, reducer } = exportPresetModalSlice
