import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ContentBundle} from '../../types/content_bundles'

export interface ContentExportsListDataState {
  total: number;
  bundles: Array<ContentBundle>;
}

export interface ContentExportsListState extends ContentExportsListDataState{
  query: string;
  page: number;
  isLoading: boolean;
}

const initialState: ContentExportsListState = {
  query: '',
  total: 0,
  page: 1,
  bundles: [],
  isLoading: false,
}

export const contentExportsListSlice = createSlice({
  name: 'contentExportsList',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setBundles: (state, action: PayloadAction<ContentExportsListDataState>) => {
      state.bundles = action.payload.bundles
      state.total = action.payload.total
      state.isLoading = false
    }
  },
});

export const { actions, reducer } = contentExportsListSlice

