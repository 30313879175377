import React from 'react'
import {Card, Button} from 'antd'
import {actions} from './slice'
import {useAppDispatch} from '../../hooks'
import { VerticalAlignTopOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import {iOzonWidget} from '../../types/ozon'

export default function OzonWidgetEditor(
  { widget, idx, children, title, isLast }
    :
  { widget: iOzonWidget, idx: number, children: React.ReactNode, title: string, isLast: boolean }
){
  const dispatch = useAppDispatch()

  const removeWidget = () => {
    if(window.confirm('Are you sure?')){
      dispatch(actions.removeWidget(idx))
    }
  }

  return <Card
    title={ <span style={{ fontSize: 16 }}>{ title }</span> }
    size={ 'small' }
    bordered={ true }
    headStyle={{ backgroundColor: '#e1e1e1' }}
    extra={
      <>
        <Button.Group style={{ marginRight: 10 }}>
          {
            idx !== 0 &&
            <Button size={'small'} icon={<VerticalAlignTopOutlined />} onClick={e => dispatch(actions.moveWidgetUp(idx))}>Up</Button>
          }
          {
            !isLast &&
            <Button size={'small'} icon={<VerticalAlignBottomOutlined />} onClick={e => dispatch(actions.moveWidgetDown(idx))}>Down</Button>
          }
        </Button.Group>
        <Button size={'small'} onClick={ removeWidget }>Remove</Button>
      </>
    }
    style={{ border: '1px solid #aaa', marginBottom: 15 }}
  >{ children }</Card>
}