import React from 'react'
import { iOzonWidget } from '../../types/ozon'
import {Button} from 'antd'
import {useAppDispatch} from '../../hooks'
import { actions } from './slice'
import OzonWidgetEditor from './OzonWidgetEditor'
import OzonWidgetBlockEditor from './OzonWidgetBlockEditor'

export default function OzonListWidgetEditor({ widget, idx, isLast }: { widget: iOzonWidget, idx: number, isLast: boolean }){
  const dispatch = useAppDispatch()

  return <OzonWidgetEditor widget={ widget } idx={idx} title={ 'List - ' + widget.theme } isLast={ isLast }>
    {
      widget.blocks!.map((block, blockIdx) => {
        return <OzonWidgetBlockEditor blockIdx={blockIdx} idx={idx} block={block} key={blockIdx}/>
      })
    }
    <Button type={'link'} onClick={e => dispatch(actions.addBlock(idx))}>Add block</Button>
  </OzonWidgetEditor>
}